const Mobile = [
  {
    _id: '64c37c823c03172a9facc087',
    id: 45,
    title: 'Samsung Galaxy Z Fold 3 5G',
    price: 149999,
    cureted_price: 159999,
    ratings: 3.5,
    decr: 'Experience the future of smartphones with the Samsung Galaxy Z Fold 3. Unfold the 7.6-inch AMOLED display for a tablet-like experience, or fold it for a compact phone. Packed with powerful features and the latest technology, this device is designed to impress.',
    category: 'mobile',
    images: [
      {
        _id: '651d50b7e2b392999df759f4',
        url: 'https://rukminim2.flixcart.com/image/312/312/xif0q/mobile/p/v/o/-original-imagh7nzmxwmbpvf.jpeg?q=70',
      },
    ],
    highlights: '12 GB RAM | 256 GB ROM | 7.6 inch AMOLED Display',
    seller: 'TechWonders',
    replacement: '10 Days Replacement Policy',
    invoice: 'GST invoice available',
    specificaton: [
      {
        inTheBox: 'Handset, Charger, USB Cable, SIM Ejector Pin, User Manual',
        modelNumber: 'SM-F926BZKD',
        modelName: 'Galaxy Z Fold 3 5G',
        type: 'Phantom Black',
        batteryCapacity: '4400mAh',
      },
    ],
    brand: 'Samsung',
    stock: 15,
    __v: 0,
    features: [
      'Foldable design for versatile use',
      'Powerful performance with 12 GB RAM',
      'Impressive 7.6-inch AMOLED display',
      'High-quality camera setup for stunning photos',
    ],
    numrev: 15,
    rating: 96,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f139',
        rate: 4.9,
        Comment: 'Incredible device! Love the foldable display.',
        date: '2024-11-12T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae759',
      },
      {
        user: '63d1089e7ee22fa515771f140',
        rate: 4.8,
        Comment: 'Amazing phone with top-notch features.',
        date: '2024-11-15T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae760',
      },
    ],
    sell: 12,
  },
  {
    _id: '64c37c823c03172a9facc088',
    id: 46,
    title: 'Poco X4 Pro 5G',
    price: 24999,
    cureted_price: 27999,
    ratings: 4.5,
    decr: "Unleash the power of 5G with the Poco X4 Pro. This smartphone features a high-refresh-rate display, a versatile camera setup, and a powerful processor to provide a smooth and immersive user experience. Whether you're gaming, streaming, or capturing moments, the Poco X4 Pro is up for the task.",
    category: 'mobile',
    images: [
      {
        _id: '651d50b7e2b392999df759f5',
        url: 'https://rukminim2.flixcart.com/image/312/312/l19m93k0/mobile/w/0/6/-original-imagcv2dcyjzcmvd.jpeg?q=70',
      },
    ],
    highlights: '6 GB RAM | 128 GB ROM | 6.67 inch Full HD+ Display',
    seller: 'GadgetZone',
    replacement: '7 Days Replacement Policy',
    invoice: 'GST invoice available',
    specificaton: [
      {
        inTheBox: 'Handset, Charger, USB Cable, SIM Ejector Pin, User Manual',
        modelNumber: 'M2013J19PI',
        modelName: 'X4 Pro 5G',
        type: 'Fantasy Black',
        batteryCapacity: '5160mAh',
      },
    ],
    brand: 'Poco',
    stock: 20,
    __v: 0,
    features: [
      '5G connectivity for faster data speeds',
      'High-refresh-rate display for smooth visuals',
      'Versatile camera system for creative photography',
      'Powerful battery for all-day usage',
    ],
    numrev: 8,
    rating: 92,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f141',
        rate: 4.7,
        Comment: 'Great value for money. Excellent performance.',
        date: '2024-12-01T14:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae761',
      },
      {
        user: '63d1089e7ee22fa515771f142',
        rate: 4.6,
        Comment: 'Smooth 5G experience. Impressive camera.',
        date: '2024-12-05T11:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae762',
      },
    ],
    sell: 18,
  },
  {
    _id: '64c37c823c03172a9facc089',
    id: 47,
    title: 'OnePlus 9 Pro',
    price: 59999,
    cureted_price: 64999,
    ratings: 3.9,
    decr: 'Experience flagship performance with the OnePlus 9 Pro. This smartphone boasts a stunning display with a high refresh rate, a powerful camera system co-engineered with Hasselblad, and ultra-fast charging capabilities. Elevate your smartphone experience with the OnePlus 9 Pro.',
    category: 'mobile',
    images: [
      {
        _id: '651d50b7e2b392999df759f6',
        url: 'https://rukminim2.flixcart.com/image/312/312/xif0q/mobile/e/z/l/-original-imagdm2pzrg5ej6y.jpeg?q=70',
      },
    ],
    highlights: '8 GB RAM | 128 GB ROM | 6.7 inch Fluid AMOLED Display',
    seller: 'TechGurus',
    replacement: '10 Days Replacement Policy',
    invoice: 'GST invoice available',
    specificaton: [
      {
        inTheBox:
          'Handset, Warp Charge 65 Power Adapter, Warp Charge Type-C to Type-C Cable, Quick Start Guide, Welcome Letter, Safety Information, Warranty Card, SIM Tray Ejector',
        modelNumber: 'LE2123',
        modelName: '9 Pro',
        type: 'Morning Mist',
        batteryCapacity: '4500mAh',
      },
    ],
    brand: 'OnePlus',
    stock: 15,
    __v: 0,
    features: [
      'Flagship performance with Snapdragon processor',
      'Stunning Fluid AMOLED display with high refresh rate',
      'Co-engineered camera system with Hasselblad',
      'Ultra-fast Warp Charge 65 for quick charging',
    ],
    numrev: 12,
    rating: 94,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f143',
        rate: 4.9,
        Comment: 'Absolutely love it! Best smartphone experience.',
        date: '2024-12-12T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae763',
      },
      {
        user: '63d1089e7ee22fa515771f144',
        rate: 4.8,
        Comment: 'Impressive camera and smooth performance.',
        date: '2024-12-15T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae764',
      },
    ],
    sell: 10,
  },
  {
    _id: '64c37c823c03172a9facc091',
    id: 49,
    title: 'Nothing Phone (2)',
    price: 49999,
    cureted_price: 54999,
    ratings: 4.5,
    decr: 'Introducing the Nothing Phone X – a revolutionary smartphone that redefines simplicity. Embrace a minimalist design, powerful performance, and a seamless user experience. Nothing Phone X, where less is more.',
    category: 'mobile',
    images: [
      {
        _id: '651d50b7e2b392999df759f8',
        url: 'https://rukminim2.flixcart.com/image/312/312/xif0q/mobile/u/m/b/-original-imagrdefbw6bhbjr.jpeg?q=70',
      },
    ],
    highlights: 'Minimalist Design | Powerful Performance',
    seller: 'NothingStore',
    replacement: '10 Days Replacement Policy',
    invoice: 'GST invoice available',
    specificaton: [
      {
        inTheBox: 'Handset, Charger, USB Cable, SIM Ejector Pin, User Manual',
        modelNumber: 'NPX2025',
        modelName: 'Phone X',
        type: 'Lunar White',
        batteryCapacity: '4500mAh',
      },
    ],
    brand: 'Nothing',
    stock: 15,
    __v: 0,
    features: [
      'Sleek and minimalist design',
      'High-performance hardware for smooth operation',
      'Immersive display for an enhanced viewing experience',
      'Long-lasting battery for all-day usage',
    ],
    numrev: 6,
    rating: 86,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f147',
        rate: 4.7,
        Comment: 'Elegance meets performance. Loving my Nothing Phone X.',
        date: '2025-02-01T14:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae767',
      },
      {
        user: '63d1089e7ee22fa515771f148',
        rate: 4.5,
        Comment: 'Simple yet powerful. Great user experience.',
        date: '2025-02-05T11:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae768',
      },
    ],
    sell: 8,
  },
  {
    _id: '64c37c823c03172a9facc092',
    id: 50,
    title: 'iPhone 13 Pro Max',
    price: 129999,
    cureted_price: 139999,
    ratings: 3.5,
    decr: 'Unleash the power of innovation with the iPhone 13 Pro Max. Featuring a ProMotion XDR display, an advanced camera system, and the A15 Bionic chip, this smartphone delivers an unparalleled user experience. Elevate your creativity and capture moments like never before with the iPhone 13 Pro Max.',
    category: 'mobile',
    images: [
      {
        _id: '651d50b7e2b392999df759f9',
        url: 'https://rukminim2.flixcart.com/image/312/312/ktketu80/mobile/r/m/8/iphone-13-pro-max-mllj3hn-a-apple-original-imag6vpgphrzuffg.jpeg?q=70',
      },
    ],
    highlights: '8 GB RAM | 256 GB ROM | 6.7 inch Super Retina XDR Display',
    seller: 'AppleStore',
    replacement: '15 Days Replacement Policy',
    invoice: 'GST invoice available',
    specificaton: [
      {
        inTheBox: 'iPhone, USB-C to Lightning Cable, Documentation',
        modelNumber: 'MLLH3HN/A',
        modelName: 'iPhone 13 Pro Max',
        type: 'Graphite',
        batteryCapacity: '4352mAh',
      },
    ],
    brand: 'Apple',
    stock: 30,
    __v: 0,
    features: [
      'ProMotion XDR display for smooth visuals',
      'Triple-camera system with ProRAW and ProRes video recording',
      'A15 Bionic chip for powerful performance',
      'Ceramic Shield front cover for durability',
    ],
    numrev: 20,
    rating: 98,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f149',
        rate: 4.9,
        Comment: 'Absolutely stunning! Best iPhone yet.',
        date: '2025-03-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae769',
      },
      {
        user: '63d1089e7ee22fa515771f150',
        rate: 4.8,
        Comment: 'Amazing camera capabilities. Super fast and responsive.',
        date: '2025-03-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae770',
      },
    ],
    sell: 25,
  },
  {
    _id: '64c37c823c03172a9facc093',
    id: 51,
    title: 'Redmi Note 11 Pro',
    price: 17999,
    cureted_price: 19999,
    ratings: 3.5,
    decr: 'Discover excellence with the Redmi Note 11 Pro. Packed with a powerful camera system, a high-refresh-rate display, and a large battery, this smartphone is designed to enhance your mobile experience. Capture stunning photos, enjoy smooth gaming, and stay powered throughout the day with the Redmi Note 11 Pro.',
    category: 'mobile',
    images: [
      {
        _id: '651d50b7e2b392999df759fa',
        url: 'https://rukminim2.flixcart.com/image/312/312/xif0q/mobile/z/d/c/-original-imagcvhxubttxe7r.jpeg?q=70',
      },
    ],
    highlights: '6 GB RAM | 128 GB ROM | 6.67 inch Super AMOLED Display',
    seller: 'RedmiHub',
    replacement: '10 Days Replacement Policy',
    invoice: 'GST invoice available',
    specificaton: [
      {
        inTheBox:
          'Handset, Power Adapter, USB Type-C Cable, SIM Ejector Pin, Soft Case, User Manual',
        modelNumber: 'M2013K6G',
        modelName: 'Note 11 Pro',
        type: 'Celestial Blue',
        batteryCapacity: '5020mAh',
      },
    ],
    brand: 'Redmi',
    stock: 18,
    __v: 0,
    features: [
      'Versatile camera setup for creative photography',
      'Super AMOLED display for vivid visuals',
      'Powerful processor for smooth multitasking',
      'High-capacity battery for all-day usage',
    ],
    numrev: 15,
    rating: 92,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f151',
        rate: 4.7,
        Comment: 'Great value for money. Excellent camera performance.',
        date: '2025-04-01T14:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae771',
      },
      {
        user: '63d1089e7ee22fa515771f152',
        rate: 4.6,
        Comment: 'Smooth display and fast performance.',
        date: '2025-04-05T11:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae772',
      },
    ],
    sell: 12,
  },
  {
    _id: '64c37c823c03172a9facc094',
    id: 52,
    title: 'Realme 9 Pro',
    price: 15999,
    cureted_price: 17999,
    ratings: 3.9,
    decr: "Experience the next level of performance with the Realme 9 Pro. This smartphone features a stunning camera setup, a high-refresh-rate display, and a powerful processor. Whether you're capturing moments or enjoying multimedia, the Realme 9 Pro is designed to deliver a premium experience.",
    category: 'mobile',
    images: [
      {
        _id: '651d50b7e2b392999df759fb',
        url: 'https://rukminim2.flixcart.com/image/312/312/kzogn0w0/mobile/m/p/2/-original-imagbmf4qsurna5j.jpeg?q=70',
      },
    ],
    highlights: '6 GB RAM | 128 GB ROM | 6.4 inch Super AMOLED Display',
    seller: 'RealmeStore',
    replacement: '12 Days Replacement Policy',
    invoice: 'GST invoice available',
    specificaton: [
      {
        inTheBox:
          'Handset, Adapter, USB Type-C Cable, SIM Card Tool, Case, Quick Guide, Warranty Card',
        modelNumber: 'RMX3381',
        modelName: '9 Pro',
        type: 'Lightning Blue',
        batteryCapacity: '4500mAh',
      },
    ],
    brand: 'Realme',
    stock: 20,
    __v: 0,
    features: [
      'Versatile camera system for creative photography',
      'Super AMOLED display for vibrant visuals',
      'Snapdragon processor for seamless multitasking',
      'Fast charging for quick power replenishment',
    ],
    numrev: 18,
    rating: 94,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f153',
        rate: 4.8,
        Comment: 'Fantastic camera quality. Loving the vibrant display.',
        date: '2025-05-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae773',
      },
      {
        user: '63d1089e7ee22fa515771f154',
        rate: 4.7,
        Comment: 'Smooth performance and stylish design.',
        date: '2025-05-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae774',
      },
    ],
    sell: 15,
  },
  {
    _id: '64c37c823c03172a9facc095',
    id: 53,
    title: 'Nokia X30',
    price: 1899,
    cureted_price: 2099,
    ratings: 3.2,
    decr: 'Rediscover the iconic Nokia experience with the Nokia X30. This smartphone combines classic design with modern features, offering a pure Android experience and reliable performance. Capture memories, enjoy multimedia, and stay connected with the Nokia X30.',
    category: 'mobile',
    images: [
      {
        _id: '651d50b7e2b392999df759fc',
        url: 'https://rukminim2.flixcart.com/image/312/312/ked56kw0/mobile/j/y/9/nokia-5310-12pisx21a01-original-imafv2dvfhnnhzy3.jpeg?q=70',
      },
    ],
    highlights: '6 GB RAM | 128 GB ROM | 6.5 inch Full HD+ Display',
    seller: 'NokiaOfficial',
    replacement: '15 Days Replacement Policy',
    invoice: 'GST invoice available',
    specificaton: [
      {
        inTheBox: 'Nokia X30, Charger, USB Cable, Headset, Quick Guide',
        modelNumber: 'TA-2023',
        modelName: 'X30',
        type: 'Midnight Blue',
        batteryCapacity: '5000mAh',
      },
    ],
    brand: 'Nokia',
    stock: 25,
    __v: 0,
    features: [
      'Timeless Nokia design for a classic appeal',
      'Pure Android experience for fast and secure performance',
      'Impressive display for immersive visuals',
      'Reliable battery for extended usage',
    ],
    numrev: 14,
    rating: 90,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f155',
        rate: 4.6,
        Comment: "Nokia's build quality stands out. Great user experience.",
        date: '2025-06-01T14:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae775',
      },
      {
        user: '63d1089e7ee22fa515771f156',
        rate: 4.7,
        Comment: 'Smooth performance and reliable battery life.',
        date: '2025-06-05T11:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae776',
      },
    ],
    sell: 18,
  },
]

export default Mobile
