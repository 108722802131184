const Electronics = [
  {
    _id: '64c37c823c03172a9facc122',
    id: 80,
    title: 'Sony 65-inch 4K Ultra HD Smart LED TV',
    price: 149999,
    cureted_price: 169999,
    category: 'electronics',
    ratings: 4.7,
    images: [
      {
        _id: '651d50b7e2b392999df75a23',
        url: 'https://rukminim2.flixcart.com/image/312/312/l3929ow0/television/h/d/m/kd-65x75k-in5-sony-original-imagef3zkuszrfgh.jpeg?q=70',
      },
    ],
    seller: 'ElectroGuru',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        screenSize: '65 inches',
        resolution: '4K Ultra HD',
        type: 'Android TV, Voice Assistant,',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Sony',
        Color: 'Black',
        Type: 'Smart LED TV',
        ModelName: 'BRAVIA X900H',
        ModelID: 'SONY-BRAVIA-X900H',
        screenSize: '65 inches',
        resolution: '4K Ultra HD',
        smartFeatures: 'Android TV, Voice Assistant, Streaming Apps',
        Collection: 'BRAVIA Series',
        care: 'Stunning visuals with smart features for an immersive entertainment experience',
      },
    ],
    stock: 8,
    __v: 0,
    features: [
      'Sony 65-inch 4K Ultra HD Smart LED TV',
      'Immersive 4K Ultra HD resolution',
      'Android TV with Voice Assistant',
      'Streaming apps for endless entertainment',
    ],
    numrev: 25,
    rating: 95,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f209',
        rate: 4.8,
        Comment:
          'Outstanding picture quality. Smart features are a game-changer.',
        date: '2027-09-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7b7',
      },
      {
        user: '63d1089e7ee22fa515771f210',
        rate: 4.6,
        Comment: 'Best investment for a home theater experience. Loving it!',
        date: '2027-09-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7b8',
      },
    ],
    sell: 15,
  },
  {
    _id: '64c37c823c03172a9facc123',
    id: 81,
    title: 'Apple MacBook Pro 13-inch (M2 Chip, 16GB RAM, 512GB SSD)',
    price: 199999,
    cureted_price: 219999,
    category: 'electronics',
    ratings: 4.9,
    images: [
      {
        _id: '651d50b7e2b392999df75a24',
        url: 'https://rukminim2.flixcart.com/image/312/312/xif0q/computer/k/s/m/-original-imagfdewfh9z3pee.jpeg?q=70',
      },
    ],
    seller: 'TechHaven',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        processor: 'M2 Chip',
        ram: '16GB',
        storage: '512GB SSD',
        displaySize: '13 inches',
        type: 'macOS Monterey',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Apple',
        Color: 'Space Gray',
        Type: 'Laptop',
        ModelName: 'MacBook Pro 13-inch',
        ModelID: 'APPLE-MBP-13-M2',
        processor: 'M2 Chip',
        ram: '16GB',
        storage: '512GB SSD',
        displaySize: '13 inches',
        operatingSystem: 'macOS Monterey',
        Collection: 'MacBook Pro Series',
        care: 'Powerful performance with a sleek design for creative professionals',
      },
    ],
    stock: 10,
    __v: 0,
    features: [
      'Apple MacBook Pro 13-inch (M2 Chip, 16GB RAM, 512GB SSD)',
      'Powerful M2 Chip for seamless performance',
      '16GB RAM and 512GB SSD for storage and speed',
      'Sleek design with macOS Monterey',
    ],
    numrev: 30,
    rating: 97,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f211',
        rate: 5,
        Comment:
          "Absolutely love my MacBook Pro. It's a powerhouse for my work.",
        date: '2027-10-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7b9',
      },
      {
        user: '63d1089e7ee22fa515771f212',
        rate: 4.8,
        Comment: 'Fast and reliable. Perfect for professionals.',
        date: '2027-10-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7ba',
      },
    ],
    sell: 12,
  },
  {
    _id: '64c37c823c03172a9facc124',
    id: 82,
    title: 'Samsung Galaxy Watch 4 Classic (46mm, Bluetooth)',
    price: 29999,
    cureted_price: 34999,
    category: 'electronics',
    ratings: 4.8,
    images: [
      {
        _id: '651d50b7e2b392999df75a25',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/smartwatch/w/z/l/49-smasung-active-smart-watch-android-ios-speedfox-yes-original-imagwu5hceujhgkh.jpeg?q=70',
      },
    ],
    seller: 'GadgetHub',
    replacement: '15 Days Replacement Policy',
    specificaton: [
      {
        displaySize: '46mm',
        type: 'Bluetooth',
        healthFeatures: 'Heart Rate Monitor, Blood Pressure Monitor, ECG',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Samsung',
        Color: 'Mystic Black',
        Type: 'Smartwatch',
        ModelName: 'Galaxy Watch 4 Classic',
        ModelID: 'SAMSUNG-GW4C-46-BT',
        displaySize: '46mm',
        connectivity: 'Bluetooth',
        healthFeatures: 'Heart Rate Monitor, Blood Pressure Monitor, ECG',
        Collection: 'Galaxy Watch Series',
        care: 'Stay connected and monitor your health with this stylish smartwatch',
      },
    ],
    stock: 18,
    __v: 0,
    features: [
      'Samsung Galaxy Watch 4 Classic (46mm, Bluetooth)',
      'Large 46mm display for clear visuals',
      'Bluetooth connectivity for seamless pairing',
      'Advanced health monitoring features',
    ],
    numrev: 20,
    rating: 94,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f213',
        rate: 4.9,
        Comment: 'Love the design and functionality. Great fitness companion.',
        date: '2027-11-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7bb',
      },
      {
        user: '63d1089e7ee22fa515771f214',
        rate: 4.7,
        Comment: 'Samsung never disappoints. Impressive watch!',
        date: '2027-11-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7bc',
      },
    ],
    sell: 14,
  },
  {
    _id: '64c37c823c03172a9facc125',
    id: 83,
    title: 'Bose QuietComfort 45 Wireless Noise Cancelling Headphones',
    price: 3499,
    cureted_price: 3999,
    category: 'electronics',
    ratings: 4.9,
    images: [
      {
        _id: '651d50b7e2b392999df75a26',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/headphone/m/w/p/-original-imaguxdnykcjtpkc.jpeg?q=70',
      },
    ],
    seller: 'AudioMasters',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        wireless: true,
        noiseCancelling: true,
        batteryLife: 'Up to 24 hours',
        type: 'Bluetooth',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Bose',
        Color: 'Black',
        Type: 'Wireless Headphones',
        ModelName: 'QuietComfort 45',
        ModelID: 'BOSE-QC45-WIRELESS',
        wireless: true,
        noiseCancelling: true,
        batteryLife: 'Up to 24 hours',
        connectivity: 'Bluetooth',
        Collection: 'QuietComfort Series',
        care: 'Immerse yourself in music with superior comfort and noise cancellation',
      },
    ],
    stock: 25,
    __v: 0,
    features: [
      'Bose QuietComfort 45 Wireless Noise Cancelling Headphones',
      'Wireless with Bluetooth connectivity',
      'Advanced noise cancellation for immersive audio',
      'Up to 24 hours of battery life',
    ],
    numrev: 18,
    rating: 96,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f215',
        rate: 5,
        Comment: 'Incredible sound quality and comfort. Best headphones!',
        date: '2027-12-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7bd',
      },
      {
        user: '63d1089e7ee22fa515771f216',
        rate: 4.8,
        Comment: 'The noise cancellation is a game-changer. Worth every penny.',
        date: '2027-12-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7be',
      },
    ],
    sell: 20,
  },
  {
    _id: '64c37c823c03172a9facc126',
    id: 84,
    title: 'Sony BRAVIA XR A90J 65-inch OLED 4K Ultra HD Smart Google TV',
    price: 249999,
    cureted_price: 279999,
    category: 'electronics',
    ratings: 4.7,
    images: [
      {
        _id: '651d50b7e2b392999df75a27',
        url: 'https://rukminim2.flixcart.com/image/312/312/jpcxrww0/television/x/m/c/sony-kd-65a9f-original-imafbcdfewe32s7u.jpeg?q=70',
      },
    ],
    seller: 'TechWorldElectronics',
    replacement: '45 Days Replacement Policy',
    specificaton: [
      {
        screenSize: '65 inches',
        displayType: 'OLED',
        resolution: '4K Ultra HD',
        type: 'Google TV, Voice Remote',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Sony',
        Color: 'Black',
        Type: 'Smart TV',
        ModelName: 'BRAVIA XR A90J',
        ModelID: 'SONY-A90J-65-OLED',
        screenSize: '65 inches',
        displayType: 'OLED',
        resolution: '4K Ultra HD',
        smartFeatures: 'Google TV, Voice Remote',
        Collection: 'BRAVIA XR Series',
        care: 'Immerse yourself in a cinematic experience with Sony BRAVIA XR technology',
      },
    ],
    stock: 15,
    __v: 0,
    features: [
      'Sony BRAVIA XR A90J 65-inch OLED 4K Ultra HD Smart Google TV',
      'Stunning 65-inch OLED display for vibrant visuals',
      '4K Ultra HD resolution for crystal-clear images',
      'Smart TV features with Google TV and Voice Remote',
    ],
    numrev: 25,
    rating: 94,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f217',
        rate: 4.7,
        Comment: 'Amazing picture quality and smart features. Love it!',
        date: '2028-01-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7bf',
      },
      {
        user: '63d1089e7ee22fa515771f218',
        rate: 4.8,
        Comment: 'The OLED display is a game-changer. Worth the investment.',
        date: '2028-01-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7c0',
      },
    ],
    sell: 18,
  },
  {
    _id: '64c37c823c03172a9facc127',
    id: 85,
    title: 'DJI Mavic Air 2 - Drone Quadcopter UAV',
    price: 79999,
    cureted_price: 89999,
    category: 'electronics',
    ratings: 3.9,
    images: [
      {
        _id: '651d50b7e2b392999df75a28',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/screen-guard/tempered-glass/m/v/4/tempered-glass-lens-screen-protector-for-dji-mavic-mini-dji-mini-original-imagqxyzzydzs5za.jpeg?q=70',
      },
    ],
    seller: 'SkyHighTech',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        flightTime: '34 minutes',
        cameraResolution: '48 MP',
        maxRange: '10 km',
        type: 'SmartPhoto, FocusTrack, APAS 4.0',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'DJI',
        Color: 'Gray',
        Type: 'Drone Quadcopter',
        ModelName: 'Mavic Air 2',
        ModelID: 'DJIAIR2-DRONE',
        flightTime: '34 minutes',
        cameraResolution: '48 MP',
        maxRange: '10 km',
        intelligentModes: 'SmartPhoto, FocusTrack, APAS 4.0',
        Collection: 'Mavic Series',
        care: 'Capture stunning aerial footage with the powerful DJI Mavic Air 2 drone',
      },
    ],
    stock: 12,
    __v: 0,
    features: [
      'DJI Mavic Air 2 - Drone Quadcopter UAV',
      '34 minutes of flight time for extended aerial exploration',
      '48 MP camera for high-resolution aerial photography',
      'Intelligent modes for enhanced photo and video capture',
    ],
    numrev: 15,
    rating: 97,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f219',
        rate: 5,
        Comment: 'Impressive drone. Great camera quality and flight time.',
        date: '2028-02-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7c1',
      },
      {
        user: '63d1089e7ee22fa515771f220',
        rate: 4.9,
        Comment:
          'Easy to fly and captures amazing footage. Highly recommended.',
        date: '2028-02-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7c2',
      },
    ],
    sell: 10,
  },
  {
    _id: '64c37c823c03172a9facc128',
    id: 86,
    title: 'Samsung Odyssey G7 32-inch Curved Gaming Monitor',
    price: 69999,
    cureted_price: 79999,
    category: 'electronics',
    ratings: 4.8,
    images: [
      {
        _id: '651d50b7e2b392999df75a29',
        url: 'https://rukminim2.flixcart.com/image/312/312/xif0q/monitor/2/e/9/ls32bg750nwxxl-uhd-32-ls32bg750nwxxl-samsung-original-imaggfy4pnzbmgz8.jpeg?q=70',
      },
    ],
    seller: 'GamingTechHub',
    replacement: '45 Days Replacement Policy',
    specificaton: [
      {
        screenSize: '32 inches',
        resolution: '2560 x 1440',
        refreshRate: '240 Hz',
        responseTime: '1 ms',
        type: 'GamingTechHub',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Samsung',
        Color: 'Black',
        Type: 'Curved Gaming Monitor',
        ModelName: 'Odyssey G7',
        ModelID: 'SAMSUNG-G7-32-CURVED',
        screenSize: '32 inches',
        resolution: '2560 x 1440',
        refreshRate: '240 Hz',
        responseTime: '1 ms',
        Collection: 'Odyssey Series',
        care: 'Immerse yourself in gaming with the Samsung Odyssey G7 curved gaming monitor',
      },
    ],
    stock: 18,
    __v: 0,
    features: [
      'Samsung Odyssey G7 32-inch Curved Gaming Monitor',
      'Immersive curved display for gaming enthusiasts',
      'High resolution and refresh rate for smooth gameplay',
      '1 ms response time for reduced motion blur',
    ],
    numrev: 20,
    rating: 96,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f221',
        rate: 4.8,
        Comment: 'Absolutely love this gaming monitor. Stunning visuals!',
        date: '2028-03-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7c3',
      },
      {
        user: '63d1089e7ee22fa515771f222',
        rate: 4.9,
        Comment:
          'The 240 Hz refresh rate is a game-changer. Perfect for competitive gaming.',
        date: '2028-03-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7c4',
      },
    ],
    sell: 15,
  },
  {
    _id: '64c37c823c03172a9facc129',
    id: 87,
    title: 'Apple MacBook Pro 16-inch with M2 Pro Chip',
    price: 2299.99,
    cureted_price: 2499.99,
    category: 'electronics',
    ratings: 3.9,
    images: [
      {
        _id: '651d50b7e2b392999df75a30',
        url: 'https://rukminim2.flixcart.com/image/312/312/xif0q/computer/7/r/w/-original-imaguw3hpuzcwvdp.jpeg?q=70',
      },
    ],
    seller: 'AppleStore',
    replacement: '90 Days Replacement Policy',
    specificaton: [
      {
        screenSize: '16 inches',
        processor: 'M2 Pro Chip',
        RAM: '32 GB',
        storage: '1 TB SSD',
        type: 'AppleStore',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Apple',
        Color: 'Space Gray',
        Type: 'Laptop',
        ModelName: 'MacBook Pro 16-inch',
        ModelID: 'APPLE-MBP-16-M2',
        screenSize: '16 inches',
        processor: 'M2 Pro Chip',
        RAM: '32 GB',
        storage: '1 TB SSD',
        Collection: 'MacBook Pro Series',
        care: 'Experience the power of the Apple MacBook Pro with M2 Pro Chip',
      },
    ],
    stock: 10,
    __v: 0,
    features: [
      'Apple MacBook Pro 16-inch with M2 Pro Chip',
      'Powerful M2 Pro Chip for enhanced performance',
      '16-inch Retina display for stunning visuals',
      '32 GB RAM and 1 TB SSD for seamless multitasking',
    ],
    numrev: 18,
    rating: 98,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f223',
        rate: 4.9,
        Comment: 'Absolutely amazing laptop. Fast and reliable.',
        date: '2028-04-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7c5',
      },
      {
        user: '63d1089e7ee22fa515771f224',
        rate: 5,
        Comment: 'Worth every penny. The M2 Pro Chip is a game-changer.',
        date: '2028-04-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7c6',
      },
    ],
    sell: 8,
  },
  {
    _id: '64c37c823c03172a9facc130',
    id: 88,
    title: 'Sony WH-1000XM4 Wireless Noise-Canceling Headphones',
    price: 29999,
    cureted_price: 34999,
    category: 'electronics',
    ratings: 2.7,
    images: [
      {
        _id: '651d50b7e2b392999df75a31',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/headphone/g/m/4/wh-g900n-sony-original-imagg6bgfw7vgtgs.jpeg?q=70',
      },
    ],
    seller: 'AudioEmpire',
    replacement: '60 Days Replacement Policy',
    specificaton: [
      {
        type: 'Over-Ear',
        wireless: 'Yes',
        noiseCancellation: 'Yes',
        batteryLife: '30 hours',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Sony',
        Color: 'Black',
        Type: 'Wireless Headphones',
        ModelName: 'WH-1000XM4',
        ModelID: 'SONY-WH-1000XM4',
        type: 'Over-Ear',
        wireless: 'Yes',
        noiseCancellation: 'Yes',
        batteryLife: '30 hours',
        Collection: 'WH Series',
        care: 'Immerse yourself in music with the Sony WH-1000XM4 Wireless Noise-Canceling Headphones',
      },
    ],
    stock: 15,
    __v: 0,
    features: [
      'Sony WH-1000XM4 Wireless Noise-Canceling Headphones',
      'Industry-leading noise cancellation for immersive audio experience',
      'Wireless connectivity for convenient usage',
      '30 hours of battery life for extended listening sessions',
    ],
    numrev: 25,
    rating: 95,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f225',
        rate: 4.7,
        Comment: 'Top-notch noise cancellation. Comfortable for long use.',
        date: '2028-05-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7c7',
      },
      {
        user: '63d1089e7ee22fa515771f226',
        rate: 4.8,
        Comment: 'Excellent sound quality. The battery life is impressive.',
        date: '2028-05-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7c8',
      },
    ],
    sell: 20,
  },
  {
    _id: '64c37c823c03172a9facc131',
    id: 89,
    title: 'DJI Mavic Air 2 Drone',
    price: 799.99,
    cureted_price: 899.99,
    category: 'electronics',
    ratings: 3.9,
    images: [
      {
        _id: '651d50b7e2b392999df75a32',
        url: 'https://rukminim2.flixcart.com/image/312/312/xif0q/drone/m/h/q/40-24-1-4k-wifi-dual-camera-drone-for-adults-kids-with-2-original-imagrfa6esxteknr.jpeg?q=70',
      },
    ],
    seller: 'SkyTechInnovations',
    replacement: '45 Days Replacement Policy',
    specificaton: [
      {
        type: 'Quadcopter',
        cameraResolution: '48 MP',
        flightTime: '34 minutes',
        maxRange: '10 km',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'DJI',
        Color: 'Gray',
        Type: 'Drone',
        ModelName: 'Mavic Air 2',
        ModelID: 'DJ-Mavic-Air-2',
        type: 'Quadcopter',
        cameraResolution: '48 MP',
        flightTime: '34 minutes',
        maxRange: '10 km',
        Collection: 'Mavic Series',
        care: 'Capture stunning aerial footage with the DJI Mavic Air 2 Drone',
      },
    ],
    stock: 12,
    __v: 0,
    features: [
      'DJI Mavic Air 2 Drone',
      'High-resolution camera for professional-quality photos and videos',
      'Long flight time and impressive range for extended exploration',
      'Compact and foldable design for portability',
    ],
    numrev: 15,
    rating: 97,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f227',
        rate: 4.9,
        Comment: 'Incredible drone. The camera quality is exceptional.',
        date: '2028-06-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7c9',
      },
      {
        user: '63d1089e7ee22fa515771f228',
        rate: 5,
        Comment:
          'Easy to fly and captures breathtaking shots. Highly recommended.',
        date: '2028-06-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7ca',
      },
    ],
    sell: 18,
  },
  {
    _id: '64c37c823c03172a9facc133',
    id: 91,
    title: 'Bose QuietComfort 35 II Wireless Noise-Canceling Headphones',
    price: 27999,
    cureted_price: 32999,
    category: 'electronics',
    ratings: 4.9,
    images: [
      {
        _id: '651d50b7e2b392999df75a34',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/headphone/y/c/o/tws345-with-high-resolution-audio-48hr-playtime-led-digital-original-imagwnkq5qkmzkpf.jpeg?q=70',
      },
    ],
    seller: 'AudioEnclave',
    replacement: '45 Days Replacement Policy',
    specificaton: [
      {
        type: 'Over-Ear',
        wireless: 'Yes',
        noiseCancellation: 'Yes',
        batteryLife: '20 hours',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Bose',
        Color: 'Silver',
        Type: 'Wireless Headphones',
        ModelName: 'QuietComfort 35 II',
        ModelID: 'BOSE-QC35II',
        type: 'Over-Ear',
        wireless: 'Yes',
        noiseCancellation: 'Yes',
        batteryLife: '20 hours',
        Collection: 'QuietComfort Series',
        care: 'Experience premium audio quality with Bose QuietComfort 35 II Wireless Noise-Canceling Headphones',
      },
    ],
    stock: 18,
    __v: 0,
    features: [
      'Bose QuietComfort 35 II Wireless Noise-Canceling Headphones',
      'World-class noise cancellation for a peaceful listening experience',
      'Wireless connectivity for convenience and flexibility',
      'Up to 20 hours of battery life for all-day usage',
    ],
    numrev: 28,
    rating: 98,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f231',
        rate: 4.9,
        Comment:
          'Incredible comfort and superb noise cancellation. A must-have!',
        date: '2028-08-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7cd',
      },
      {
        user: '63d1089e7ee22fa515771f232',
        rate: 5,
        Comment: 'Excellent build quality and the sound is just amazing.',
        date: '2028-08-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7ce',
      },
    ],
    sell: 22,
  },
  {
    _id: '64c37c823c03172a9facc134',
    id: 92,
    title: 'Sony PlayStation 5 Gaming Console',
    price: 49999,
    cureted_price: 54999,
    category: 'electronics',
    ratings: 3.9,
    images: [
      {
        _id: '651d50b7e2b392999df75a35',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/gamingconsole/z/b/w/-original-imagtk7vfbzqbjg6.jpeg?q=70',
      },
    ],
    seller: 'GamingWorld',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        type: 'Gaming Console',
        storageCapacity: '825 GB SSD',
        resolution: '4K UHD',
        frameRate: 'Up to 120fps',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Sony',
        Color: 'Black',
        Type: 'Gaming Console',
        ModelName: 'PlayStation 5',
        ModelID: 'SONY-PS5',
        type: 'Gaming Console',
        storageCapacity: '825 GB SSD',
        resolution: '4K UHD',
        frameRate: 'Up to 120fps',
        Collection: 'PlayStation Series',
        care: 'Experience next-gen gaming with Sony PlayStation 5 Gaming Console',
      },
    ],
    stock: 15,
    __v: 0,
    features: [
      'Sony PlayStation 5 Gaming Console',
      'Ultra-fast SSD for quick loading and smoother gaming',
      'Immersive 4K UHD graphics and high frame rate for a stunning gaming experience',
      'Backward compatibility for playing a vast library of PS4 games',
    ],
    numrev: 25,
    rating: 97,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f233',
        rate: 4.9,
        Comment: 'Absolutely amazing. The graphics and speed are mind-blowing.',
        date: '2028-09-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7cf',
      },
      {
        user: '63d1089e7ee22fa515771f234',
        rate: 5,
        Comment: 'Best gaming console in the market. Worth every penny!',
        date: '2028-09-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7d0',
      },
    ],
    sell: 20,
  },
]

export default Electronics
