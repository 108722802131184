import Toys from './Toys'
import TwoWheelers from './TwoWheelers'

const Data = [
  {
    _id: '64c37c823c03172a9facc073',
    id: 31,
    title: 'You are the Best Wife',
    price: 249,
    cureted_price: 350,
    category: 'books',
    ratings: 4.5,
    images: [
      {
        _id: '651d50b7e2b392999df759e6',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/book/5/p/j/you-are-the-best-wife-original-imagn5nzxqxq2nah.jpeg?q=70',
      },
    ],
    seller: 'SrishtiPD',
    replacement: '10 Days Replacement Policy',
    specificaton: [
      {
        author: 'Ajay K Pandey',
        genre: 'Fiction',
        format: 'paperback',
        type: 'Fiction book',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'TamilPusthakam',
        Color: 'blue',
        Type: 'Fiction',
        ModelName: 'Best_Wife',
        ModelID: 'Best_Wife',
        author: 'Ajay K Pandey',
        genre: 'Fiction',
        format: 'paperback',
        Collection: 'love',
        care: 'Handle with care to maintain book condition',
      },
    ],
    stock: 25,
    __v: 0,
    features: [
      'Practical guide to mindfulness and meditation',
      'Authored by a meditation expert',
      'Available in paperback format',
    ],
    numrev: 5,
    rating: 89,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f111',
        rate: 4.5,
        Comment:
          "This book has inspired me alot... Ajay sir and Bhavna mam's love for each other is immortal... it will always remain in the heart of all the readers... 💞💞",
        date: '2024-05-02T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae731',
      },
      {
        user: '63d1089e7ee22fa515771f112',
        rate: 4.8,
        Comment: 'heart touching book. imotinal Ending you may cry.',
        date: '2024-05-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae732',
      },
    ],
    sell: 18,
  },
  {
    _id: '64c37c823c03172a9facc062',
    id: 20,
    title:
      'The Memoirs of Sherlock Holmes  (English, Paperback, Doyle Arthur Conan)',
    price: 203,
    cureted_price: 229,
    category: 'books',
    ratings: 4.7,
    images: [
      {
        _id: '651d50b7e2b392999df759db',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/book/m/p/g/-original-imagnhhauzvm4kjf.jpeg?q=70',
      },
    ],
    seller: 'BookHaven',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        author: 'Emma L. Archer',
        genre: 'Mystery',
        format: 'Paperback',
        type: 'Mystery',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'BookHaven',
        Color: 'Assorted',
        Type: 'Mystery Novel',
        ModelName: 'SILENT-OBSERVER-NOVEL',
        ModelID: 'SILENT-OBSERVER-NOVEL',
        author: 'Emma L. Archer',
        genre: 'Mystery',
        format: 'Paperback',
        Collection: 'bestseller novels',
        care: 'Handle with care to maintain book condition',
      },
    ],
    stock: 30,
    __v: 0,
    features: [
      'Engaging mystery storyline',
      'Well-written by renowned author',
      'Available in paperback format',
    ],
    numrev: 15,
    rating: 93,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f89',
        rate: 5,
        Comment: "Couldn't put it down! Highly recommended.",
        date: '2024-01-05T12:55:30.892Z',
        _id: '64e9d16786e6fe3e932ae709',
      },
      {
        user: '63d1089e7ee22fa515771f90',
        rate: 4.5,
        Comment: 'Captivating mystery. A must-read.',
        date: '2024-01-08T11:15:12.892Z',
        _id: '64e9d16786e6fe3e932ae710',
      },
    ],
    sell: 25,
  },
  {
    _id: '64c37c823c03172a9facc063',
    id: 21,
    title: 'Beyond the Horizon: Sci-Fi Adventure',
    price: 473,
    cureted_price: 2499,
    category: 'books',
    ratings: 4.5,
    images: [
      {
        _id: '651d50b7e2b392999df759dc',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/book/k/b/p/beyond-the-blue-horizon-original-imagjw996pfwsyqu.jpeg?q=70',
      },
    ],
    seller: 'GalacticBooks',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        author: 'Jason A. Starlight',
        genre: 'Sci-Fi',
        format: 'Hardcover',
        type: 'Fiction book',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'GalacticBooks',
        Color: 'Assorted',
        Type: 'Sci-Fi Adventure',
        ModelName: 'BEYOND-HORIZON-SCI-FI',
        ModelID: 'BEYOND-HORIZON-SCI-FI',
        author: 'Jason A. Starlight',
        genre: 'Sci-Fi',
        format: 'Hardcover',
        Collection: 'bestseller novels',
        care: 'Keep away from direct sunlight',
      },
    ],
    stock: 22,
    __v: 0,
    features: [
      'Exciting sci-fi adventure',
      'Written by acclaimed author',
      'Available in hardcover format',
    ],
    numrev: 11,
    rating: 92,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f91',
        rate: 5,
        Comment: 'Mind-blowing adventure! Loved every page.',
        date: '2024-01-12T10:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae711',
      },
      {
        user: '63d1089e7ee22fa515771f92',
        rate: 4.5,
        Comment: 'Engaging storyline. Must-read for sci-fi fans.',
        date: '2024-01-15T08:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae712',
      },
    ],
    sell: 18,
  },
  {
    _id: '64c37c823c03172a9facc071',
    id: 29,
    title: 'Wise & Otherwise',
    price: 349,
    cureted_price: 500,
    category: 'books',
    ratings: 4.6,
    images: [
      {
        _id: '651d50b7e2b392999df759e4',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/book/u/r/i/wise-otherwise-original-imagu7sasyjqxhyf.jpeg?q=70',
      },
    ],
    seller: 'BookNook',
    replacement: '10 Days Replacement Policy',
    specificaton: [
      {
        author: 'Sudha Murthy',
        genre: 'Non-Fiction',
        format: 'Paperback',
        type: 'Non-Fiction book',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'BookNook',
        Color: 'Assorted',
        Type: 'Non-Fiction',
        ModelName: 'MILK-STOPPED',
        ModelID: 'MILK-STOPPED',
        author: 'Sudha Murthy',
        genre: 'Non-Fiction',
        format: 'Paperback',
        Collection: 'Inspirational Stories for Adults',
        care: 'Handle with care to maintain book condition',
      },
    ],
    stock: 15,
    __v: 0,
    features: [
      'Compelling non-fiction narrative',
      'Authored by Sudha Murthy',
      'Available in paperback format',
    ],
    numrev: 9,
    rating: 88,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f107',
        rate: 4.7,
        Comment:
          "A thought-provoking book. Sudha Murthy's writing is captivating.",
        date: '2024-04-10T14:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae727',
      },
      {
        user: '63d1089e7ee22fa515771f108',
        rate: 4.5,
        Comment: "Excellent insights into life's experiences.",
        date: '2024-04-12T11:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae728',
      },
    ],
    sell: 8,
  },
  {
    _id: '64c37c823c03172a9facc064',
    id: 22,
    title: 'The Enchanted Garden: Fantasy Tale',
    price: 486,
    cureted_price: 1539,
    category: 'books',
    ratings: 4.8,
    images: [
      {
        _id: '651d50b7e2b392999df759dd',
        url: 'https://rukminim2.flixcart.com/image/612/612/kg8avm80-0/poster/k/m/1/small-azart15816104pos-fr-l-01-gd-fantasy-garden-with-tree-original-imafwgeeguybmvjg.jpeg?q=70',
      },
    ],
    seller: 'MysticalPress',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        author: 'Isabella Moonlight',
        genre: 'Fantasy',
        format: 'Paperback',
        type: 'Fantasy book',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'MysticalPress',
        Color: 'Assorted',
        Type: 'Fantasy Tale',
        ModelName: 'ENCHANTED-GARDEN-FANTASY',
        ModelID: 'ENCHANTED-GARDEN-FANTASY',
        author: 'Isabella Moonlight',
        genre: 'Fantasy',
        format: 'Paperback',
        Collection: 'bestseller novels',
        care: 'Handle with care to maintain book condition',
      },
    ],
    stock: 28,
    __v: 0,
    features: [
      'Whimsical fantasy tale',
      'Crafted by talented author',
      'Available in paperback format',
    ],
    numrev: 13,
    rating: 95,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f93',
        rate: 5,
        Comment: 'Magical journey! Beautifully written.',
        date: '2024-01-22T12:55:30.892Z',
        _id: '64e9d16786e6fe3e932ae713',
      },
      {
        user: '63d1089e7ee22fa515771f94',
        rate: 4.5,
        Comment: 'Captivating world. Highly recommended.',
        date: '2024-01-25T11:15:12.892Z',
        _id: '64e9d16786e6fe3e932ae714',
      },
    ],
    sell: 24,
  },
  {
    _id: '64c37c823c03172a9facc065',
    id: 23,
    title: 'CodeCraft: Mastering Web Development',
    price: 330,
    cureted_price: 360,
    category: 'books',
    ratings: 2.9,
    images: [
      {
        _id: '651d50b7e2b392999df759de',
        url: 'https://rukminim2.flixcart.com/image/612/612/book/7/9/9/web-designing-and-development-training-guide-original-imae9dfdjadxpcfk.jpeg?q=70',
      },
    ],
    seller: 'TechBooks',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        author: 'Alex Coder',
        genre: 'Technology',
        format: 'Hardcover',
        type: 'Technology book',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'TechBooks',
        Color: 'Assorted',
        Type: 'Web Development',
        ModelName: 'CODECRAFT-WEB-DEVELOPMENT',
        ModelID: 'CODECRAFT-WEB-DEVELOPMENT',
        author: 'Alex Coder',
        genre: 'Technology',
        format: 'Hardcover',
        Collection: 'coding and development',
        care: 'Store in a cool, dry place',
      },
    ],
    stock: 20,
    __v: 0,
    features: [
      'Comprehensive guide to web development',
      'Written by expert coder',
      'High-quality hardcover format',
    ],
    numrev: 18,
    rating: 98,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f95',
        rate: 5,
        Comment: 'Invaluable resource for developers. Must-have!',
        date: '2024-02-01T10:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae715',
      },
      {
        user: '63d1089e7ee22fa515771f96',
        rate: 4.5,
        Comment: 'Clear explanations. Great for learning.',
        date: '2024-02-05T08:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae716',
      },
    ],
    sell: 15,
  },
  {
    _id: '64c37c823c03172a9facc066',
    id: 24,
    title: 'The Art of Mindfulness: A Guide to Meditation',
    price: 720,
    cureted_price: 750,
    category: 'books',
    ratings: 3.6,
    images: [
      {
        _id: '651d50b7e2b392999df759df',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/book/y/3/2/a-mindful-year-original-imagr75juhzwqnt8.jpeg?q=70',
      },
    ],
    seller: 'ZenReads',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        author: 'Elena Tranquil',
        genre: 'Self-Help',
        format: 'Paperback',
        type: 'Self-Help book',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'ZenReads',
        Color: 'Assorted',
        Type: 'Mindfulness Meditation Guide',
        ModelName: 'ART-OF-MINDFULNESS',
        ModelID: 'ART-OF-MINDFULNESS',
        author: 'Elena Tranquil',
        genre: 'Self-Help',
        format: 'Paperback',
        Collection: 'mindfulness and meditation',
        care: 'Handle with care to maintain book condition',
      },
    ],
    stock: 25,
    __v: 0,
    features: [
      'Practical guide to mindfulness and meditation',
      'Authored by a meditation expert',
      'Available in paperback format',
    ],
    numrev: 9,
    rating: 90,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f97',
        rate: 4.5,
        Comment: 'Helpful guide for beginners. Enjoyed it.',
        date: '2024-02-12T12:55:30.892Z',
        _id: '64e9d16786e6fe3e932ae717',
      },
      {
        user: '63d1089e7ee22fa515771f98',
        rate: 4,
        Comment: 'Clear instructions. Great introduction to mindfulness.',
        date: '2024-02-15T11:15:12.892Z',
        _id: '64e9d16786e6fe3e932ae718',
      },
    ],
    sell: 20,
  },
  {
    _id: '64c37c823c03172a9facc067',
    id: 25,
    title: 'The Tunguska Mystery  (English, Paperback, Rubtsov Vladimir)',
    price: 2199,
    cureted_price: 3499,
    category: 'books',
    ratings: 2.7,
    images: [
      {
        _id: '651d50b7e2b392999df759e0',
        url: 'https://rukminim2.flixcart.com/image/612/612/kjd6nww0-0/book/q/9/t/the-tunguska-mystery-original-imafyxjs4dhnzb7z.jpeg?q=70',
      },
    ],
    seller: 'CosmicBooks',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        author: 'Dr. Alan Quantum',
        genre: 'Science',
        format: 'Hardcover',
        type: 'Science book',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'CosmicBooks',
        Color: 'Assorted',
        Type: 'Physics Exploration',
        ModelName: 'QUANTUM-PARADOX',
        ModelID: 'QUANTUM-PARADOX',
        author: 'Dr. Alan Quantum',
        genre: 'Science',
        format: 'Hardcover',
        Collection: 'science and physics',
        care: 'Handle with care to maintain book condition',
      },
    ],
    stock: 18,
    __v: 0,
    features: [
      'In-depth exploration of quantum physics',
      'Authored by renowned physicist',
      'High-quality hardcover format',
    ],
    numrev: 12,
    rating: 94,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f99',
        rate: 5,
        Comment: 'Fascinating read! Mind-bending concepts explained well.',
        date: '2024-02-22T10:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae719',
      },
      {
        user: '63d1089e7ee22fa515771f100',
        rate: 4.5,
        Comment: 'Complex topics made accessible. Highly recommended.',
        date: '2024-02-25T08:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae720',
      },
    ],
    sell: 14,
  },
  {
    _id: '64c37c823c03172a9facc068',
    id: 26,
    title: 'Littlebuds 100 cm An Art Of Cooking Removable Sticker  (Pack of 1)',
    price: 299,
    cureted_price: 699,
    category: 'books',
    ratings: 4.8,
    images: [
      {
        _id: '651d50b7e2b392999df759e1',
        url: 'https://rukminim2.flixcart.com/image/612/612/l3j2cnk0/book/v/s/a/-original-imagemu5zjyzkt9u.jpeg?q=70',
      },
    ],
    seller: 'EpicureanPress',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        author: 'Chef Isabella Gourmet',
        genre: 'Cooking',
        format: 'Hardcover',
        type: 'Cooking book',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'EpicureanPress',
        Color: 'Assorted',
        Type: 'Cooking Masterclass',
        ModelName: 'CULINARY-MASTERCHEF',
        ModelID: 'CULINARY-MASTERCHEF',
        author: 'Chef Isabella Gourmet',
        genre: 'Cooking',
        format: 'Hardcover',
        Collection: 'culinary arts',
        care: 'Handle with care to maintain book condition',
      },
    ],
    stock: 20,
    __v: 0,
    features: [
      'Comprehensive guide to culinary arts',
      'Authored by renowned chef',
      'High-quality hardcover format',
    ],
    numrev: 15,
    rating: 96,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f101',
        rate: 5,
        Comment: 'Amazing recipes! A must-have for food enthusiasts.',
        date: '2024-03-02T12:55:30.892Z',
        _id: '64e9d16786e6fe3e932ae721',
      },
      {
        user: '63d1089e7ee22fa515771f102',
        rate: 4.5,
        Comment: 'Excellent tips. Beautifully presented.',
        date: '2024-03-05T11:15:12.892Z',
        _id: '64e9d16786e6fe3e932ae722',
      },
    ],
    sell: 18,
  },
  {
    _id: '64c37c823c03172a9facc069',
    id: 27,
    title:
      "Philip's Stargazing 2023 Month-by-Month Guide to the Night Sky Britain & Ireland ",
    price: 733,
    cureted_price: 1100,
    category: 'books',
    ratings: 4.6,
    images: [
      {
        _id: '651d50b7e2b392999df759e2',
        url: 'https://rukminim2.flixcart.com/image/612/612/l2tcfbk0/book/d/f/w/philip-s-stargazing-2023-month-by-month-guide-to-the-night-sky-original-image2bh5vzg2eyh.jpeg?q=70',
      },
    ],
    seller: 'AstroBooks',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        author: 'Dr. Celeste Astronomer',
        genre: 'Science',
        format: 'Paperback',
        type: 'Science book',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'AstroBooks',
        Color: 'Assorted',
        Type: 'Stargazing Guide',
        ModelName: 'CELESTIAL-ATLAS',
        ModelID: 'CELESTIAL-ATLAS',
        author: 'Dr. Celeste Astronomer',
        genre: 'Science',
        format: 'Paperback',
        Collection: 'astronomy and stargazing',
        care: 'Handle with care to maintain book condition',
      },
    ],
    stock: 22,
    __v: 0,
    features: [
      'Comprehensive guide to stargazing',
      'Authored by renowned astronomer',
      'Available in paperback format',
    ],
    numrev: 8,
    rating: 89,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f103',
        rate: 4.5,
        Comment: 'Fascinating insights. Perfect for amateur astronomers.',
        date: '2024-03-12T10:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae723',
      },
      {
        user: '63d1089e7ee22fa515771f104',
        rate: 4,
        Comment: 'Clear explanations. Beautiful illustrations.',
        date: '2024-03-15T08:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae724',
      },
    ],
    sell: 16,
  },
  {
    _id: '64c37c823c03172a9facc070',
    id: 28,
    title: 'The Bird with Golden Wings',
    price: 299,
    cureted_price: 450,
    category: 'books',
    ratings: 4.8,
    images: [
      {
        _id: '651d50b7e2b392999df759e3',
        url: 'https://rukminim2.flixcart.com/image/612/612/krdtlzk0/regionalbooks/i/x/c/the-bird-with-golden-wings-stories-of-wit-and-magic-english-original-imag56jy4tgpswnz.jpeg?q=70',
      },
    ],
    seller: 'StorytellerBooks',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        author: 'Sudha Murthy',
        genre: "Children's Fiction",
        format: 'Hardcover',
        type: 'Children Fiction book',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'StorytellerBooks',
        Color: 'Assorted',
        Type: "Children's Book",
        ModelName: 'GOLDEN-WINGS',
        ModelID: 'GOLDEN-WINGS',
        author: 'Sudha Murthy',
        genre: "Children's Fiction",
        format: 'Hardcover',
        Collection: 'Inspirational Stories for Children',
        care: 'Handle with care to maintain book condition',
      },
    ],
    stock: 18,
    __v: 0,
    features: [
      "Heartwarming children's fiction",
      'Written by Sudha Murthy',
      'Available in hardcover format',
    ],
    numrev: 6,
    rating: 92,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f105',
        rate: 5,
        Comment: 'A beautiful story that teaches valuable lessons.',
        date: '2024-04-02T12:15:30.892Z',
        _id: '64e9d16786e6fe3e932ae725',
      },
      {
        user: '63d1089e7ee22fa515771f106',
        rate: 4.5,
        Comment: 'My child loved reading it. Highly recommended!',
        date: '2024-04-05T09:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae726',
      },
    ],
    sell: 12,
  },
  {
    _id: '64c37c823c03172a9facc072',
    id: 30,
    title: 'Mastering Mathematics: Advanced Concepts',
    price: 499,
    cureted_price: 699,
    category: 'books',
    ratings: 3.9,
    images: [
      {
        _id: '651d50b7e2b392999df759e5',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/regionalbooks/8/2/l/hive-mind-foundation-course-class-6-maths-science-english-3-original-imagsgmk7zye3ntr.jpeg?q=70',
      },
    ],
    seller: 'MathGeniusBooks',
    replacement: '15 Days Replacement Policy',
    specificaton: [
      {
        author: 'Prof. A. Mathison',
        genre: 'Academic',
        format: 'Hardcover',
        type: 'Academic book',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'MathGeniusBooks',
        Color: 'Assorted',
        Type: 'Academic',
        ModelName: 'MATH-ADVANCED',
        ModelID: 'MATH-ADVANCED',
        author: 'Prof. A. Mathison',
        genre: 'Academic',
        format: 'Hardcover',
        Collection: 'Advanced Mathematics',
        care: 'Handle with care to maintain book condition',
      },
    ],
    stock: 20,
    __v: 0,
    features: [
      'Comprehensive guide to advanced mathematics',
      'Authored by renowned mathematician',
      'Available in hardcover format',
    ],
    numrev: 12,
    rating: 95,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f109',
        rate: 5,
        Comment:
          'A must-have for anyone serious about mastering advanced mathematics.',
        date: '2024-04-18T10:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae729',
      },
      {
        user: '63d1089e7ee22fa515771f110',
        rate: 4.8,
        Comment:
          'Clear explanations and challenging problems. Excellent resource.',
        date: '2024-04-22T09:15:22.892Z',
        _id: '64e9d16786e6fe3e932ae730',
      },
    ],
    sell: 15,
  },
  //fashion
  {
    _id: '64c37c823c03172a9facc075',
    id: 33,
    title: 'Classic Denim Jacket',
    price: 899,
    cureted_price: 1299,
    category: 'fashion',
    ratings: 4.7,
    images: [
      {
        _id: '651d50b7e2b392999df759e8',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/jacket/w/l/3/m-1-no-dlmjks068-dennis-lingo-original-imaggqbdfaezgtsj.jpeg?q=70',
      },
    ],
    seller: 'StyleHub',
    replacement: '15 Days Replacement Policy',
    specificaton: [
      {
        material: 'Denim',
        color: 'Blue',
        size: 'M',
        brand: 'FashionRise',
        type: 'Regular Fit',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'FashionRise',
        Color: 'Blue',
        Type: 'Denim Jacket',
        ModelName: 'CLASSIC-DENIM',
        ModelID: 'CLASSIC-DENIM',
        material: 'Denim',
        size: 'M',
        fit: 'Regular Fit',
        Collection: "Men's Fashion",
        care: 'Machine washable, gentle cycle',
      },
    ],
    stock: 28,
    __v: 0,
    features: [
      'Classic denim jacket for men',
      'Made with high-quality denim material',
      'Regular fit and stylish design',
    ],
    numrev: 10,
    rating: 94,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f115',
        rate: 4.8,
        Comment: 'Great quality denim jacket. Fits perfectly!',
        date: '2024-05-22T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae735',
      },
      {
        user: '63d1089e7ee22fa515771f116',
        rate: 4.5,
        Comment: 'Excellent purchase. Stylish and comfortable.',
        date: '2024-05-25T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae736',
      },
    ],
    sell: 20,
  },
  {
    _id: '64c37c823c03172a9facc076',
    id: 34,
    title: 'Slim Fit Cotton Chinos',
    price: 699,
    cureted_price: 999,
    category: 'fashion',
    ratings: 3.5,
    images: [
      {
        _id: '651d50b7e2b392999df759e9',
        url: 'https://rukminim2.flixcart.com/image/612/612/k1b1bbk0/trouser/v/f/t/32-chino01b-3-the-indian-garage-co-original-imafkwmtughgbxgp.jpeg?q=70',
      },
    ],
    seller: 'TrendSetterClothing',
    replacement: '10 Days Replacement Policy',
    specificaton: [
      {
        material: 'Cotton',
        color: 'Khaki',
        size: '32',
        brand: 'TrendSetter',
        type: 'Slim Fit',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'TrendSetter',
        Color: 'Khaki',
        Type: 'Cotton Chinos',
        ModelName: 'SLIM-COTTON-CHINOS',
        ModelID: 'SLIM-COTTON-CHINOS',
        material: 'Cotton',
        size: '32',
        fit: 'Slim Fit',
        Collection: "Men's Fashion",
        care: 'Machine wash cold, tumble dry low',
      },
    ],
    stock: 25,
    __v: 0,
    features: [
      'Slim fit cotton chinos for a stylish look',
      'Made with comfortable cotton material',
      'Versatile khaki color for various outfits',
    ],
    numrev: 8,
    rating: 91,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f117',
        rate: 4.5,
        Comment: 'Love the slim fit. Great quality chinos.',
        date: '2024-06-01T14:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae737',
      },
      {
        user: '63d1089e7ee22fa515771f118',
        rate: 4.3,
        Comment: 'Perfect for both casual and semi-formal occasions.',
        date: '2024-06-05T11:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae738',
      },
    ],
    sell: 18,
  },
  {
    _id: '64c37c823c03172a9facc077',
    id: 35,
    title: 'Classic Blue Denim Jeans',
    price: 799,
    cureted_price: 1199,
    category: 'fashion',
    ratings: 4.0,
    images: [
      {
        _id: '651d50b7e2b392999df759ea',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/jean/n/v/p/32-fmjen2187-flying-machine-original-imagt77q47cmzgr7.jpeg?q=70',
      },
    ],
    seller: 'FashionHub',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        material: 'Denim',
        color: 'Blue',
        size: '34',
        brand: 'StyleMasters',
        type: 'Regular Fit',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'StyleMasters',
        Color: 'Blue',
        Type: 'Denim Jeans',
        ModelName: 'CLASSIC-DENIM-JEANS',
        ModelID: 'CLASSIC-DENIM-JEANS',
        material: 'Denim',
        size: '34',
        fit: 'Regular Fit',
        Collection: "Men's Fashion",
        care: 'Machine wash inside out, tumble dry low',
      },
    ],
    stock: 20,
    __v: 0,
    features: [
      'Classic blue denim jeans for a timeless look',
      'Made with durable and comfortable denim material',
      'Regular fit for a versatile style',
    ],
    numrev: 11,
    rating: 93,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f119',
        rate: 4.7,
        Comment: 'Perfect fit and excellent quality denim.',
        date: '2024-06-12T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae739',
      },
      {
        user: '63d1089e7ee22fa515771f120',
        rate: 4.5,
        Comment: 'Love the classic blue color. Comfortable to wear.',
        date: '2024-06-15T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae740',
      },
    ],
    sell: 15,
  },
  {
    _id: '64c37c823c03172a9facc078',
    id: 36,
    title: 'Casual Cotton Crew Neck T-Shirt',
    price: 349,
    cureted_price: 499,
    category: 'fashion',
    ratings: 4.4,
    images: [
      {
        _id: '651d50b7e2b392999df759eb',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/t-shirt/o/t/t/s-udtsh1376-u-s-polo-assn-denim-co-original-imagm6xgrnqyxzzu.jpeg?q=70',
      },
    ],
    seller: 'UrbanStyles',
    replacement: '10 Days Replacement Policy',
    specificaton: [
      {
        material: 'Cotton',
        color: 'Heather Gray',
        size: 'L',
        brand: 'FashionEase',
        type: 'Regular Fit',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'FashionEase',
        Color: 'Heather Gray',
        Type: 'Cotton T-Shirt',
        ModelName: 'CASUAL-COTTON-TSHIRT',
        ModelID: 'CASUAL-COTTON-TSHIRT',
        material: 'Cotton',
        size: 'L',
        fit: 'Regular Fit',
        Collection: "Men's Fashion",
        care: 'Machine wash cold, do not bleach',
      },
    ],
    stock: 25,
    __v: 0,
    features: [
      'Casual cotton crew neck t-shirt for everyday wear',
      'Made with soft and breathable cotton material',
      'Heather gray color for a versatile look',
    ],
    numrev: 9,
    rating: 88,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f121',
        rate: 4.3,
        Comment: 'Comfortable and good quality cotton t-shirt.',
        date: '2024-07-01T14:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae741',
      },
      {
        user: '63d1089e7ee22fa515771f122',
        rate: 4.5,
        Comment: 'Nice fit and stylish design. Happy with the purchase.',
        date: '2024-07-05T11:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae742',
      },
    ],
    sell: 20,
  },

  //womens
  {
    _id: '64c37c823c03172a9facc079',
    id: 37,
    title: 'Elegant Silk Saree with Zari Border',
    price: 1499,
    cureted_price: 2199,
    category: 'fashion',
    ratings: 2.8,
    images: [
      {
        _id: '651d50b7e2b392999df759ec',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/sari/h/x/z/free-s-red-omsaienterprise-unstitched-original-imagngpuztzz6gn2.jpeg?q=70',
      },
    ],
    seller: 'EthnicEmporium',
    replacement: '15 Days Replacement Policy',
    specificaton: [
      {
        material: 'Silk',
        color: 'Royal Blue',
        brand: 'EthnicCrafts',
        type: 'Traditional Zari Border',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'EthnicCrafts',
        Color: 'Royal Blue',
        Type: 'Silk Saree',
        ModelName: 'ELEGANT-SILK-SAREE',
        ModelID: 'ELEGANT-SILK-SAREE',
        material: 'Silk',
        design: 'Traditional Zari Border',
        Collection: "Women's Fashion",
        care: 'Dry clean recommended',
      },
    ],
    stock: 30,
    __v: 0,
    features: [
      'Elegant silk saree with traditional zari border',
      'Made with high-quality silk material',
      'Royal blue color for a regal look',
    ],
    numrev: 12,
    rating: 96,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f123',
        rate: 4.9,
        Comment: 'Absolutely beautiful saree. Love the design.',
        date: '2024-07-12T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae743',
      },
      {
        user: '63d1089e7ee22fa515771f124',
        rate: 4.7,
        Comment: 'Great quality silk saree. Worth the price.',
        date: '2024-07-15T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae744',
      },
    ],
    sell: 25,
  },
  {
    _id: '64c37c823c03172a9facc080',
    id: 38,
    title: 'Floral Print Cotton Kurti',
    price: 799,
    cureted_price: 1199,
    category: 'fashion',
    ratings: 3.6,
    images: [
      {
        _id: '651d50b7e2b392999df759ed',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/kurta/s/j/o/xl-snt-long-flared-kurta-1004-style-n-trend-original-imagbvnxkfeyqgx6-bb.jpeg?q=70',
      },
    ],
    seller: 'EthnicTrends',
    replacement: '10 Days Replacement Policy',
    specificaton: [
      {
        material: 'Cotton',
        color: 'Multicolor',
        size: 'XL',
        brand: 'EthnicTrends',
        type: 'Floral Print',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'EthnicTrends',
        Color: 'Multicolor',
        Type: 'Cotton Kurti',
        ModelName: 'FLORAL-COTTON-KURTI',
        ModelID: 'FLORAL-COTTON-KURTI',
        material: 'Cotton',
        style: 'Floral Print',
        size: 'XL',
        Collection: "Women's Fashion",
        care: 'Hand wash recommended',
      },
    ],
    stock: 22,
    __v: 0,
    features: [
      'Floral print cotton kurti for a vibrant look',
      'Made with comfortable and breathable cotton material',
      'Multicolor design for versatile styling',
    ],
    numrev: 9,
    rating: 92,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f125',
        rate: 4.5,
        Comment: 'Beautiful kurti. Fits well and comfortable.',
        date: '2024-08-01T14:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae745',
      },
      {
        user: '63d1089e7ee22fa515771f126',
        rate: 4.7,
        Comment: 'Love the floral print. Great quality.',
        date: '2024-08-05T11:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae746',
      },
    ],
    sell: 18,
  },
  {
    _id: '64c37c823c03172a9facc081',
    id: 39,
    title: 'Embroidered Kurthi Set with Palazzo',
    price: 1299,
    cureted_price: 1899,
    category: 'fashion',
    ratings: 4.7,
    images: [
      {
        _id: '651d50b7e2b392999df759ee',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/ethnic-set/v/t/j/s-98kbd1075-samah-original-imagwyezh8bbvq4r.jpeg?q=70',
      },
    ],
    seller: 'TrendyEthnics',
    replacement: '15 Days Replacement Policy',
    specificaton: [
      {
        material: 'Rayon',
        color: 'Peach',
        size: 'L',
        brand: 'TrendyEthnics',
        type: 'Golden Thread Work',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'TrendyEthnics',
        Color: 'Peach',
        Type: 'Kurthi Set with Palazzo',
        ModelName: 'EMBROIDERED-KURTHI-PALAZZO',
        ModelID: 'EMBROIDERED-KURTHI-PALAZZO',
        material: 'Rayon',
        embroidery: 'Golden Thread Work',
        size: 'L',
        Collection: "Women's Fashion",
        care: 'Dry clean recommended',
      },
    ],
    stock: 20,
    __v: 0,
    features: [
      'Embroidered kurthi set with palazzo for an ethnic look',
      'Made with high-quality rayon material',
      'Beautiful golden thread work for an elegant touch',
    ],
    numrev: 11,
    rating: 94,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f127',
        rate: 4.8,
        Comment: 'Stunning kurthi set. Love the embroidery.',
        date: '2024-08-12T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae747',
      },
      {
        user: '63d1089e7ee22fa515771f128',
        rate: 4.6,
        Comment: 'Perfect fit and excellent quality.',
        date: '2024-08-15T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae748',
      },
    ],
    sell: 15,
  },
  {
    _id: '64c37c823c03172a9facc082',
    id: 40,
    title: 'Skinny Fit High Waist Denim Jeans',
    price: 899,
    cureted_price: 1299,
    category: 'fashion',
    ratings: 4.5,
    images: [
      {
        _id: '651d50b7e2b392999df759ef',
        url: 'https://rukminim2.flixcart.com/image/612/612/k6dxocw0/jean/z/f/6/34-high-waist-jeans-90-high-waist-denim-original-imafzu8z6hhx2aby.jpeg?q=70',
      },
    ],
    seller: 'FashionistaBoutique',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        material: 'Denim',
        color: 'Black',
        size: 'M',
        brand: 'Fashionista',
        type: 'Skinny Fit',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Fashionista',
        Color: 'Black',
        Type: 'Denim Jeans',
        ModelName: 'SKINNY-FIT-DENIM-JEANS',
        ModelID: 'SKINNY-FIT-DENIM-JEANS',
        material: 'Denim',
        size: 'M',
        fit: 'Skinny Fit',
        Collection: "Women's Fashion",
        care: 'Machine wash inside out, tumble dry low',
      },
    ],
    stock: 18,
    __v: 0,
    features: [
      'Skinny fit high waist denim jeans for a trendy look',
      'Made with comfortable and stretchy denim material',
      'Classic black color for versatile styling',
    ],
    numrev: 10,
    rating: 90,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f129',
        rate: 4.5,
        Comment: 'Love the fit. Great quality denim.',
        date: '2024-09-01T14:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae749',
      },
      {
        user: '63d1089e7ee22fa515771f130',
        rate: 4.4,
        Comment: 'Perfect skinny jeans. Comfortable to wear.',
        date: '2024-09-05T11:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae750',
      },
    ],
    sell: 14,
  },

  //kids
  {
    _id: '64c37c823c03172a9facc083',
    id: 41,
    title: "Adorable Floral Print Girl's Dress",
    price: 499,
    cureted_price: 799,
    category: 'fashion',
    ratings: 4.8,
    images: [
      {
        _id: '651d50b7e2b392999df759f0',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/kids-dress/l/q/g/4-5-years-sd0001259-niren-enterprise-original-imagmgyqb3mgdhju.jpeg?q=70',
      },
    ],
    seller: 'CuteStylesKids',
    replacement: '10 Days Replacement Policy',
    specificaton: [
      {
        material: 'Cotton',
        color: 'Pink',
        size: '4-5 Years',
        brand: 'CuteStyles',
        type: 'Floral Print',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'CuteStyles',
        Color: 'Pink',
        Type: "Girl's Dress",
        ModelName: 'ADORABLE-FLORAL-DRESS',
        ModelID: 'ADORABLE-FLORAL-DRESS',
        material: 'Cotton',
        pattern: 'Floral Print',
        size: '4-5 Years',
        Collection: "Kids' Fashion",
        care: 'Machine wash cold, gentle cycle',
      },
    ],
    stock: 25,
    __v: 0,
    features: [
      "Adorable floral print girl's dress for a cute look",
      'Made with soft and breathable cotton material',
      'Pink color with a playful pattern',
    ],
    numrev: 13,
    rating: 97,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f131',
        rate: 4.9,
        Comment: 'Absolutely adorable! My daughter loves it.',
        date: '2024-09-12T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae751',
      },
      {
        user: '63d1089e7ee22fa515771f132',
        rate: 4.7,
        Comment: 'Great quality and vibrant colors.',
        date: '2024-09-15T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae752',
      },
    ],
    sell: 20,
  },
  {
    _id: '64c37c823c03172a9facc084',
    id: 42,
    title: 'Sparkling Sequin Party Dress for Girls',
    price: 899,
    cureted_price: 1299,
    category: 'fashion',
    ratings: 4.7,
    images: [
      {
        _id: '651d50b7e2b392999df759f1',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/kids-dress/p/l/x/4-5-years-bm009-tiktok-fashion-original-imagw5ufhygf5d55.jpeg?q=70',
      },
    ],
    seller: 'PartyPrincessKids',
    replacement: '15 Days Replacement Policy',
    specificaton: [
      {
        material: 'Polyester',
        color: 'Blue',
        size: '6-7 Years',
        brand: 'PartyPrincess',
        type: 'Sequin Embellishments',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'PartyPrincess',
        Color: 'Blue',
        Type: 'Party Dress for Girls',
        ModelName: 'SPARKLING-SEQUIN-DRESS',
        ModelID: 'SPARKLING-SEQUIN-DRESS',
        material: 'Polyester',
        design: 'Sequin Embellishments',
        size: '6-7 Years',
        Collection: "Kids' Fashion",
        care: 'Dry clean only',
      },
    ],
    stock: 18,
    __v: 0,
    features: [
      'Sparkling sequin party dress for girls',
      'Made with shiny and comfortable polyester material',
      'Beautiful blue color with sequin embellishments',
    ],
    numrev: 10,
    rating: 93,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f133',
        rate: 4.8,
        Comment: 'Perfect party dress! My daughter loves it.',
        date: '2024-10-01T14:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae753',
      },
      {
        user: '63d1089e7ee22fa515771f134',
        rate: 4.6,
        Comment: 'Great quality and elegant design.',
        date: '2024-10-05T11:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae754',
      },
    ],
    sell: 16,
  },
  {
    _id: '64c37c823c03172a9facc085',
    id: 43,
    title: "Sporty Casual Boy's T-shirt and Shorts Set",
    price: 699,
    cureted_price: 999,
    category: 'fashion',
    ratings: 4.5,
    images: [
      {
        _id: '651d50b7e2b392999df759f2',
        url: 'https://rukminim2.flixcart.com/image/612/612/kf8kvbk0/kids-apparel-combo/g/h/6/3-6-months-gobs20-1770-beige-babygo-original-imafvqwsr9g2hzzp.jpeg?q=70',
      },
    ],
    seller: 'CoolKidsWear',
    replacement: '10 Days Replacement Policy',
    specificaton: [
      {
        material: 'Cotton',
        color: 'Red/Blue',
        size: '8-9 Years',
        brand: 'CoolKids',
        type: 'Sporty Casual',
      },
    ],
    productDetails: [
      {
        packOf: '2',
        brand: 'CoolKids',
        Color: 'Red/Blue',
        Type: 'T-shirt and Shorts Set',
        ModelName: 'SPORTY-BOYS-SET',
        ModelID: 'SPORTY-BOYS-SET',
        material: 'Cotton',
        style: 'Sporty Casual',
        size: '8-9 Years',
        Collection: "Kids' Fashion",
        care: 'Machine wash cold, tumble dry low',
      },
    ],
    stock: 20,
    __v: 0,
    features: [
      "Sporty casual boy's t-shirt and shorts set for active play",
      'Made with soft and breathable cotton material',
      'Red and blue color combination for a vibrant look',
    ],
    numrev: 9,
    rating: 88,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f135',
        rate: 4.4,
        Comment: 'Comfortable and stylish. Perfect for my son.',
        date: '2024-10-12T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae755',
      },
      {
        user: '63d1089e7ee22fa515771f136',
        rate: 4.6,
        Comment: 'Great quality and vibrant colors.',
        date: '2024-10-15T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae756',
      },
    ],
    sell: 15,
  },
  {
    _id: '64c37c823c03172a9facc086',
    id: 44,
    title: "Traditional Ethnic Boy's Dhoti Kurta Set",
    price: 899,
    cureted_price: 1299,
    category: 'fashion',
    ratings: 4.6,
    images: [
      {
        _id: '651d50b7e2b392999df759f3',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/kids-ethnic-set/y/f/a/1-2-years-d402-yellow-sahil-collection-original-imaggjpddk9x7jtj.jpeg?q=70',
      },
    ],
    seller: 'EthnicWondersKids',
    replacement: '15 Days Replacement Policy',
    specificaton: [
      {
        material: 'Silk Blend',
        color: 'White/Gold',
        size: '10-11 Years',
        brand: 'EthnicWonders',
        type: 'Traditional Ethnic',
      },
    ],
    productDetails: [
      {
        packOf: '2',
        brand: 'EthnicWonders',
        Color: 'White/Gold',
        Type: 'Dhoti Kurta Set',
        ModelName: 'ETHNIC-BOYS-DHOTI-KURTA',
        ModelID: 'ETHNIC-BOYS-DHOTI-KURTA',
        material: 'Silk Blend',
        style: 'Traditional Ethnic',
        size: '10-11 Years',
        Collection: "Kids' Fashion",
        care: 'Dry clean recommended',
      },
    ],
    stock: 18,
    __v: 0,
    features: [
      "Traditional ethnic boy's dhoti kurta set for special occasions",
      'Made with silk blend material for a luxurious feel',
      'White and gold color combination for an elegant look',
    ],
    numrev: 11,
    rating: 92,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f137',
        rate: 4.8,
        Comment: 'Beautiful outfit. Perfect for ceremonies.',
        date: '2024-11-01T14:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae757',
      },
      {
        user: '63d1089e7ee22fa515771f138',
        rate: 4.5,
        Comment: 'Great quality and excellent stitching.',
        date: '2024-11-05T11:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae758',
      },
    ],
    sell: 14,
  },

  //grocery
  {
    _id: '64c37c823c03172a9face070',
    id: 28,
    title:
      'Bliss of Earth 200gm USDA Organic Quinoa Seeds Super Food Quinoa  (200 g)',
    price: 199,
    cureted_price: 299,
    category: 'grocery',
    ratings: 3.9,
    images: [
      {
        _id: '651d50b7e2b392999df759e3',
        url: 'https://rukminim2.flixcart.com/image/612/612/kzegk280/edible-seed/t/s/l/1-2-usda-certified-combo-of-organic-white-quinoa-1kg-200gm-raw-original-imagbezqbzh5gtyh.jpeg?q=70',
      },
    ],
    seller: 'NatureHarvest',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        type: 'Whole Grain',
        packaging: 'Resealable Bag',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'NatureHarvest',
        Color: 'Brown',
        Type: 'Quinoa',
        ModelName: 'ORGANIC-QUINOA',
        ModelID: 'ORGANIC-QUINOA',
        type: 'Whole Grain',
        packaging: 'Resealable Bag',
        Collection: 'organic groceries',
        care: 'Store in a cool, dry place',
      },
    ],
    stock: 50,
    __v: 0,
    features: [
      'Premium organic quinoa',
      'Rich in nutrients',
      'Resealable packaging for freshness',
    ],
    numrev: 25,
    rating: 97,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f105',
        rate: 5,
        Comment: 'Excellent quality. Love the resealable bag.',
        date: '2024-03-22T12:55:30.892Z',
        _id: '64e9d16786e6fe3e932ae725',
      },
      {
        user: '63d1089e7ee22fa515771f106',
        rate: 4.5,
        Comment: 'Healthy and delicious. Highly recommended.',
        date: '2024-03-25T11:15:12.892Z',
        _id: '64e9d16786e6fe3e932ae726',
      },
    ],
    sell: 35,
  },
  {
    _id: '64c37c823c03172a9fafc071',
    id: 29,
    title: 'Organic Extra Virgin Olive Oil',
    price: 499,
    cureted_price: 999,
    category: 'grocery',
    ratings: 4.7,
    images: [
      {
        _id: '651d50b7e2b392999df759e4',
        url: 'https://rukminim2.flixcart.com/image/612/612/kn7sdjk0/edible-oil/w/z/m/1000-olive-oil-cooking-oil-extra-virgin-for-cooking-edible-1l-original-imagfxzcy8yqutge.jpeg?q=70',
      },
    ],
    seller: 'GreenHarvest',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        type: 'Extra Virgin',
        packaging: 'Glass Bottle',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'GreenHarvest',
        Color: 'Golden',
        Type: 'Olive Oil',
        ModelName: 'ORGANIC-OLIVE-OIL',
        ModelID: 'ORGANIC-OLIVE-OIL',
        type: 'Extra Virgin',
        packaging: 'Glass Bottle',
        Collection: 'organic oils',
        care: 'Store in a cool, dark place',
      },
    ],
    stock: 40,
    __v: 0,
    features: [
      'Certified organic extra virgin olive oil',
      'Rich flavor and aroma',
      'Packaged in a glass bottle for freshness',
    ],
    numrev: 18,
    rating: 94,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f107',
        rate: 5,
        Comment: 'Top-quality olive oil. Impressed with the flavor.',
        date: '2024-04-02T10:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae727',
      },
      {
        user: '63d1089e7ee22fa515771f108',
        rate: 4.5,
        Comment: 'Great for cooking and salads. Highly recommended.',
        date: '2024-04-05T08:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae728',
      },
    ],
    sell: 28,
  },
  {
    _id: '64c37c823c03172z9facc072',
    id: 30,
    title: 'Organic Mixed Berries Blend: Frozen',
    price: 670,
    cureted_price: 899,
    category: 'grocery',
    ratings: 2.8,
    images: [
      {
        _id: '651d50b7e2b392999df759e5',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/nut-dry-fruit/m/z/t/200-premium-healthy-mix-berries-for-breakfast-and-healthy-original-imagkbadc8tanwye.jpeg?q=70',
      },
    ],
    seller: 'FrostyHarvest',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        type: 'Frozen Berries',
        packaging: 'Resealable Bag',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'FrostyHarvest',
        Color: 'Assorted',
        Type: 'Mixed Berries',
        ModelName: 'ORGANIC-MIXED-BERRIES',
        ModelID: 'ORGANIC-MIXED-BERRIES',
        type: 'Frozen Berries',
        packaging: 'Resealable Bag',
        Collection: 'organic frozen fruits',
        care: 'Keep frozen at -18°C or below',
      },
    ],
    stock: 35,
    __v: 0,
    features: [
      'Certified organic mixed berries blend',
      'Ideal for smoothies and desserts',
      'Convenient resealable packaging',
    ],
    numrev: 20,
    rating: 96,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f109',
        rate: 5,
        Comment: 'Delicious and convenient. Perfect for smoothies.',
        date: '2024-04-12T12:55:30.892Z',
        _id: '64e9d16786e6fe3e932ae729',
      },
      {
        user: '63d1089e7ee22fa515771f110',
        rate: 4.5,
        Comment: 'High-quality frozen berries. Love the resealable bag.',
        date: '2024-04-15T11:15:12.892Z',
        _id: '64e9d16786e6fe3e932ae730',
      },
    ],
    sell: 30,
  },
  {
    _id: '64c37c823c03172a9fazc073',
    id: 31,
    title: 'Spicy Jalapeño Popcorn: Gourmet Snack',
    price: 299,
    cureted_price: 350,
    category: 'grocery',
    ratings: 3.6,
    images: [
      {
        _id: '651d50b7e2b392999df759e6',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/popcorn/f/r/b/110-cheese-sea-salt-caramel-popcorn-1pc-each-assorted-tub-original-imaggxfq5gf4gwza.jpeg?q=70',
      },
    ],
    seller: 'CrunchyDelight',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        type: 'Spicy Jalapeño',
        packaging: 'Resealable Pouch',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'CrunchyDelight',
        Color: 'Golden',
        Type: 'Popcorn',
        ModelName: 'SPICY-JALAPENO-POPCORN',
        ModelID: 'SPICY-JALAPENO-POPCORN',
        flavor: 'Spicy Jalapeño',
        packaging: 'Resealable Pouch',
        Collection: 'gourmet popcorn',
        care: 'Store in a cool, dry place',
      },
    ],
    stock: 42,
    __v: 0,
    features: [
      'Gourmet popcorn with a spicy kick',
      'Perfect snack for movie nights',
      'Resealable pouch for freshness',
    ],
    numrev: 15,
    rating: 92,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f111',
        rate: 4.5,
        Comment: 'Delicious and spicy. Great for snack time.',
        date: '2024-05-02T10:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae731',
      },
      {
        user: '63d1089e7ee22fa515771f112',
        rate: 4,
        Comment: 'Addictive flavor. Love the resealable pouch.',
        date: '2024-05-05T08:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae732',
      },
    ],
    sell: 32,
  },
  {
    _id: '64c37c823c03172a9facc074',
    id: 32,
    title: 'Sea Salt & Vinegar Potato Chips: Classic Flavor',
    price: 349,
    cureted_price: 399,
    category: 'grocery',
    ratings: 3.5,
    images: [
      {
        _id: '651d50b7e2b392999df759e7',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/chips/h/r/y/500-salted-potato-chips-aloo-chips-salty-wafers-aalu-chips-original-imaggedspqz4hyrk.jpeg?q=70',
      },
    ],
    seller: 'SnackMasters',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        type: 'Sea Salt & Vinegar',
        packaging: 'Foil Bag',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'SnackMasters',
        Color: 'Golden',
        Type: 'Potato Chips',
        ModelName: 'SALT-VINEGAR-CHIPS',
        ModelID: 'SALT-VINEGAR-CHIPS',
        flavor: 'Sea Salt & Vinegar',
        packaging: 'Foil Bag',
        Collection: 'classic potato chips',
        care: 'Reseal after opening to retain freshness',
      },
    ],
    stock: 38,
    __v: 0,
    features: [
      'Classic potato chips with a tangy twist',
      'Perfect for snacking anytime',
      'Foil bag for enhanced freshness',
    ],
    numrev: 12,
    rating: 88,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f113',
        rate: 4,
        Comment: 'Love the tangy flavor. Crispy and delicious.',
        date: '2024-05-12T12:55:30.892Z',
        _id: '64e9d16786e6fe3e932ae733',
      },
      {
        user: '63d1089e7ee22fa515771f114',
        rate: 4.5,
        Comment: 'Classic and tasty. Great snack option.',
        date: '2024-05-15T11:15:12.892Z',
        _id: '64e9d16786e6fe3e932ae734',
      },
    ],
    sell: 25,
  },
  {
    _id: '64c37c823c03172r9facc075',
    id: 33,
    title: 'Tropical Paradise Blend: Exotic Fruit Juice',
    price: 529,
    cureted_price: 540,
    category: 'grocery',
    ratings: 3.8,
    images: [
      {
        _id: '651d50b7e2b392999df759e8',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/energy-sport-drink-mix/g/k/l/1800-go-mixed-fruit-mania-active-hydration-drink-with-0-calories-original-imagkwfbrqrcsgnm.jpeg?q=70',
      },
    ],
    seller: 'JuicyDelights',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        type: 'Tropical Paradise',
        packaging: 'Bottle',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'JuicyDelights',
        Color: 'Assorted',
        Type: 'Fruit Juice',
        ModelName: 'TROPICAL-PARADISE-BLEND',
        ModelID: 'TROPICAL-PARADISE-BLEND',
        blend: 'Tropical Paradise',
        packaging: 'Bottle',
        Collection: 'exotic fruit juices',
        care: 'Shake well before serving',
      },
    ],
    stock: 45,
    __v: 0,
    features: [
      'Exotic fruit juice blend for a tropical experience',
      'Refreshing and hydrating',
      'Convenient bottle packaging',
    ],
    numrev: 18,
    rating: 94,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f115',
        rate: 5,
        Comment: 'Love the tropical flavor. So refreshing!',
        date: '2024-06-02T10:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae735',
      },
      {
        user: '63d1089e7ee22fa515771f116',
        rate: 4.5,
        Comment: 'Delicious blend. Perfect for summer.',
        date: '2024-06-05T08:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae736',
      },
    ],
    sell: 38,
  },
  {
    _id: '64c37c823c03172s9facc076',
    id: 34,
    title: 'Extra Virgin Olive Oil: Cold Pressed',
    price: 1299,
    cureted_price: 1699,
    category: 'grocery',
    ratings: 4.7,
    images: [
      {
        _id: '651d50b7e2b392999df759e9',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/edible-oil/n/1/s/5-wood-pressed-coconut-oil-5-liter-cold-pressed-from-ghani-original-imagzpfyhhz7n4gq.jpeg?q=70',
      },
    ],
    seller: 'HealthyHarvest',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        type: 'Extra Virgin',
        processing: 'Cold Pressed',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'HealthyHarvest',
        Color: 'Golden',
        Type: 'Olive Oil',
        ModelName: 'EXTRA-VIRGIN-OLIVE-OIL',
        ModelID: 'EXTRA-VIRGIN-OLIVE-OIL',
        type: 'Extra Virgin',
        processing: 'Cold Pressed',
        Collection: 'premium cooking oils',
        care: 'Store in a cool, dark place',
      },
    ],
    stock: 32,
    __v: 0,
    features: [
      'High-quality extra virgin olive oil',
      'Cold-pressed for optimal flavor',
      'Ideal for cooking and salads',
    ],
    numrev: 22,
    rating: 93,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f117',
        rate: 5,
        Comment: 'Excellent quality. Rich and flavorful.',
        date: '2024-06-12T12:55:30.892Z',
        _id: '64e9d16786e6fe3e932ae737',
      },
      {
        user: '63d1089e7ee22fa515771f118',
        rate: 4.5,
        Comment: 'Great for cooking. Love the cold-pressed process.',
        date: '2024-06-15T11:15:12.892Z',
        _id: '64e9d16786e6fe3e932ae738',
      },
    ],
    sell: 26,
  },
  {
    _id: '64c37e823c03172a9facc077',
    id: 35,
    title: 'Organic Split Red Lentils: Premium Dhal',
    price: 499,
    cureted_price: 699,
    category: 'grocery',
    ratings: 4.6,
    images: [
      {
        _id: '651d50b7e2b392999df759ea',
        url: 'https://rukminim2.flixcart.com/image/612/612/kmjhw280/pulses/n/f/f/450-certified-organic-masoor-dal-red-lentil-non-gmo-healthy-original-imagff4byzbtgn59.jpeg?q=70',
      },
    ],
    seller: 'PurePulses',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        type: 'Split Red Lentils',
        processing: 'Organic',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'PurePulses',
        Color: 'Red',
        Type: 'Dhal',
        ModelName: 'ORGANIC-RED-LENTILS',
        ModelID: 'ORGANIC-RED-LENTILS',
        type: 'Split Red Lentils',
        processing: 'Organic',
        Collection: 'premium dhal',
        care: 'Store in a cool, dry place',
      },
    ],
    stock: 40,
    __v: 0,
    features: [
      'Premium quality organic split red lentils',
      'Ideal for traditional dhal recipes',
      'Rich in protein and fiber',
    ],
    numrev: 18,
    rating: 91,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f119',
        rate: 4.5,
        Comment: 'Excellent dhal. Cooks quickly and tastes great.',
        date: '2024-07-02T10:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae739',
      },
      {
        user: '63d1089e7ee22fa515771f120',
        rate: 4,
        Comment: 'Highly recommended. Good texture and flavor.',
        date: '2024-07-05T08:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae740',
      },
    ],
    sell: 32,
  },

  //diary
  {
    _id: '64c37p823c03172a9facc078',
    id: 36,
    title: 'PURAMIO Whole , Milk Substitutes Powder  (300 g)',
    price: 349,
    cureted_price: 459,
    category: 'grocery',
    ratings: 3.9,
    images: [
      {
        _id: '651d50b7e2b392999df759eb',
        url: 'https://rukminim2.flixcart.com/image/612/612/kqpj4i80/milk-powder/z/f/h/300-whole-milk-powder-puramio-original-imag4ny828szxtru.jpeg?q=70',
      },
    ],
    seller: 'PureDairyFarms',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        type: 'Whole Milk',
        processing: 'Organic',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'PureDairyFarms',
        Color: 'White',
        Type: 'Milk',
        ModelName: 'ORGANIC-WHOLE-MILK',
        ModelID: 'ORGANIC-WHOLE-MILK',
        type: 'Whole Milk',
        processing: 'Organic',
        Collection: 'fresh dairy products',
        care: 'Keep refrigerated for freshness',
      },
    ],
    stock: 28,
    __v: 0,
    features: [
      'Organic whole milk from pasture-raised cows',
      'Rich in vitamins and minerals',
      'No artificial additives or preservatives',
    ],
    numrev: 25,
    rating: 97,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f121',
        rate: 5,
        Comment: 'Absolutely delicious. Creamy and nutritious.',
        date: '2024-07-12T12:55:30.892Z',
        _id: '64e9d16786e6fe3e932ae741',
      },
      {
        user: '63d1089e7ee22fa515771f122',
        rate: 4.5,
        Comment: 'High-quality organic milk. My family loves it.',
        date: '2024-07-15T11:15:12.892Z',
        _id: '64e9d16786e6fe3e932ae742',
      },
    ],
    sell: 20,
  },

  //fruits& veg
  {
    _id: '64c37c823t03172a9facc079',
    id: 37,
    title: 'Organic Mangoes: Juicy and Sweet',
    price: 299,
    cureted_price: 399,
    category: 'grocery',
    ratings: 3.8,
    images: [
      {
        _id: '651d50b7e2b392999df759ec',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/fruit/q/z/w/-original-imagfvvj7zfadgbg.jpeg?q=70',
      },
    ],
    seller: 'FreshHarvest',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        type: 'Alphonso Mangoes',
        origin: 'Organic Farm',
      },
    ],
    productDetails: [
      {
        packOf: '4',
        brand: 'FreshHarvest',
        Color: 'Yellow',
        Type: 'Mangoes',
        ModelName: 'ORGANIC-MANGOES',
        ModelID: 'ORGANIC-MANGOES',
        type: 'Alphonso Mangoes',
        origin: 'Organic Farm',
        Collection: 'organic fruits',
        care: 'Store in a cool, dry place until ripe',
      },
    ],
    stock: 36,
    __v: 0,
    features: [
      'Certified organic Alphonso mangoes',
      'Naturally sweet and juicy',
      'Harvested from organic farms',
    ],
    numrev: 20,
    rating: 96,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f123',
        rate: 5,
        Comment: "Best mangoes I've ever had. Perfectly ripe.",
        date: '2024-08-02T10:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae743',
      },
      {
        user: '63d1089e7ee22fa515771f124',
        rate: 4.5,
        Comment: 'Delicious and organic. Will buy again.',
        date: '2024-08-05T08:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae744',
      },
    ],
    sell: 30,
  },
  {
    _id: '64c37c823c03172a9facq080',
    id: 38,
    title: 'Sweet Pineapples: Fresh Tropical Delight',
    price: 250,
    cureted_price: 499,
    category: 'grocery',
    ratings: 4.7,
    images: [
      {
        _id: '651d50b7e2b392999df759ed',
        url: 'https://rukminim2.flixcart.com/image/612/612/kst9gnk0/fruit/c/w/3/500-1-unbranded-whole-original-imag6ajfrgqjg6gr.jpeg?q=70',
      },
    ],
    seller: 'TropicalHarvest',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        type: 'Queen Victoria Pineapples',
        origin: 'Tropical Plantation',
      },
    ],
    productDetails: [
      {
        packOf: '2',
        brand: 'TropicalHarvest',
        Color: 'Golden',
        Type: 'Pineapples',
        ModelName: 'SWEET-PINEAPPLES',
        ModelID: 'SWEET-PINEAPPLES',
        type: 'Queen Victoria Pineapples',
        origin: 'Tropical Plantation',
        Collection: 'fresh tropical fruits',
        care: 'Keep refrigerated for freshness',
      },
    ],
    stock: 28,
    __v: 0,
    features: [
      'Fresh and sweet Queen Victoria pineapples',
      'Tropical delight with a burst of flavor',
      'Perfect for snacking or fruit salads',
    ],
    numrev: 18,
    rating: 92,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f125',
        rate: 4.5,
        Comment: 'Delicious pineapples. Sweet and refreshing.',
        date: '2024-08-12T12:55:30.892Z',
        _id: '64e9d16786e6fe3e932ae745',
      },
      {
        user: '63d1089e7ee22fa515771f126',
        rate: 4,
        Comment: 'Great quality. Love the tropical flavor.',
        date: '2024-08-15T11:15:12.892Z',
        _id: '64e9d16786e6fe3e932ae746',
      },
    ],
    sell: 22,
  },
  {
    _id: '64c37c823g03172a9facc081',
    id: 39,
    title: 'Crimson Red Apples: Crisp and Sweet',
    price: 325,
    cureted_price: 345,
    category: 'grocery',
    ratings: 2.6,
    images: [
      {
        _id: '651d50b7e2b392999df759ee',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/vinegar/m/9/s/500-apple-cider-vinegar-acv-probiotic-plus-1-tata-1mg-original-imagvjjhrygxqngc.jpeg?q=70',
      },
    ],
    seller: 'FreshHarvest',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        type: 'Crimson Red Apples',
        origin: 'Orchard Farms',
      },
    ],
    productDetails: [
      {
        packOf: '6',
        brand: 'FreshHarvest',
        Color: 'Red',
        Type: 'Apples',
        ModelName: 'CRIMSON-RED-APPLES',
        ModelID: 'CRIMSON-RED-APPLES',
        type: 'Crimson Red Apples',
        origin: 'Orchard Farms',
        Collection: 'fresh orchard fruits',
        care: 'Store in a cool, dark place',
      },
    ],
    stock: 34,
    __v: 0,
    features: [
      'Crisp and sweet Crimson Red Apples',
      'Harvested from premium orchard farms',
      'Perfect for snacking or desserts',
    ],
    numrev: 15,
    rating: 90,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f127',
        rate: 4.5,
        Comment: 'Excellent apples. Fresh and tasty.',
        date: '2024-09-02T10:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae747',
      },
      {
        user: '63d1089e7ee22fa515771f128',
        rate: 4,
        Comment: 'Quality apples. Love the crunch.',
        date: '2024-09-05T08:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae748',
      },
    ],
    sell: 28,
  },

  //home and furniture
  {
    _id: '64c37c823c03172a9facc054',
    id: 12,
    title: 'Home Garage 210 TC Cotton King Floral Fitted (Elastic) Bedsheet  ',
    price: 299,
    cureted_price: 999,
    category: 'homeandfurniture',
    ratings: 4.2,
    images: [
      {
        _id: '651d50b7e2b392999df759d3',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/bedsheet/h/u/g/elastic-fitted-bedsheet-double-bed-1-multi-line-fitted-elastic-original-imagusyjnqycztrc.jpeg?q=70',
      },
    ],

    seller: 'HOMEFR',
    replacement: '7 Days of Return Policy',
    specificaton: [
      {
        type: 'Regular',
        size: 'M',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Home Garage',
        Color: 'Fitted Green',
        Type: 'Fitted (Elastic)',
        Size: 'King',
        Material: 'Cotton',
        ModelName: 'FIT-GREEN-WHITE-DOT',
        ModelID: 'FIT-GREEN-WHITE-DOT',
        fits: 'Regular',
        netQuantity: 1,
        Collection: 'elastic fitted bedsheet, bedsheet Double size',
        care: 'NORMAL WASH',
      },
    ],

    stock: 1,
    __v: 1,
    features: 'elastic fitted bedsheet, bedsheet Double size',
    numrev: 2,
    rating: 95,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f74',
        rate: 2,
        Comment: 'Very Good',
        date: '2023-08-26T10:17:28.892Z',
        _id: '64e9d16786e6fe3e932ae694',
      },
      {
        user: '63d1089e7ee22fa515771f74',
        rate: 2,
        Comment: 'Good quality product',
        date: '2023-08-26T10:17:28.892Z',
        _id: '64e9d16786e6fe3e932ae694',
      },
    ],
    sell: 0,
  },
  {
    _id: '64c37c823c03172a9facc055',
    id: 13,
    title: 'Cozy Living Room 300 TC Velvet Sofa Cover (Pack of 1, Aqua Blue)',
    price: 599,
    cureted_price: 1299,
    category: 'homeandfurniture',
    ratings: 4.5,
    images: [
      {
        _id: '651d50b7e2b392999df759d4',
        url: 'https://rukminim2.flixcart.com/image/612/612/klb78nk0/slipcover/t/m/a/chp-grey-3-seater-nendle-original-imagygfhehktg3gz.jpeg?q=70',
      },
    ],
    seller: 'Cozy Living',
    replacement: '15 Days Replacement Policy',
    specificaton: [
      {
        type: 'Regular',
        size: '3 Seater',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Cozy Living',
        Color: 'Aqua Blue',
        Type: 'Velvet',
        Size: '3 Seater',
        Material: 'TC Velvet',
        ModelName: 'VELVET-SOFA-BLUE',
        ModelID: 'VELVET-SOFA-BLUE',
        fits: 'Regular',
        netQuantity: 1,
        Collection: 'velvet sofa cover, sofa cover 3 seater',
        care: 'Machine Wash',
      },
    ],
    stock: 5,
    __v: 0,
    features: 'velvet sofa cover, sofa cover 3 seater',
    numrev: 5,
    rating: 90,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f75',
        rate: 4,
        Comment: 'High-quality fabric. Looks great on my sofa!',
        date: '2023-09-15T14:45:00.892Z',
        _id: '64e9d16786e6fe3e932ae695',
      },
      {
        user: '63d1089e7ee22fa515771f76',
        rate: 5,
        Comment: 'Excellent fit and color. Highly recommended.',
        date: '2023-09-18T09:30:22.892Z',
        _id: '64e9d16786e6fe3e932ae696',
      },
    ],
    sell: 10,
  },
  {
    _id: '64c37c823c03172a9facc056',
    id: 14,
    title: 'Modern Kitchen Essentials 12-Piece Non-Stick Cookware Set',
    price: 799,
    cureted_price: 1499,
    category: 'homeandfurniture',
    ratings: 4.8,
    images: [
      {
        _id: '651d50b7e2b392999df759d5',
        url: 'https://rukminim2.flixcart.com/image/612/612/k8t3jbk0/cookware-set/e/v/8/ft13-dkd-b-ap12-nirlon-original-imafqry66zkkeghm.jpeg?q=70',
      },
    ],
    seller: 'KitchenPro',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        material: 'Aluminum',
        type: 'Non-Stick Aluminium',
        pieces: 12,
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'KitchenPro',
        Color: 'Black',
        Type: 'Cookware Set',
        Material: 'Aluminum',
        ModelName: 'MODERN-COOKWARE-SET',
        ModelID: 'MODERN-COOKWARE-SET',
        pieces: 12,
        Collection: 'non-stick cookware set',
        care: 'Hand Wash Only',
      },
    ],
    stock: 8,
    __v: 0,
    features: 'MODERN-NON-STICK COOKWARE-SET,',
    numrev: 8,
    rating: 96,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f77',
        rate: 5,
        Comment: 'High-quality cookware. Love the non-stick coating!',
        date: '2023-10-05T18:20:30.892Z',
        _id: '64e9d16786e6fe3e932ae697',
      },
      {
        user: '63d1089e7ee22fa515771f78',
        rate: 4.5,
        Comment: 'Good value for money. Easy to clean.',
        date: '2023-10-08T11:15:12.892Z',
        _id: '64e9d16786e6fe3e932ae698',
      },
    ],
    sell: 15,
  },
  {
    _id: '64c37c823c03172a9facc057',
    id: 15,
    title: 'Weaving Poems Hand Towels Set 14" x 21" size for wash Basin Soft',
    price: 294,
    cureted_price: 499,
    category: 'homeandfurniture',
    ratings: 3.6,
    images: [
      {
        _id: '651d50b7e2b392999df759d6',
        url: 'https://rukminim2.flixcart.com/image/612/612/l1zc6fk0/napkin/c/b/i/hand-towels-set-14-x-21-size-for-wash-basin-soft-super-absorbent-original-imagdfnkpzjjcyev.jpeg?q=70',
      },
    ],
    seller: 'FitnessPro',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        weightRange: '5-25 lbs per dumbbell',
        type: 'Cast Iron',
      },
    ],
    productDetails: [
      {
        packOf: '2',
        brand: 'Hand Towels',
        Color: 'multi colours',
        Type: 'Adjustable Dumbbell Set',
        Material: 'Cast Iron',
        ModelName:
          'Hand Towels Set 14" x 21" size for wash Basin Soft & Super Absorbent, (6 Sheets)',
        ModelID: 'Hand Towels Set',
        weightRange: '5-25 lbs',
        Collection: 'adjustable dumbbell set',
        care: 'hand wash',
      },
    ],
    stock: 10,
    __v: 0,
    features: ['Light weight ', 'Durable for lomg lasting'],
    numrev: 12,
    rating: 92,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f79',
        rate: 4,
        Comment: 'Great forkitchens',
        date: '2023-11-02T09:40:18.892Z',
        _id: '64e9d16786e6fe3e932ae699',
      },
      {
        user: '63d1089e7ee22fa515771f80',
        rate: 5,
        Comment: 'Highly recommend!',
        date: '2023-11-05T15:25:42.892Z',
        _id: '64e9d16786e6fe3e932ae700',
      },
    ],
    sell: 20,
  },
  {
    _id: '64c37c823c03172a9facc058',
    id: 16,
    title: 'SmartHome Security Camera System (4 Cameras)',
    price: 59999,
    cureted_price: 89999,
    category: 'homeandfurniture',
    ratings: 2.7,
    images: [
      {
        _id: '651d50b7e2b392999df759d7',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/home-security-camera/d/h/l/1-oxs-double-antenna-0013-indoor-security-camera-onexsale-original-imagzqyuhz8tysxm.jpeg?q=70',
      },
    ],
    seller: 'SmartTech',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        resolution: '1080p HD',
        nightVision: 'Yes',
        numberofCameras: 4,
        type: 'SmartTech',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'SmartTech',
        Color: 'White',
        Type: 'Security Camera System',
        ModelName: 'SMART-CAMERA-SYSTEM',
        ModelID: 'SMART-CAMERA-SYSTEM',
        resolution: '1080p HD',
        nightVision: 'Yes',
        numberofCameras: 4,
        Collection: 'home security camera system',
        care: 'Weatherproof design',
      },
    ],
    stock: 15,
    __v: 0,
    features: [
      '1080p HD resolution',
      'Night vision capability',
      'Weatherproof design',
      'Remote viewing via mobile app',
    ],
    numrev: 9,
    rating: 94,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f81',
        rate: 5,
        Comment: 'Excellent video quality. Easy to set up.',
        date: '2023-11-15T12:55:30.892Z',
        _id: '64e9d16786e6fe3e932ae701',
      },
      {
        user: '63d1089e7ee22fa515771f82',
        rate: 4.5,
        Comment: 'Great value for the price. Highly recommended!',
        date: '2023-11-18T09:30:22.892Z',
        _id: '64e9d16786e6fe3e932ae702',
      },
    ],
    sell: 25,
  },
  {
    _id: '64c37c823c03172a9facc059',
    id: 17,
    title: 'Outdoor Adventure 2-Person Camping Tent',
    price: 8999,
    cureted_price: 12999,
    category: 'homeandfurniture',
    ratings: 4.4,
    images: [
      {
        _id: '651d50b7e2b392999df759d8',
        url: 'https://rukminim2.flixcart.com/image/612/612/l52sivk0/tent/a/l/f/picnic-camping-outdoor-hiking-portable-waterproof-tent-for-4-original-imagftupxnk4y5h3.jpeg?q=70',
      },
    ],
    seller: 'AdventureGear',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        capacity: '2 Persons',
        season: '3-Season',
        weight: '4.5 lbs',
        type: 'Outdoor Adventure',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'AdventureGear',
        Color: 'Green',
        Type: 'Camping Tent',
        ModelName: 'ADVENTURE-TENT-2P',
        ModelID: 'ADVENTURE-TENT-2P',
        capacity: '2 Persons',
        season: '3-Season',
        weight: '4.5 lbs',
        Collection: 'outdoor adventure tent',
        care: 'Wipe clean with a damp cloth',
      },
    ],
    stock: 20,
    __v: 0,
    features: [
      'Spacious 2-person tent',
      '3-season usability',
      'Lightweight and portable',
      'Easy setup and takedown',
    ],
    numrev: 7,
    rating: 88,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f83',
        rate: 4,
        Comment: 'Perfect for camping trips. Easy to carry.',
        date: '2023-12-01T15:10:45.892Z',
        _id: '64e9d16786e6fe3e932ae703',
      },
      {
        user: '63d1089e7ee22fa515771f84',
        rate: 4.5,
        Comment: 'Durable and spacious. Great value.',
        date: '2023-12-05T09:40:18.892Z',
        _id: '64e9d16786e6fe3e932ae704',
      },
    ],
    sell: 18,
  },
  {
    _id: '64c37c823c03172a9facc060',
    id: 18,
    title: 'Retro Style Record Player with Bluetooth',
    price: 14999,
    cureted_price: 19999,
    category: 'homeandfurniture',
    ratings: 4.9,
    images: [
      {
        _id: '651d50b7e2b392999df759d9',
        url: 'https://rukminim2.flixcart.com/image/612/612/l5iid8w0/fm-radio/m/k/h/pocket-blast-bluetooth-speaker-with-fm-fm-and-voice-recording-original-imagg62hpdxcjyur.jpeg?q=70',
      },
    ],
    seller: 'AudioVintage',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        type: 'Vinyl, Bluetooth',
        speeds: '33 1/3, 45, 78 RPM',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'AudioVintage',
        Color: 'Walnut',
        Type: 'Record Player',
        ModelName: 'RETRO-PLAYER-WALNUT',
        ModelID: 'RETRO-PLAYER-WALNUT',
        playback: 'Vinyl, Bluetooth',
        speeds: '33 1/3, 45, 78 RPM',
        Collection: 'retro-style audio',
        care: 'Dust regularly and keep away from moisture',
      },
    ],
    stock: 12,
    __v: 0,
    features: [
      'Vintage design with modern functionality',
      'Bluetooth connectivity for wireless streaming',
      'Supports various vinyl speeds',
    ],
    numrev: 15,
    rating: 97,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f85',
        rate: 5,
        Comment: 'Absolutely love it! Great sound quality.',
        date: '2023-12-12T12:55:30.892Z',
        _id: '64e9d16786e6fe3e932ae705',
      },
      {
        user: '63d1089e7ee22fa515771f86',
        rate: 4.5,
        Comment: 'Perfect blend of vintage and modern tech.',
        date: '2023-12-15T11:15:12.892Z',
        _id: '64e9d16786e6fe3e932ae706',
      },
    ],
    sell: 30,
  },
  {
    _id: '64c37c823c03172a9facc061',
    id: 19,
    title: 'Gourmet Coffee Lovers Gift Set',
    price: 399,
    cureted_price: 799,
    category: 'homeandfurniture',
    ratings: 3.6,
    images: [
      {
        _id: '651d50b7e2b392999df759da',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/valentine-gift-set/n/y/t/valentine-s-day-mug-key-572-ted-heart-spl-earnam-original-imagwkaqnyzu7yqc.jpeg?q=70',
      },
    ],
    seller: 'BeanBliss',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        coffeeTypes: ['Espresso', 'Medium Roast', 'Decaf'],
        type: 'Gift Set',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'BeanBliss',
        Color: 'Assorted',
        Type: 'Coffee Gift Set',
        ModelName: 'GOURMET-COFFEE-GIFT',
        ModelID: 'GOURMET-COFFEE-GIFT',
        coffeeTypes: ['Espresso', 'Medium Roast', 'Decaf'],
        Collection: 'gourmet coffee collection',
        care: 'Store in a cool, dry place',
      },
    ],
    stock: 25,
    __v: 0,
    features: [
      'Premium selection of gourmet coffee',
      'Perfect gift for coffee lovers',
      'Variety of coffee types included',
    ],
    numrev: 10,
    rating: 91,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f87',
        rate: 5,
        Comment: 'Absolutely delicious! Great variety.',
        date: '2023-12-22T09:40:18.892Z',
        _id: '64e9d16786e6fe3e932ae707',
      },
      {
        user: '63d1089e7ee22fa515771f88',
        rate: 4.5,
        Comment: 'Lovely packaging. Makes a perfect gift.',
        date: '2023-12-25T15:25:42.892Z',
        _id: '64e9d16786e6fe3e932ae708',
      },
    ],
    sell: 20,
  },

  //mobile
  {
    _id: '64c37c823c03172a9facc087',
    id: 45,
    title: 'Samsung Galaxy Z Fold 3 5G',
    price: 149999,
    cureted_price: 159999,
    ratings: 3.5,
    decr: 'Experience the future of smartphones with the Samsung Galaxy Z Fold 3. Unfold the 7.6-inch AMOLED display for a tablet-like experience, or fold it for a compact phone. Packed with powerful features and the latest technology, this device is designed to impress.',
    category: 'mobile',
    images: [
      {
        _id: '651d50b7e2b392999df759f4',
        url: 'https://rukminim2.flixcart.com/image/312/312/xif0q/mobile/p/v/o/-original-imagh7nzmxwmbpvf.jpeg?q=70',
      },
    ],
    highlights: '12 GB RAM | 256 GB ROM | 7.6 inch AMOLED Display',
    seller: 'TechWonders',
    replacement: '10 Days Replacement Policy',
    invoice: 'GST invoice available',
    specificaton: [
      {
        inTheBox: 'Handset, Charger, USB Cable, SIM Ejector Pin, User Manual',
        modelNumber: 'SM-F926BZKD',
        modelName: 'Galaxy Z Fold 3 5G',
        type: 'Phantom Black',
        batteryCapacity: '4400mAh',
      },
    ],
    brand: 'Samsung',
    stock: 15,
    __v: 0,
    features: [
      'Foldable design for versatile use',
      'Powerful performance with 12 GB RAM',
      'Impressive 7.6-inch AMOLED display',
      'High-quality camera setup for stunning photos',
    ],
    numrev: 15,
    rating: 96,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f139',
        rate: 4.9,
        Comment: 'Incredible device! Love the foldable display.',
        date: '2024-11-12T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae759',
      },
      {
        user: '63d1089e7ee22fa515771f140',
        rate: 4.8,
        Comment: 'Amazing phone with top-notch features.',
        date: '2024-11-15T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae760',
      },
    ],
    sell: 12,
  },
  {
    _id: '64c37c823c03172a9facc088',
    id: 46,
    title: 'Poco X4 Pro 5G',
    price: 24999,
    cureted_price: 27999,
    ratings: 4.5,
    decr: "Unleash the power of 5G with the Poco X4 Pro. This smartphone features a high-refresh-rate display, a versatile camera setup, and a powerful processor to provide a smooth and immersive user experience. Whether you're gaming, streaming, or capturing moments, the Poco X4 Pro is up for the task.",
    category: 'mobile',
    images: [
      {
        _id: '651d50b7e2b392999df759f5',
        url: 'https://rukminim2.flixcart.com/image/312/312/l19m93k0/mobile/w/0/6/-original-imagcv2dcyjzcmvd.jpeg?q=70',
      },
    ],
    highlights: '6 GB RAM | 128 GB ROM | 6.67 inch Full HD+ Display',
    seller: 'GadgetZone',
    replacement: '7 Days Replacement Policy',
    invoice: 'GST invoice available',
    specificaton: [
      {
        inTheBox: 'Handset, Charger, USB Cable, SIM Ejector Pin, User Manual',
        modelNumber: 'M2013J19PI',
        modelName: 'X4 Pro 5G',
        type: 'Fantasy Black',
        batteryCapacity: '5160mAh',
      },
    ],
    brand: 'Poco',
    stock: 20,
    __v: 0,
    features: [
      '5G connectivity for faster data speeds',
      'High-refresh-rate display for smooth visuals',
      'Versatile camera system for creative photography',
      'Powerful battery for all-day usage',
    ],
    numrev: 8,
    rating: 92,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f141',
        rate: 4.7,
        Comment: 'Great value for money. Excellent performance.',
        date: '2024-12-01T14:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae761',
      },
      {
        user: '63d1089e7ee22fa515771f142',
        rate: 4.6,
        Comment: 'Smooth 5G experience. Impressive camera.',
        date: '2024-12-05T11:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae762',
      },
    ],
    sell: 18,
  },
  {
    _id: '64c37c823c03172a9facc089',
    id: 47,
    title: 'OnePlus 9 Pro',
    price: 59999,
    cureted_price: 64999,
    ratings: 3.9,
    decr: 'Experience flagship performance with the OnePlus 9 Pro. This smartphone boasts a stunning display with a high refresh rate, a powerful camera system co-engineered with Hasselblad, and ultra-fast charging capabilities. Elevate your smartphone experience with the OnePlus 9 Pro.',
    category: 'mobile',
    images: [
      {
        _id: '651d50b7e2b392999df759f6',
        url: 'https://rukminim2.flixcart.com/image/312/312/xif0q/mobile/e/z/l/-original-imagdm2pzrg5ej6y.jpeg?q=70',
      },
    ],
    highlights: '8 GB RAM | 128 GB ROM | 6.7 inch Fluid AMOLED Display',
    seller: 'TechGurus',
    replacement: '10 Days Replacement Policy',
    invoice: 'GST invoice available',
    specificaton: [
      {
        inTheBox:
          'Handset, Warp Charge 65 Power Adapter, Warp Charge Type-C to Type-C Cable, Quick Start Guide, Welcome Letter, Safety Information, Warranty Card, SIM Tray Ejector',
        modelNumber: 'LE2123',
        modelName: '9 Pro',
        type: 'Morning Mist',
        batteryCapacity: '4500mAh',
      },
    ],
    brand: 'OnePlus',
    stock: 15,
    __v: 0,
    features: [
      'Flagship performance with Snapdragon processor',
      'Stunning Fluid AMOLED display with high refresh rate',
      'Co-engineered camera system with Hasselblad',
      'Ultra-fast Warp Charge 65 for quick charging',
    ],
    numrev: 12,
    rating: 94,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f143',
        rate: 4.9,
        Comment: 'Absolutely love it! Best smartphone experience.',
        date: '2024-12-12T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae763',
      },
      {
        user: '63d1089e7ee22fa515771f144',
        rate: 4.8,
        Comment: 'Impressive camera and smooth performance.',
        date: '2024-12-15T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae764',
      },
    ],
    sell: 10,
  },
  {
    _id: '64c37c823c03172a9facc091',
    id: 49,
    title: 'Nothing Phone (2)',
    price: 49999,
    cureted_price: 54999,
    ratings: 4.5,
    decr: 'Introducing the Nothing Phone X – a revolutionary smartphone that redefines simplicity. Embrace a minimalist design, powerful performance, and a seamless user experience. Nothing Phone X, where less is more.',
    category: 'mobile',
    images: [
      {
        _id: '651d50b7e2b392999df759f8',
        url: 'https://rukminim2.flixcart.com/image/312/312/xif0q/mobile/u/m/b/-original-imagrdefbw6bhbjr.jpeg?q=70',
      },
    ],
    highlights: 'Minimalist Design | Powerful Performance',
    seller: 'NothingStore',
    replacement: '10 Days Replacement Policy',
    invoice: 'GST invoice available',
    specificaton: [
      {
        inTheBox: 'Handset, Charger, USB Cable, SIM Ejector Pin, User Manual',
        modelNumber: 'NPX2025',
        modelName: 'Phone X',
        type: 'Lunar White',
        batteryCapacity: '4500mAh',
      },
    ],
    brand: 'Nothing',
    stock: 15,
    __v: 0,
    features: [
      'Sleek and minimalist design',
      'High-performance hardware for smooth operation',
      'Immersive display for an enhanced viewing experience',
      'Long-lasting battery for all-day usage',
    ],
    numrev: 6,
    rating: 86,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f147',
        rate: 4.7,
        Comment: 'Elegance meets performance. Loving my Nothing Phone X.',
        date: '2025-02-01T14:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae767',
      },
      {
        user: '63d1089e7ee22fa515771f148',
        rate: 4.5,
        Comment: 'Simple yet powerful. Great user experience.',
        date: '2025-02-05T11:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae768',
      },
    ],
    sell: 8,
  },
  {
    _id: '64c37c823c03172a9facc092',
    id: 50,
    title: 'iPhone 13 Pro Max',
    price: 129999,
    cureted_price: 139999,
    ratings: 3.5,
    decr: 'Unleash the power of innovation with the iPhone 13 Pro Max. Featuring a ProMotion XDR display, an advanced camera system, and the A15 Bionic chip, this smartphone delivers an unparalleled user experience. Elevate your creativity and capture moments like never before with the iPhone 13 Pro Max.',
    category: 'mobile',
    images: [
      {
        _id: '651d50b7e2b392999df759f9',
        url: 'https://rukminim2.flixcart.com/image/312/312/ktketu80/mobile/r/m/8/iphone-13-pro-max-mllj3hn-a-apple-original-imag6vpgphrzuffg.jpeg?q=70',
      },
    ],
    highlights: '8 GB RAM | 256 GB ROM | 6.7 inch Super Retina XDR Display',
    seller: 'AppleStore',
    replacement: '15 Days Replacement Policy',
    invoice: 'GST invoice available',
    specificaton: [
      {
        inTheBox: 'iPhone, USB-C to Lightning Cable, Documentation',
        modelNumber: 'MLLH3HN/A',
        modelName: 'iPhone 13 Pro Max',
        type: 'Graphite',
        batteryCapacity: '4352mAh',
      },
    ],
    brand: 'Apple',
    stock: 30,
    __v: 0,
    features: [
      'ProMotion XDR display for smooth visuals',
      'Triple-camera system with ProRAW and ProRes video recording',
      'A15 Bionic chip for powerful performance',
      'Ceramic Shield front cover for durability',
    ],
    numrev: 20,
    rating: 98,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f149',
        rate: 4.9,
        Comment: 'Absolutely stunning! Best iPhone yet.',
        date: '2025-03-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae769',
      },
      {
        user: '63d1089e7ee22fa515771f150',
        rate: 4.8,
        Comment: 'Amazing camera capabilities. Super fast and responsive.',
        date: '2025-03-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae770',
      },
    ],
    sell: 25,
  },
  {
    _id: '64c37c823c03172a9facc093',
    id: 51,
    title: 'Redmi Note 11 Pro',
    price: 17999,
    cureted_price: 19999,
    ratings: 3.5,
    decr: 'Discover excellence with the Redmi Note 11 Pro. Packed with a powerful camera system, a high-refresh-rate display, and a large battery, this smartphone is designed to enhance your mobile experience. Capture stunning photos, enjoy smooth gaming, and stay powered throughout the day with the Redmi Note 11 Pro.',
    category: 'mobile',
    images: [
      {
        _id: '651d50b7e2b392999df759fa',
        url: 'https://rukminim2.flixcart.com/image/312/312/xif0q/mobile/z/d/c/-original-imagcvhxubttxe7r.jpeg?q=70',
      },
    ],
    highlights: '6 GB RAM | 128 GB ROM | 6.67 inch Super AMOLED Display',
    seller: 'RedmiHub',
    replacement: '10 Days Replacement Policy',
    invoice: 'GST invoice available',
    specificaton: [
      {
        inTheBox:
          'Handset, Power Adapter, USB Type-C Cable, SIM Ejector Pin, Soft Case, User Manual',
        modelNumber: 'M2013K6G',
        modelName: 'Note 11 Pro',
        type: 'Celestial Blue',
        batteryCapacity: '5020mAh',
      },
    ],
    brand: 'Redmi',
    stock: 18,
    __v: 0,
    features: [
      'Versatile camera setup for creative photography',
      'Super AMOLED display for vivid visuals',
      'Powerful processor for smooth multitasking',
      'High-capacity battery for all-day usage',
    ],
    numrev: 15,
    rating: 92,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f151',
        rate: 4.7,
        Comment: 'Great value for money. Excellent camera performance.',
        date: '2025-04-01T14:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae771',
      },
      {
        user: '63d1089e7ee22fa515771f152',
        rate: 4.6,
        Comment: 'Smooth display and fast performance.',
        date: '2025-04-05T11:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae772',
      },
    ],
    sell: 12,
  },
  {
    _id: '64c37c823c03172a9facc094',
    id: 52,
    title: 'Realme 9 Pro',
    price: 15999,
    cureted_price: 17999,
    ratings: 3.9,
    decr: "Experience the next level of performance with the Realme 9 Pro. This smartphone features a stunning camera setup, a high-refresh-rate display, and a powerful processor. Whether you're capturing moments or enjoying multimedia, the Realme 9 Pro is designed to deliver a premium experience.",
    category: 'mobile',
    images: [
      {
        _id: '651d50b7e2b392999df759fb',
        url: 'https://rukminim2.flixcart.com/image/312/312/kzogn0w0/mobile/m/p/2/-original-imagbmf4qsurna5j.jpeg?q=70',
      },
    ],
    highlights: '6 GB RAM | 128 GB ROM | 6.4 inch Super AMOLED Display',
    seller: 'RealmeStore',
    replacement: '12 Days Replacement Policy',
    invoice: 'GST invoice available',
    specificaton: [
      {
        inTheBox:
          'Handset, Adapter, USB Type-C Cable, SIM Card Tool, Case, Quick Guide, Warranty Card',
        modelNumber: 'RMX3381',
        modelName: '9 Pro',
        type: 'Lightning Blue',
        batteryCapacity: '4500mAh',
      },
    ],
    brand: 'Realme',
    stock: 20,
    __v: 0,
    features: [
      'Versatile camera system for creative photography',
      'Super AMOLED display for vibrant visuals',
      'Snapdragon processor for seamless multitasking',
      'Fast charging for quick power replenishment',
    ],
    numrev: 18,
    rating: 94,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f153',
        rate: 4.8,
        Comment: 'Fantastic camera quality. Loving the vibrant display.',
        date: '2025-05-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae773',
      },
      {
        user: '63d1089e7ee22fa515771f154',
        rate: 4.7,
        Comment: 'Smooth performance and stylish design.',
        date: '2025-05-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae774',
      },
    ],
    sell: 15,
  },
  {
    _id: '64c37c823c03172a9facc095',
    id: 53,
    title: 'Nokia X30',
    price: 1899,
    cureted_price: 2099,
    ratings: 3.2,
    decr: 'Rediscover the iconic Nokia experience with the Nokia X30. This smartphone combines classic design with modern features, offering a pure Android experience and reliable performance. Capture memories, enjoy multimedia, and stay connected with the Nokia X30.',
    category: 'mobile',
    images: [
      {
        _id: '651d50b7e2b392999df759fc',
        url: 'https://rukminim2.flixcart.com/image/312/312/ked56kw0/mobile/j/y/9/nokia-5310-12pisx21a01-original-imafv2dvfhnnhzy3.jpeg?q=70',
      },
    ],
    highlights: '6 GB RAM | 128 GB ROM | 6.5 inch Full HD+ Display',
    seller: 'NokiaOfficial',
    replacement: '15 Days Replacement Policy',
    invoice: 'GST invoice available',
    specificaton: [
      {
        inTheBox: 'Nokia X30, Charger, USB Cable, Headset, Quick Guide',
        modelNumber: 'TA-2023',
        modelName: 'X30',
        type: 'Midnight Blue',
        batteryCapacity: '5000mAh',
      },
    ],
    brand: 'Nokia',
    stock: 25,
    __v: 0,
    features: [
      'Timeless Nokia design for a classic appeal',
      'Pure Android experience for fast and secure performance',
      'Impressive display for immersive visuals',
      'Reliable battery for extended usage',
    ],
    numrev: 14,
    rating: 90,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f155',
        rate: 4.6,
        Comment: "Nokia's build quality stands out. Great user experience.",
        date: '2025-06-01T14:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae775',
      },
      {
        user: '63d1089e7ee22fa515771f156',
        rate: 4.7,
        Comment: 'Smooth performance and reliable battery life.',
        date: '2025-06-05T11:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae776',
      },
    ],
    sell: 18,
  },

  //electronics
  {
    _id: '64c37c823c03172a9facc122',
    id: 80,
    title: 'Sony 65-inch 4K Ultra HD Smart LED TV',
    price: 149999,
    cureted_price: 169999,
    category: 'electronics',
    ratings: 4.7,
    images: [
      {
        _id: '651d50b7e2b392999df75a23',
        url: 'https://rukminim2.flixcart.com/image/312/312/l3929ow0/television/h/d/m/kd-65x75k-in5-sony-original-imagef3zkuszrfgh.jpeg?q=70',
      },
    ],
    seller: 'ElectroGuru',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        screenSize: '65 inches',
        resolution: '4K Ultra HD',
        type: 'Android TV, Voice Assistant',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Sony',
        Color: 'Black',
        Type: 'Smart LED TV',
        ModelName: 'BRAVIA X900H',
        ModelID: 'SONY-BRAVIA-X900H',
        screenSize: '65 inches',
        resolution: '4K Ultra HD',
        smartFeatures: 'Android TV, Voice Assistant, Streaming Apps',
        Collection: 'BRAVIA Series',
        care: 'Stunning visuals with smart features for an immersive entertainment experience',
      },
    ],
    stock: 8,
    __v: 0,
    features: [
      'Sony 65-inch 4K Ultra HD Smart LED TV',
      'Immersive 4K Ultra HD resolution',
      'Android TV with Voice Assistant',
      'Streaming apps for endless entertainment',
    ],
    numrev: 25,
    rating: 95,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f209',
        rate: 4.8,
        Comment:
          'Outstanding picture quality. Smart features are a game-changer.',
        date: '2027-09-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7b7',
      },
      {
        user: '63d1089e7ee22fa515771f210',
        rate: 4.6,
        Comment: 'Best investment for a home theater experience. Loving it!',
        date: '2027-09-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7b8',
      },
    ],
    sell: 15,
  },
  {
    _id: '64c37c823c03172a9facc123',
    id: 81,
    title: 'Apple MacBook Pro 13-inch (M2 Chip, 16GB RAM, 512GB SSD)',
    price: 199999,
    cureted_price: 219999,
    category: 'electronics',
    ratings: 4.9,
    images: [
      {
        _id: '651d50b7e2b392999df75a24',
        url: 'https://rukminim2.flixcart.com/image/312/312/xif0q/computer/k/s/m/-original-imagfdewfh9z3pee.jpeg?q=70',
      },
    ],
    seller: 'TechHaven',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        processor: 'M2 Chip',
        ram: '16GB',
        storage: '512GB SSD',
        displaySize: '13 inches',
        type: 'macOS Monterey',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Apple',
        Color: 'Space Gray',
        Type: 'Laptop',
        ModelName: 'MacBook Pro 13-inch',
        ModelID: 'APPLE-MBP-13-M2',
        processor: 'M2 Chip',
        ram: '16GB',
        storage: '512GB SSD',
        displaySize: '13 inches',
        operatingSystem: 'macOS Monterey',
        Collection: 'MacBook Pro Series',
        care: 'Powerful performance with a sleek design for creative professionals',
      },
    ],
    stock: 10,
    __v: 0,
    features: [
      'Apple MacBook Pro 13-inch (M2 Chip, 16GB RAM, 512GB SSD)',
      'Powerful M2 Chip for seamless performance',
      '16GB RAM and 512GB SSD for storage and speed',
      'Sleek design with macOS Monterey',
    ],
    numrev: 30,
    rating: 97,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f211',
        rate: 5,
        Comment:
          "Absolutely love my MacBook Pro. It's a powerhouse for my work.",
        date: '2027-10-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7b9',
      },
      {
        user: '63d1089e7ee22fa515771f212',
        rate: 4.8,
        Comment: 'Fast and reliable. Perfect for professionals.',
        date: '2027-10-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7ba',
      },
    ],
    sell: 12,
  },
  {
    _id: '64c37c823c03172a9facc124',
    id: 82,
    title: 'Samsung Galaxy Watch 4 Classic (46mm, Bluetooth)',
    price: 29999,
    cureted_price: 34999,
    category: 'electronics',
    ratings: 4.8,
    images: [
      {
        _id: '651d50b7e2b392999df75a25',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/smartwatch/w/z/l/49-smasung-active-smart-watch-android-ios-speedfox-yes-original-imagwu5hceujhgkh.jpeg?q=70',
      },
    ],
    seller: 'GadgetHub',
    replacement: '15 Days Replacement Policy',
    specificaton: [
      {
        displaySize: '46mm',
        type: 'Bluetooth',
        healthFeatures: 'Heart Rate Monitor, Blood Pressure Monitor, ECG',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Samsung',
        Color: 'Mystic Black',
        Type: 'Smartwatch',
        ModelName: 'Galaxy Watch 4 Classic',
        ModelID: 'SAMSUNG-GW4C-46-BT',
        displaySize: '46mm',
        connectivity: 'Bluetooth',
        healthFeatures: 'Heart Rate Monitor, Blood Pressure Monitor, ECG',
        Collection: 'Galaxy Watch Series',
        care: 'Stay connected and monitor your health with this stylish smartwatch',
      },
    ],
    stock: 18,
    __v: 0,
    features: [
      'Samsung Galaxy Watch 4 Classic (46mm, Bluetooth)',
      'Large 46mm display for clear visuals',
      'Bluetooth connectivity for seamless pairing',
      'Advanced health monitoring features',
    ],
    numrev: 20,
    rating: 94,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f213',
        rate: 4.9,
        Comment: 'Love the design and functionality. Great fitness companion.',
        date: '2027-11-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7bb',
      },
      {
        user: '63d1089e7ee22fa515771f214',
        rate: 4.7,
        Comment: 'Samsung never disappoints. Impressive watch!',
        date: '2027-11-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7bc',
      },
    ],
    sell: 14,
  },
  {
    _id: '64c37c823c03172a9facc125',
    id: 83,
    title: 'Bose QuietComfort 45 Wireless Noise Cancelling Headphones',
    price: 3499,
    cureted_price: 3999,
    category: 'electronics',
    ratings: 4.9,
    images: [
      {
        _id: '651d50b7e2b392999df75a26',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/headphone/m/w/p/-original-imaguxdnykcjtpkc.jpeg?q=70',
      },
    ],
    seller: 'AudioMasters',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        wireless: true,
        noiseCancelling: true,
        batteryLife: 'Up to 24 hours',
        type: 'Bluetooth',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Bose',
        Color: 'Black',
        Type: 'Wireless Headphones',
        ModelName: 'QuietComfort 45',
        ModelID: 'BOSE-QC45-WIRELESS',
        wireless: true,
        noiseCancelling: true,
        batteryLife: 'Up to 24 hours',
        connectivity: 'Bluetooth',
        Collection: 'QuietComfort Series',
        care: 'Immerse yourself in music with superior comfort and noise cancellation',
      },
    ],
    stock: 25,
    __v: 0,
    features: [
      'Bose QuietComfort 45 Wireless Noise Cancelling Headphones',
      'Wireless with Bluetooth connectivity',
      'Advanced noise cancellation for immersive audio',
      'Up to 24 hours of battery life',
    ],
    numrev: 18,
    rating: 96,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f215',
        rate: 5,
        Comment: 'Incredible sound quality and comfort. Best headphones!',
        date: '2027-12-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7bd',
      },
      {
        user: '63d1089e7ee22fa515771f216',
        rate: 4.8,
        Comment: 'The noise cancellation is a game-changer. Worth every penny.',
        date: '2027-12-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7be',
      },
    ],
    sell: 20,
  },
  {
    _id: '64c37c823c03172a9facc126',
    id: 84,
    title: 'Sony BRAVIA XR A90J 65-inch OLED 4K Ultra HD Smart Google TV',
    price: 249999,
    cureted_price: 279999,
    category: 'electronics',
    ratings: 4.7,
    images: [
      {
        _id: '651d50b7e2b392999df75a27',
        url: 'https://rukminim2.flixcart.com/image/312/312/jpcxrww0/television/x/m/c/sony-kd-65a9f-original-imafbcdfewe32s7u.jpeg?q=70',
      },
    ],
    seller: 'TechWorldElectronics',
    replacement: '45 Days Replacement Policy',
    specificaton: [
      {
        screenSize: '65 inches',
        displayType: 'OLED',
        resolution: '4K Ultra HD',
        type: 'Google TV, Voice Remote',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Sony',
        Color: 'Black',
        Type: 'Smart TV',
        ModelName: 'BRAVIA XR A90J',
        ModelID: 'SONY-A90J-65-OLED',
        screenSize: '65 inches',
        displayType: 'OLED',
        resolution: '4K Ultra HD',
        smartFeatures: 'Google TV, Voice Remote',
        Collection: 'BRAVIA XR Series',
        care: 'Immerse yourself in a cinematic experience with Sony BRAVIA XR technology',
      },
    ],
    stock: 15,
    __v: 0,
    features: [
      'Sony BRAVIA XR A90J 65-inch OLED 4K Ultra HD Smart Google TV',
      'Stunning 65-inch OLED display for vibrant visuals',
      '4K Ultra HD resolution for crystal-clear images',
      'Smart TV features with Google TV and Voice Remote',
    ],
    numrev: 25,
    rating: 94,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f217',
        rate: 4.7,
        Comment: 'Amazing picture quality and smart features. Love it!',
        date: '2028-01-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7bf',
      },
      {
        user: '63d1089e7ee22fa515771f218',
        rate: 4.8,
        Comment: 'The OLED display is a game-changer. Worth the investment.',
        date: '2028-01-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7c0',
      },
    ],
    sell: 18,
  },
  {
    _id: '64c37c823c03172a9facc127',
    id: 85,
    title: 'DJI Mavic Air 2 - Drone Quadcopter UAV',
    price: 79999,
    cureted_price: 89999,
    category: 'electronics',
    ratings: 3.9,
    images: [
      {
        _id: '651d50b7e2b392999df75a28',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/screen-guard/tempered-glass/m/v/4/tempered-glass-lens-screen-protector-for-dji-mavic-mini-dji-mini-original-imagqxyzzydzs5za.jpeg?q=70',
      },
    ],
    seller: 'SkyHighTech',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        flightTime: '34 minutes',
        cameraResolution: '48 MP',
        maxRange: '10 km',
        type: 'SmartPhoto, FocusTrack, APAS 4.0',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'DJI',
        Color: 'Gray',
        Type: 'Drone Quadcopter',
        ModelName: 'Mavic Air 2',
        ModelID: 'DJIAIR2-DRONE',
        flightTime: '34 minutes',
        cameraResolution: '48 MP',
        maxRange: '10 km',
        intelligentModes: 'SmartPhoto, FocusTrack, APAS 4.0',
        Collection: 'Mavic Series',
        care: 'Capture stunning aerial footage with the powerful DJI Mavic Air 2 drone',
      },
    ],
    stock: 12,
    __v: 0,
    features: [
      'DJI Mavic Air 2 - Drone Quadcopter UAV',
      '34 minutes of flight time for extended aerial exploration',
      '48 MP camera for high-resolution aerial photography',
      'Intelligent modes for enhanced photo and video capture',
    ],
    numrev: 15,
    rating: 97,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f219',
        rate: 5,
        Comment: 'Impressive drone. Great camera quality and flight time.',
        date: '2028-02-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7c1',
      },
      {
        user: '63d1089e7ee22fa515771f220',
        rate: 4.9,
        Comment:
          'Easy to fly and captures amazing footage. Highly recommended.',
        date: '2028-02-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7c2',
      },
    ],
    sell: 10,
  },
  {
    _id: '64c37c823c03172a9facc128',
    id: 86,
    title: 'Samsung Odyssey G7 32-inch Curved Gaming Monitor',
    price: 69999,
    cureted_price: 79999,
    category: 'electronics',
    ratings: 4.8,
    images: [
      {
        _id: '651d50b7e2b392999df75a29',
        url: 'https://rukminim2.flixcart.com/image/312/312/xif0q/monitor/2/e/9/ls32bg750nwxxl-uhd-32-ls32bg750nwxxl-samsung-original-imaggfy4pnzbmgz8.jpeg?q=70',
      },
    ],
    seller: 'GamingTechHub',
    replacement: '45 Days Replacement Policy',
    specificaton: [
      {
        screenSize: '32 inches',
        resolution: '2560 x 1440',
        refreshRate: '240 Hz',
        responseTime: '1 ms',
        type: 'GamingTechHub',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Samsung',
        Color: 'Black',
        Type: 'Curved Gaming Monitor',
        ModelName: 'Odyssey G7',
        ModelID: 'SAMSUNG-G7-32-CURVED',
        screenSize: '32 inches',
        resolution: '2560 x 1440',
        refreshRate: '240 Hz',
        responseTime: '1 ms',
        Collection: 'Odyssey Series',
        care: 'Immerse yourself in gaming with the Samsung Odyssey G7 curved gaming monitor',
      },
    ],
    stock: 18,
    __v: 0,
    features: [
      'Samsung Odyssey G7 32-inch Curved Gaming Monitor',
      'Immersive curved display for gaming enthusiasts',
      'High resolution and refresh rate for smooth gameplay',
      '1 ms response time for reduced motion blur',
    ],
    numrev: 20,
    rating: 96,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f221',
        rate: 4.8,
        Comment: 'Absolutely love this gaming monitor. Stunning visuals!',
        date: '2028-03-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7c3',
      },
      {
        user: '63d1089e7ee22fa515771f222',
        rate: 4.9,
        Comment:
          'The 240 Hz refresh rate is a game-changer. Perfect for competitive gaming.',
        date: '2028-03-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7c4',
      },
    ],
    sell: 15,
  },
  {
    _id: '64c37c823c03172a9facc129',
    id: 87,
    title: 'Apple MacBook Pro 16-inch with M2 Pro Chip',
    price: 2299.99,
    cureted_price: 2499.99,
    category: 'electronics',
    ratings: 3.9,
    images: [
      {
        _id: '651d50b7e2b392999df75a30',
        url: 'https://rukminim2.flixcart.com/image/312/312/xif0q/computer/7/r/w/-original-imaguw3hpuzcwvdp.jpeg?q=70',
      },
    ],
    seller: 'AppleStore',
    replacement: '90 Days Replacement Policy',
    specificaton: [
      {
        screenSize: '16 inches',
        processor: 'M2 Pro Chip',
        RAM: '32 GB',
        storage: '1 TB SSD',
        type: 'AppleStore',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Apple',
        Color: 'Space Gray',
        Type: 'Laptop',
        ModelName: 'MacBook Pro 16-inch',
        ModelID: 'APPLE-MBP-16-M2',
        screenSize: '16 inches',
        processor: 'M2 Pro Chip',
        RAM: '32 GB',
        storage: '1 TB SSD',
        Collection: 'MacBook Pro Series',
        care: 'Experience the power of the Apple MacBook Pro with M2 Pro Chip',
      },
    ],
    stock: 10,
    __v: 0,
    features: [
      'Apple MacBook Pro 16-inch with M2 Pro Chip',
      'Powerful M2 Pro Chip for enhanced performance',
      '16-inch Retina display for stunning visuals',
      '32 GB RAM and 1 TB SSD for seamless multitasking',
    ],
    numrev: 18,
    rating: 98,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f223',
        rate: 4.9,
        Comment: 'Absolutely amazing laptop. Fast and reliable.',
        date: '2028-04-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7c5',
      },
      {
        user: '63d1089e7ee22fa515771f224',
        rate: 5,
        Comment: 'Worth every penny. The M2 Pro Chip is a game-changer.',
        date: '2028-04-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7c6',
      },
    ],
    sell: 8,
  },
  {
    _id: '64c37c823c03172a9facc130',
    id: 88,
    title: 'Sony WH-1000XM4 Wireless Noise-Canceling Headphones',
    price: 29999,
    cureted_price: 34999,
    category: 'electronics',
    ratings: 2.7,
    images: [
      {
        _id: '651d50b7e2b392999df75a31',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/headphone/g/m/4/wh-g900n-sony-original-imagg6bgfw7vgtgs.jpeg?q=70',
      },
    ],
    seller: 'AudioEmpire',
    replacement: '60 Days Replacement Policy',
    specificaton: [
      {
        type: 'Over-Ear',
        wireless: 'Yes',
        noiseCancellation: 'Yes',
        batteryLife: '30 hours',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Sony',
        Color: 'Black',
        Type: 'Wireless Headphones',
        ModelName: 'WH-1000XM4',
        ModelID: 'SONY-WH-1000XM4',
        type: 'Over-Ear',
        wireless: 'Yes',
        noiseCancellation: 'Yes',
        batteryLife: '30 hours',
        Collection: 'WH Series',
        care: 'Immerse yourself in music with the Sony WH-1000XM4 Wireless Noise-Canceling Headphones',
      },
    ],
    stock: 15,
    __v: 0,
    features: [
      'Sony WH-1000XM4 Wireless Noise-Canceling Headphones',
      'Industry-leading noise cancellation for immersive audio experience',
      'Wireless connectivity for convenient usage',
      '30 hours of battery life for extended listening sessions',
    ],
    numrev: 25,
    rating: 95,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f225',
        rate: 4.7,
        Comment: 'Top-notch noise cancellation. Comfortable for long use.',
        date: '2028-05-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7c7',
      },
      {
        user: '63d1089e7ee22fa515771f226',
        rate: 4.8,
        Comment: 'Excellent sound quality. The battery life is impressive.',
        date: '2028-05-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7c8',
      },
    ],
    sell: 20,
  },
  {
    _id: '64c37c823c03172a9facc131',
    id: 89,
    title: 'DJI Mavic Air 2 Drone',
    price: 799.99,
    cureted_price: 899.99,
    category: 'electronics',
    ratings: 3.9,
    images: [
      {
        _id: '651d50b7e2b392999df75a32',
        url: 'https://rukminim2.flixcart.com/image/312/312/xif0q/drone/m/h/q/40-24-1-4k-wifi-dual-camera-drone-for-adults-kids-with-2-original-imagrfa6esxteknr.jpeg?q=70',
      },
    ],
    seller: 'SkyTechInnovations',
    replacement: '45 Days Replacement Policy',
    specificaton: [
      {
        type: 'Quadcopter',
        cameraResolution: '48 MP',
        flightTime: '34 minutes',
        maxRange: '10 km',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'DJI',
        Color: 'Gray',
        Type: 'Drone',
        ModelName: 'Mavic Air 2',
        ModelID: 'DJ-Mavic-Air-2',
        type: 'Quadcopter',
        cameraResolution: '48 MP',
        flightTime: '34 minutes',
        maxRange: '10 km',
        Collection: 'Mavic Series',
        care: 'Capture stunning aerial footage with the DJI Mavic Air 2 Drone',
      },
    ],
    stock: 12,
    __v: 0,
    features: [
      'DJI Mavic Air 2 Drone',
      'High-resolution camera for professional-quality photos and videos',
      'Long flight time and impressive range for extended exploration',
      'Compact and foldable design for portability',
    ],
    numrev: 15,
    rating: 97,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f227',
        rate: 4.9,
        Comment: 'Incredible drone. The camera quality is exceptional.',
        date: '2028-06-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7c9',
      },
      {
        user: '63d1089e7ee22fa515771f228',
        rate: 5,
        Comment:
          'Easy to fly and captures breathtaking shots. Highly recommended.',
        date: '2028-06-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7ca',
      },
    ],
    sell: 18,
  },
  {
    _id: '64c37c823c03172a9facc133',
    id: 91,
    title: 'Bose QuietComfort 35 II Wireless Noise-Canceling Headphones',
    price: 27999,
    cureted_price: 32999,
    category: 'electronics',
    ratings: 4.9,
    images: [
      {
        _id: '651d50b7e2b392999df75a34',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/headphone/y/c/o/tws345-with-high-resolution-audio-48hr-playtime-led-digital-original-imagwnkq5qkmzkpf.jpeg?q=70',
      },
    ],
    seller: 'AudioEnclave',
    replacement: '45 Days Replacement Policy',
    specificaton: [
      {
        type: 'Over-Ear',
        wireless: 'Yes',
        noiseCancellation: 'Yes',
        batteryLife: '20 hours',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Bose',
        Color: 'Silver',
        Type: 'Wireless Headphones',
        ModelName: 'QuietComfort 35 II',
        ModelID: 'BOSE-QC35II',
        type: 'Over-Ear',
        wireless: 'Yes',
        noiseCancellation: 'Yes',
        batteryLife: '20 hours',
        Collection: 'QuietComfort Series',
        care: 'Experience premium audio quality with Bose QuietComfort 35 II Wireless Noise-Canceling Headphones',
      },
    ],
    stock: 18,
    __v: 0,
    features: [
      'Bose QuietComfort 35 II Wireless Noise-Canceling Headphones',
      'World-class noise cancellation for a peaceful listening experience',
      'Wireless connectivity for convenience and flexibility',
      'Up to 20 hours of battery life for all-day usage',
    ],
    numrev: 28,
    rating: 98,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f231',
        rate: 4.9,
        Comment:
          'Incredible comfort and superb noise cancellation. A must-have!',
        date: '2028-08-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7cd',
      },
      {
        user: '63d1089e7ee22fa515771f232',
        rate: 5,
        Comment: 'Excellent build quality and the sound is just amazing.',
        date: '2028-08-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7ce',
      },
    ],
    sell: 22,
  },
  {
    _id: '64c37c823c03172a9facc134',
    id: 92,
    title: 'Sony PlayStation 5 Gaming Console',
    price: 49999,
    cureted_price: 54999,
    category: 'electronics',
    ratings: 3.9,
    images: [
      {
        _id: '651d50b7e2b392999df75a35',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/gamingconsole/z/b/w/-original-imagtk7vfbzqbjg6.jpeg?q=70',
      },
    ],
    seller: 'GamingWorld',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        type: 'Gaming Console',
        storageCapacity: '825 GB SSD',
        resolution: '4K UHD',
        frameRate: 'Up to 120fps',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Sony',
        Color: 'Black',
        Type: 'Gaming Console',
        ModelName: 'PlayStation 5',
        ModelID: 'SONY-PS5',
        type: 'Gaming Console',
        storageCapacity: '825 GB SSD',
        resolution: '4K UHD',
        frameRate: 'Up to 120fps',
        Collection: 'PlayStation Series',
        care: 'Experience next-gen gaming with Sony PlayStation 5 Gaming Console',
      },
    ],
    stock: 15,
    __v: 0,
    features: [
      'Sony PlayStation 5 Gaming Console',
      'Ultra-fast SSD for quick loading and smoother gaming',
      'Immersive 4K UHD graphics and high frame rate for a stunning gaming experience',
      'Backward compatibility for playing a vast library of PS4 games',
    ],
    numrev: 25,
    rating: 97,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f233',
        rate: 4.9,
        Comment: 'Absolutely amazing. The graphics and speed are mind-blowing.',
        date: '2028-09-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7cf',
      },
      {
        user: '63d1089e7ee22fa515771f234',
        rate: 5,
        Comment: 'Best gaming console in the market. Worth every penny!',
        date: '2028-09-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7d0',
      },
    ],
    sell: 20,
  },
  // Toys

  {
    _id: '64c37c823c03172a9facc102',
    id: 60,
    title:
      'BIOTIQUE Morning Nectar Flawless Skin moisturizer | All Skin Types | For Men & Women',
    price: 24.99,
    cureted_price: 29.99,
    category: 'Beauty,Toys&more',
    ratings: 4.7,
    images: [
      {
        _id: '651d50b7e2b392999df75a03',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/moisturizer-cream/e/y/3/-original-imagwv4egk8hzzkb.jpeg?q=70',
      },
    ],
    seller: 'BeautyEssentials',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        type: 'All Skin Types',
        benefits: 'Anti-Aging, Hydrating, Firming',
        size: '1.7 oz (50ml)',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: "L'Oréal Paris",
        Color: 'N/A',
        Type: 'Moisturizer',
        ModelName: 'Revitalift Triple Power',
        ModelID: 'REVITALIFT-TRIPLE-POWER',
        skinType: 'All Skin Types',
        benefits: 'Anti-Aging, Hydrating, Firming',
        size: '1.7 oz (50ml)',
        Collection: 'premium skincare',
        care: 'Apply morning and night for best results',
      },
    ],
    stock: 40,
    __v: 0,
    features: [
      'Anti-aging moisturizer for smoother and firmer skin',
      'Hydrates and nourishes the skin',
      'Suitable for all skin types',
      '1.7 oz (50ml) size for long-lasting use',
    ],
    numrev: 20,
    rating: 93,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f169',
        rate: 4.8,
        Comment: 'Love the texture and how it makes my skin feel.',
        date: '2026-01-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae789',
      },
      {
        user: '63d1089e7ee22fa515771f170',
        rate: 4.7,
        Comment: 'Visible improvement in skin firmness after regular use.',
        date: '2026-01-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae790',
      },
    ],
    sell: 35,
  },
  {
    _id: '64c37c823c03172a9facc103',
    id: 61,
    title:
      'Lakmé Set of 2 Matte,Smudgeproof,Long Lasting Kajal  (Deep Black, 0.7 g)',
    price: 54.99,
    cureted_price: 64.99,
    category: 'Beauty,Toys&more',
    ratings: 3.9,
    images: [
      {
        _id: '651d50b7e2b392999df75a04',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/kajal/u/z/q/-original-imaggdynczfj9pbt.jpeg?q=70',
      },
    ],
    seller: 'GlamourHub',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        type: 'Neutral Rose Tones',
        finish: 'Matte, Shimmer',
        size: '12 x 0.05 oz (1.3g)',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Urban Decay',
        Color: 'N/A',
        Type: 'Eyeshadow Palette',
        ModelName: 'Naked3',
        ModelID: 'NAKED3',
        shadeType: 'Neutral Rose Tones',
        finish: 'Matte, Shimmer',
        size: '12 x 0.05 oz (1.3g)',
        Collection: 'luxury eyeshadow palettes',
        care: 'Blend and layer for customized eye looks',
      },
    ],
    stock: 25,
    __v: 0,
    features: [
      'Eyeshadow palette with neutral rose-toned shades',
      'Matte and shimmer finishes for versatile looks',
      'Highly pigmented and blendable formula',
      '12 x 0.05 oz (1.3g) size for long-lasting use',
    ],
    numrev: 18,
    rating: 97,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f171',
        rate: 5.0,
        Comment:
          'Absolute favorite palette. Beautiful colors and great quality.',
        date: '2026-02-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae791',
      },
      {
        user: '63d1089e7ee22fa515771f172',
        rate: 4.8,
        Comment: 'Versatile shades for both day and night makeup.',
        date: '2026-02-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae792',
      },
    ],
    sell: 20,
  },
  {
    _id: '64c37c823c03172a9facc104',
    id: 62,
    title:
      'LEGO Creator 3-in-1 Beach Camper Van (556 Blocks) Model Building Kit',
    price: 119.99,
    cureted_price: 139.99,
    category: 'Beauty,Toys&more',
    ratings: 2.8,
    images: [
      {
        _id: '651d50b7e2b392999df75a05',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/model-building-kit/m/9/b/0-96-31138-lego-original-imagrwjngawmfabx.jpeg?q=70',
      },
    ],
    seller: 'ToyEmporium',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        ageGroup: '16+',
        pieces: 1334,
        dimensions: '11 x 5 x 5 inches',
        type: 'ToyEmporium',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'LEGO',
        Color: 'Multicolor',
        Type: 'Building Kit',
        ModelName: 'Volkswagen T1 Camper Van',
        ModelID: 'VW-T1-CAMPER',
        ageGroup: '16+',
        pieces: 1334,
        dimensions: '11 x 5 x 5 inches',
        Collection: 'LEGO Creator Expert',
        care: 'Display as a collectible or enjoy building',
      },
    ],
    stock: 15,
    __v: 0,
    features: [
      'LEGO building kit for the iconic Volkswagen T1 Camper Van',
      'Detailed model with 1334 pieces for a challenging build',
      'Multicolor bricks for a vibrant and realistic look',
      'Dimensions: 11 x 5 x 5 inches',
    ],
    numrev: 10,
    rating: 94,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f173',
        rate: 4.9,
        Comment: 'Fantastic details. A must-have for LEGO enthusiasts.',
        date: '2026-03-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae793',
      },
      {
        user: '63d1089e7ee22fa515771f174',
        rate: 4.7,
        Comment:
          'Challenging and rewarding build. Great addition to my collection.',
        date: '2026-03-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae794',
      },
    ],
    sell: 12,
  },
  {
    _id: '64c37c823c03172a9facc105',
    id: 63,
    title:
      'Aseenaa Flash Drum With 3D Lights & Music With Good Quality Plastic And Battery Operated  (Multicolor)',
    price: 49.99,
    cureted_price: 59.99,
    category: 'Beauty,Toys&more',
    ratings: 3.9,
    images: [
      {
        _id: '651d50b7e2b392999df75a06',
        url: 'https://rukminim2.flixcart.com/image/612/612/l29c9e80/musical-toy/v/u/y/flash-drum-with-3d-lights-music-with-good-quality-plastic-and-original-imagdnd5nshbnebh.jpeg?q=70',
      },
    ],
    seller: 'BabyJoyStore',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        ageGroup: '0-36 months',
        type: 'Kick and Play piano, detachable toys, machine-washable mat',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Fisher-Price',
        Color: 'Multicolor',
        Type: 'Baby Gym',
        ModelName: "Deluxe Kick 'n Play Piano Gym",
        ModelID: 'KICK-PLAY-PIANO-GYM',
        ageGroup: '0-36 months',
        features: "Kick 'n Play piano, detachable toys, machine-washable mat",
        Collection: 'Infant Development Toys',
        care: 'Mat is machine washable for easy cleaning',
      },
    ],
    stock: 20,
    __v: 0,
    features: [
      "Interactive baby gym with Kick 'n Play piano",
      'Detachable toys for versatile play',
      'Machine-washable mat for easy cleaning',
      'Suitable for ages 0-36 months',
    ],
    numrev: 15,
    rating: 96,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f175',
        rate: 5.0,
        Comment: "Perfect for baby's play and development. The piano is a hit!",
        date: '2026-04-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae795',
      },
      {
        user: '63d1089e7ee22fa515771f176',
        rate: 4.8,
        Comment: 'Great quality and engaging for little ones.',
        date: '2026-04-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae796',
      },
    ],
    sell: 18,
  },
  {
    _id: '64c37c823c03172a9facc106',
    id: 64,
    title: 'Oral-B Pro 1000 Electric Toothbrush with CrossAction Brush Head',
    price: 39.99,
    cureted_price: 49.99,
    category: 'Beauty,Toys&more',
    ratings: 4.7,
    images: [
      {
        _id: '651d50b7e2b392999df75a07',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/electric-toothbrush/f/q/a/-original-imagny5yh6wpvspe.jpeg?q=70',
      },
    ],
    seller: 'DentalWellnessHub',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        type: 'Daily Clean',
        pressureSensor: true,
        timer: true,
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Oral-B',
        Color: 'White',
        Type: 'Electric Toothbrush',
        ModelName: 'Pro 1000',
        ModelID: 'PRO-1000',
        brushMode: 'Daily Clean',
        pressureSensor: true,
        timer: true,
        Collection: 'Advanced Oral Care',
        care: 'Replace brush head every 3 months for optimal performance',
      },
    ],
    stock: 30,
    __v: 0,
    features: [
      'Electric toothbrush with Daily Clean mode',
      'Pressure sensor for gentle brushing',
      'Timer to ensure recommended brushing time',
      'Includes CrossAction brush head',
    ],
    numrev: 25,
    rating: 92,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f177',
        rate: 4.8,
        Comment: 'Effective cleaning. Love the pressure sensor feature.',
        date: '2026-05-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae797',
      },
      {
        user: '63d1089e7ee22fa515771f178',
        rate: 4.7,
        Comment: 'Improved my oral hygiene routine. Great product!',
        date: '2026-05-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae798',
      },
    ],
    sell: 22,
  },
  {
    _id: '64c37c823c03172a9facc107',
    id: 65,
    title: "Johnson's Baby Shampoo, Tear-Free Gentle Cleanser",
    price: 5.99,
    cureted_price: 7.99,
    category: 'Beauty,Toys&more',
    ratings: 2.9,
    images: [
      {
        _id: '651d50b7e2b392999df75a08',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/shampoo/0/i/c/-original-imagv2perrqw5evf.jpeg?q=70',
      },
    ],
    seller: 'BabyEssentialsStore',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        type: 'Newborn and Up',
        hypoallergenic: true,
        noMoreTears: true,
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: "Johnson's",
        Color: 'Clear',
        Type: 'Baby Shampoo',
        ModelName: 'Tear-Free Gentle Cleanser',
        ModelID: 'BABY-SHAMPOO',
        ageGroup: 'Newborn and Up',
        hypoallergenic: true,
        noMoreTears: true,
        Collection: 'Baby Essentials',
        care: "Gently cleanse baby's hair without causing tears",
      },
    ],
    stock: 40,
    __v: 0,
    features: [
      'Tear-free baby shampoo for gentle cleansing',
      'Hypoallergenic and suitable for newborns and up',
      'No More Tears formula for a happy bath time',
      'Clear color and pleasant fragrance',
    ],
    numrev: 30,
    rating: 98,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f179',
        rate: 5.0,
        Comment: 'Best baby shampoo. Gentle on the eyes and smells great!',
        date: '2026-06-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae799',
      },
      {
        user: '63d1089e7ee22fa515771f180',
        rate: 4.9,
        Comment: "Trusted brand. Excellent for baby's delicate hair.",
        date: '2026-06-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae79a',
      },
    ],
    sell: 35,
  },
  {
    _id: '64c37c823c03172a9facc108',
    id: 66,
    title: 'Dove Sensitive Skin Beauty Bar Soap',
    price: 4.99,
    cureted_price: 6.99,
    category: 'Beauty,Toys&more',
    ratings: 3.8,
    images: [
      {
        _id: '651d50b7e2b392999df75a09',
        url: 'https://rukminim2.flixcart.com/image/612/612/kfoapow0/soap/z/5/g/4-400-pink-rosa-moistirizing-cream-beauty-bathing-bar-100g-pack-original-imafw2wkvzgktc2g.jpeg?q=70',
      },
    ],
    seller: 'HygieneHaven',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        type: 'Sensitive',
        fragrance: 'Unscented',
        moisturizing: true,
      },
    ],
    productDetails: [
      {
        packOf: '4',
        brand: 'Dove',
        Color: 'White',
        Type: 'Beauty Bar Soap',
        ModelName: 'Sensitive Skin',
        ModelID: 'DOVE-SENSITIVE-SOAP',
        skinType: 'Sensitive',
        fragrance: 'Unscented',
        moisturizing: true,
        Collection: 'Personal Hygiene',
        care: 'Gentle and moisturizing for sensitive skin',
      },
    ],
    stock: 25,
    __v: 0,
    features: [
      'Sensitive skin beauty bar soap by Dove',
      'Unscented for gentle cleansing',
      'Moisturizing formula for soft and smooth skin',
      'Pack of 4 bars for long-lasting use',
    ],
    numrev: 20,
    rating: 95,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f181',
        rate: 4.9,
        Comment: 'Love the unscented formula. Perfect for sensitive skin.',
        date: '2026-07-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae79b',
      },
      {
        user: '63d1089e7ee22fa515771f182',
        rate: 4.7,
        Comment: 'Excellent for daily use. Leaves skin feeling fresh.',
        date: '2026-07-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae79c',
      },
    ],
    sell: 18,
  },
  {
    _id: '64c37c823c03172a9facc109',
    id: 67,
    title:
      'Nuerma Science Moroccan Argan Hair Oil Without Mineral Hair Oil For Restoring Shinness',
    price: 34.99,
    cureted_price: 44.99,
    category: 'Beauty,Toys&more',
    ratings: 4.9,
    images: [
      {
        _id: '651d50b7e2b392999df75a10',
        url: 'https://rukminim2.flixcart.com/image/612/612/kfwvcsw0/hair-oil/z/x/p/100-moroccan-argan-hair-oil-without-mineral-hair-oil-for-original-imafw8yfwdyemgzg.jpeg?q=70',
      },
    ],
    seller: 'HairLuxuryCo',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        type: 'All Hair Types',
        usage: 'Leave-in treatment',
        benefits: 'Frizz control, shine enhancement, and conditioning',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Moroccanoil',
        Color: 'Transparent',
        Type: 'Hair Oil',
        ModelName: 'Treatment Original',
        ModelID: 'MOROCCANOIL-HAIR-OIL',
        hairType: 'All Hair Types',
        usage: 'Leave-in treatment',
        benefits: 'Frizz control, shine enhancement, and conditioning',
        Collection: 'Luxury Hair Care',
        care: 'Apply a small amount to clean, towel-dried hair for best results',
      },
    ],
    stock: 15,
    __v: 0,
    features: [
      'Moroccanoil Treatment Original - Hair Oil',
      'Suitable for all hair types',
      'Leave-in treatment for frizz control and shine enhancement',
      'Deeply conditions and nourishes hair',
    ],
    numrev: 25,
    rating: 97,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f183',
        rate: 5.0,
        Comment: 'Miracle worker for my hair! Adds shine and tames frizz.',
        date: '2026-08-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae79d',
      },
      {
        user: '63d1089e7ee22fa515771f184',
        rate: 4.8,
        Comment: 'Luxurious hair treatment. Smells amazing too!',
        date: '2026-08-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae79e',
      },
    ],
    sell: 12,
  },
  {
    _id: '64c37c823c03172a9facc110',
    id: 68,
    title:
      'COLORS QUEEN Creamy Matte Lip and Cheek Tint for women Enriched with Vitamin E  (Ruby_Woo_, 8 g)',
    price: 17.99,
    cureted_price: 24.99,
    category: 'Beauty,Toys&more',
    ratings: 4.7,
    images: [
      {
        _id: '651d50b7e2b392999df75a11',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/lip-stain/e/w/i/8-creamy-matte-lip-and-cheek-tint-for-women-enriched-with-original-imagmvduzamtefhh.jpeg?q=70',
      },
    ],
    seller: 'GlamourCosmetics',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        type: 'Matte',
        shade: 'Ruby Woo',
        finish: 'Intense color payoff',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'MAC',
        Color: 'Red',
        Type: 'Matte Lipstick',
        ModelName: 'Ruby Woo',
        ModelID: 'MAC-RUBY-WOO',
        lipstickType: 'Matte',
        shade: 'Ruby Woo',
        finish: 'Intense color payoff',
        Collection: 'Classic Lip Shades',
        care: 'Apply evenly on clean, exfoliated lips for a bold look',
      },
    ],
    stock: 20,
    __v: 0,
    features: [
      'MAC Matte Lipstick in Ruby Woo',
      'Intense and vibrant red shade',
      'Matte finish for a bold and long-lasting look',
      'Provides intense color payoff',
    ],
    numrev: 18,
    rating: 94,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f185',
        rate: 4.8,
        Comment: 'Love the classic red shade! Lasts all day without drying.',
        date: '2026-09-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae79f',
      },
      {
        user: '63d1089e7ee22fa515771f186',
        rate: 4.6,
        Comment:
          'Matte finish is perfect. A must-have in my makeup collection.',
        date: '2026-09-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7a0',
      },
    ],
    sell: 15,
  },
  {
    _id: '64c37c823c03172a9facc111',
    id: 69,
    title:
      'MINARA Nude Eye Shadow Palette and Rose Gold Eyeshadow (18+18 colors) 36 g  (Multicolor)',
    price: 49.99,
    cureted_price: 59.99,
    category: 'Beauty,Toys&more',
    ratings: 3.8,
    images: [
      {
        _id: '651d50b7e2b392999df75a12',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/eye-shadow/y/f/u/-original-imagsfspy6zjhwjk.jpeg?q=70',
      },
    ],
    seller: 'GlamourCosmetics',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        type: 'Eyeshadow',
        shadeRange: 'Neutral Rosy Tones',
        finish: 'Matte and Shimmer',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Urban Decay',
        Color: 'Various',
        Type: 'Eyeshadow Palette',
        ModelName: 'Naked3',
        ModelID: 'URBAN-DECAY-NAKED3',
        paletteType: 'Eyeshadow',
        shadeRange: 'Neutral Rosy Tones',
        finish: 'Matte and Shimmer',
        Collection: 'Naked Series',
        care: 'Create various eye looks with the versatile shades',
      },
    ],
    stock: 15,
    __v: 0,
    features: [
      'Urban Decay Naked3 Eyeshadow Palette',
      'Neutral rosy tones for versatile eye looks',
      'Combination of matte and shimmer finishes',
      'Highly pigmented and blendable shades',
    ],
    numrev: 22,
    rating: 96,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f187',
        rate: 4.9,
        Comment: 'My go-to eyeshadow palette. Love the rosy tones!',
        date: '2026-10-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7a1',
      },
      {
        user: '63d1089e7ee22fa515771f188',
        rate: 4.7,
        Comment:
          'Matte and shimmer combination is perfect. Great pigmentation.',
        date: '2026-10-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7a2',
      },
    ],
    sell: 12,
  },
  {
    _id: '64c37c823c03172a9facc112',
    id: 70,
    title: 'Lotus Organics+ Precious Brightening Cleansing Balm  (50 g)',
    price: 14.99,
    cureted_price: 19.99,
    category: 'Beauty,Toys&more',
    ratings: 4.6,
    images: [
      {
        _id: '651d50b7e2b392999df75a13',
        url: 'https://rukminim2.flixcart.com/image/612/612/ks7tuvk0/cleanser/c/g/7/50-precious-brightening-cleansing-balm-lotus-organics-original-imag5tukfvezfxhy.jpeg?q=70',
      },
    ],
    seller: 'SkinWellnessCo',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        type: 'Normal to Dry',
        formulation: 'Non-foaming Cream',
        keyIngredients: ['Ceramides', 'Hyaluronic Acid'],
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'CeraVe',
        Color: 'White',
        Type: 'Hydrating Cleanser',
        ModelName: 'Normal to Dry Skin',
        ModelID: 'CERAVE-HYDRATING-CLEANSER',
        skinType: 'Normal to Dry',
        formulation: 'Non-foaming Cream',
        keyIngredients: ['Ceramides', 'Hyaluronic Acid'],
        Collection: 'Hydration Series',
        care: 'Gently cleanse and hydrate your skin with this non-irritating formula',
      },
    ],
    stock: 20,
    __v: 0,
    features: [
      'CeraVe Hydrating Cleanser for Normal to Dry Skin',
      'Non-foaming cream cleanser',
      'Infused with ceramides and hyaluronic acid for hydration',
      'Gentle on the skin, suitable for daily use',
    ],
    numrev: 15,
    rating: 92,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f189',
        rate: 4.7,
        Comment:
          'Great for my dry skin. Leaves it feeling soft and moisturized.',
        date: '2026-11-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7a3',
      },
      {
        user: '63d1089e7ee22fa515771f190',
        rate: 4.5,
        Comment: 'Cleanses without drying. Love the added hydration.',
        date: '2026-11-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7a4',
      },
    ],
    sell: 18,
  },
  {
    _id: '64c37c823c03172a9facc113',
    id: 71,
    title:
      'HENGLOBE Building Blocks Puzzles Games Bricks Toys Sets with Wheel Educational Toys for Kids and Children for 3 - 5 Years and Above, Multicolour (100+ Pieces Blocks Bricks) Kids Edition  (100 Pieces)',
    price: 19.99,
    cureted_price: 24.99,
    category: 'Beauty,Toys&more',
    ratings: 3.9,
    images: [
      {
        _id: '651d50b7e2b392999df75a14',
        url: 'https://rukminim2.flixcart.com/image/612/612/kuipea80/learning-toy/f/s/i/lea-toy-1-shree-ji-enterprise-original-imag7mhzj3bnfqdw.jpeg?q=70',
      },
    ],
    seller: 'ToyWonderland',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        type: 'Wood',
        numberofPieces: 48,
        recommendedAge: '3 years and up',
      },
    ],
    productDetails: [
      {
        packOf: '4',
        brand: 'Melissa & Doug',
        Color: 'Multicolor',
        Type: 'Jigsaw Puzzles',
        ModelName: 'Wooden Puzzles Set',
        ModelID: 'MD-WOODEN-PUZZLES-SET',
        material: 'Wood',
        numberofPieces: 48,
        recommendedAge: '3 years and up',
        Collection: 'Educational Toys',
        care: 'Ideal for developing fine motor skills and problem-solving abilities',
      },
    ],
    stock: 25,
    __v: 0,
    features: [
      'Melissa & Doug Wooden Jigsaw Puzzles - Set of 4',
      'Made of high-quality wood material',
      'Includes four different colorful puzzles',
      'Recommended for kids aged 3 years and up',
    ],
    numrev: 30,
    rating: 98,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f191',
        rate: 5.0,
        Comment:
          'Excellent quality puzzles. My kids love putting them together!',
        date: '2026-12-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7a5',
      },
      {
        user: '63d1089e7ee22fa515771f192',
        rate: 4.8,
        Comment: 'Great educational value. Keeps the kids engaged.',
        date: '2026-12-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7a6',
      },
    ],
    sell: 20,
  },

  //tv and appliances

  {
    _id: '64w37c823c03172a9facc084',
    id: 42,
    title: 'Philips PowerPro FC9352/01-Compact Bagless Vacuum Cleaner for home',
    price: 19999,
    cureted_price: 24999,
    category: 'tv&appliances',
    ratings: 4.9,
    images: [
      {
        _id: '651d50b7e2b392999df759f1',
        url: 'https://rukminim2.flixcart.com/image/312/312/xif0q/vacuum-cleaner/o/q/e/-original-imagzyvmhrjtbgyh.jpeg?q=70',
      },
    ],
    seller: 'SmartHomeTech',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        type: 'Robot Vacuum Cleaner',
        features: 'Smart Cleaning, Auto Navigation',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'SmartHomeTech',
        Color: 'White',
        Type: 'Home Appliance',
        ModelName: 'ROBOT-VACUUM-CLEANER-SMART',
        ModelID: 'ROBOT-VACUUM-CLEANER-SMART',
        type: 'Robot Vacuum Cleaner',
        features: 'Smart Cleaning, Auto Navigation',
        Collection: 'smart home appliances',
        care: 'Regular maintenance for optimal performance',
      },
    ],
    stock: 25,
    __v: 0,
    features: [
      'Smart robot vacuum cleaner with auto navigation',
      'Efficient cleaning for various floor types',
      'App-controlled for convenient usage',
    ],
    numrev: 15,
    rating: 96,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f133',
        rate: 5,
        Comment: 'Revolutionary cleaning technology. Highly recommended.',
        date: '2024-10-12T12:55:30.892Z',
        _id: '64e9d16786e6fe3e932ae753',
      },
      {
        user: '63d1089e7ee22fa515771f134',
        rate: 4.5,
        Comment: 'Makes cleaning so much easier. Love the smart features.',
        date: '2024-10-15T11:15:12.892Z',
        _id: '64e9d16786e6fe3e932ae754',
      },
    ],
    sell: 20,
  },
  {
    _id: '64c37c823c03172i9facc086',
    id: 44,
    title: 'AGARO Crystal French Press Coffee Maker, 600 ML,',
    price: 79.99,
    cureted_price: 89.99,
    category: 'tv&appliances',
    ratings: 4.6,
    images: [
      {
        _id: '651d50b7e2b392999df759f3',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/coffee-maker/0/0/m/tesora-brewbelle-espresso-machine-coffee-maker-t0011-em-a-tesora-original-imagvsh4fhfpy7fn.jpeg?q=70',
      },
    ],
    seller: 'BrewMasters',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        type: 'Coffee Maker',
        brewSystem: 'Programmable',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'BrewMasters',
        Color: 'Black',
        Type: 'Kitchen Appliance',
        ModelName: 'COFFEE-MAKER-PROGRAMMABLE',
        ModelID: 'COFFEE-MAKER-PROGRAMMABLE',
        type: 'Coffee Maker',
        brewSystem: 'Programmable',
        Collection: 'coffee enthusiasts',
        care: 'Clean regularly for optimal coffee taste',
      },
    ],
    stock: 26,
    __v: 0,
    features: [
      'Programmable coffee maker for customized brewing',
      'Brew strength control and timer settings',
      'Ideal for coffee enthusiasts and busy mornings',
    ],
    numrev: 14,
    rating: 90,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f137',
        rate: 4.5,
        Comment: 'Love the programmable feature. Makes mornings easier.',
        date: '2024-11-12T12:55:30.892Z',
        _id: '64e9d16786e6fe3e932ae757',
      },
      {
        user: '63d1089e7ee22fa515771f138',
        rate: 4,
        Comment: 'Great coffee maker. Easy to use and clean.',
        date: '2024-11-15T11:15:12.892Z',
        _id: '64e9d16786e6fe3e932ae758',
      },
    ],
    sell: 18,
  },
  {
    _id: '64c37c823c03172a9favc087',
    id: 45,
    title: 'Digital Air Fryer: Healthy Cooking Companion',
    price: 89.99,
    cureted_price: 109.99,
    category: 'tv&appliances',
    ratings: 3.9,
    images: [
      {
        _id: '651d50b7e2b392999df759f4',
        url: 'https://rukminim2.flixcart.com/image/416/416/xif0q/air-fryer/2/2/8/black-2-5-1000-llhfd425-lifelong-original-imagtvggcsy9pvzb.jpeg?q=70',
      },
    ],
    seller: 'KitchenInnovate',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        type: 'Air Fryer',
        technology: 'Digital',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'KitchenInnovate',
        Color: 'Silver',
        Type: 'Kitchen Appliance',
        ModelName: 'DIGITAL-AIR-FRYER',
        ModelID: 'DIGITAL-AIR-FRYER',
        type: 'Digital Air Fryer',
        technology: 'Digital',
        Collection: 'healthy kitchen essentials',
        care: 'Follow recipe guidelines for best results',
      },
    ],
    stock: 20,
    __v: 0,
    features: [
      'Digital air fryer for healthy and oil-free cooking',
      'Adjustable temperature and timer settings',
      'Large capacity for family-sized meals',
    ],
    numrev: 16,
    rating: 97,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f139',
        rate: 5,
        Comment: 'Love this air fryer. Cooks delicious and healthy meals.',
        date: '2024-12-02T10:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae759',
      },
      {
        user: '63d1089e7ee22fa515771f140',
        rate: 4.5,
        Comment: 'Easy to use and clean. Highly recommend.',
        date: '2024-12-05T08:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae760',
      },
    ],
    sell: 15,
  },
  {
    _id: '64c37c823c03172a9facn085',
    id: 43,
    title: 'Air Purifier: HEPA Filtration System',
    price: 129.99,
    cureted_price: 149.99,
    category: 'tv&appliances',
    ratings: 2.8,
    images: [
      {
        _id: '651d50b7e2b392999df759f2',
        url: 'https://rukminim2.flixcart.com/image/312/312/ko0d6kw0/air-purifier/k/c/b/alps-uv-airpurifier-kent-original-imag2kdefkznuqkg.jpeg?q=70',
      },
    ],
    seller: 'CleanAirTech',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        type: 'Air Purifier',
        filtrationSystem: 'HEPA',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'CleanAirTech',
        Color: 'White',
        Type: 'Home Appliance',
        ModelName: 'AIR-PURIFIER-HEPA',
        ModelID: 'AIR-PURIFIER-HEPA',
        type: 'Air Purifier',
        filtrationSystem: 'HEPA',
        Collection: 'clean air essentials',
        care: 'Replace filters periodically for optimal performance',
      },
    ],
    stock: 22,
    __v: 0,
    features: [
      'Air purifier with HEPA filtration system',
      'Removes allergens, dust, and pollutants',
      'Quiet and energy-efficient operation',
    ],
    numrev: 18,
    rating: 94,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f135',
        rate: 4.5,
        Comment:
          'Effective air purifier. Noticeable improvement in air quality.',
        date: '2024-11-02T10:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae755',
      },
      {
        user: '63d1089e7ee22fa515771f136',
        rate: 4,
        Comment: 'Quiet operation and powerful purification. Satisfied.',
        date: '2024-11-05T08:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae756',
      },
    ],
    sell: 16,
  },
  {
    _id: '64c37c823x03172a9facc088',
    id: 46,
    title: 'Smart Blender: Multi-Speed Control',
    price: 69.99,
    cureted_price: 79.99,
    category: 'tv&appliances',
    ratings: 3.7,
    images: [
      {
        _id: '651d50b7e2b392999df759f5',
        url: 'https://rukminim2.flixcart.com/image/612/612/jxc5a4w0/hand-blender/m/w/p/vidisa-7-speeds-200-watt-electric-hand-original-imafe47z6spyqf4v.jpeg?q=70',
      },
    ],
    seller: 'KitchenGadget',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        type: 'Blender',
        control: 'Smart Multi-Speed',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'KitchenGadget',
        Color: 'White',
        Type: 'Kitchen Appliance',
        ModelName: 'SMART-BLENDER-MULTI',
        ModelID: 'SMART-BLENDER-MULTI',
        type: 'Smart Blender',
        control: 'Smart Multi-Speed',
        Collection: 'smart kitchen gadgets',
        care: 'Dishwasher safe for easy cleanup',
      },
    ],
    stock: 24,
    __v: 0,
    features: [
      'Smart blender with multi-speed control',
      'Blend smoothies, soups, and more with ease',
      'Easy to clean and maintain',
    ],
    numrev: 20,
    rating: 93,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f141',
        rate: 4.5,
        Comment: 'Great blender. Love the smart controls.',
        date: '2025-01-02T10:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae761',
      },
      {
        user: '63d1089e7ee22fa515771f142',
        rate: 4,
        Comment: 'Smooth blending every time. Happy with my purchase.',
        date: '2025-01-05T08:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae762',
      },
    ],
    sell: 19,
  },
  {
    _id: '64c37c823c03172l9facc082',
    id: 40,
    title: 'Samsung 139.7 cm (55 inch) UHD Smart LED TV 55CU7700',
    price: 59999,
    cureted_price: 69999,
    category: 'tv&appliances',
    ratings: 4.8,
    images: [
      {
        _id: '651d50b7e2b392999df759ef',
        url: 'https://rukminim2.flixcart.com/image/416/416/xif0q/television/z/z/s/-original-imagttjpr7vphrks.jpeg?q=70',
      },
    ],
    seller: 'TechGurus',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        type: 'Smart LED TV',
        screenSize: '55 inches',
        resolution: 'Ultra HD',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'TechGurus',
        Color: 'Black',
        Type: 'Television',
        ModelName: 'SMART-LED-TV-55UHD',
        ModelID: 'SMART-LED-TV-55UHD',
        type: 'Smart LED TV',
        screenSize: '55 inches',
        resolution: 'Ultra HD',
        Collection: 'smart home appliances',
        care: 'Handle with care to avoid damage',
      },
    ],
    stock: 20,
    __v: 0,
    features: [
      '55-inch Smart LED TV with Ultra HD resolution',
      'Smart TV features for streaming and connectivity',
      'Enhanced visual and audio experience',
    ],
    numrev: 10,
    rating: 94,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f129',
        rate: 5,
        Comment: 'Outstanding TV. Crystal clear picture quality.',
        date: '2024-09-12T12:55:30.892Z',
        _id: '64e9d16786e6fe3e932ae749',
      },
      {
        user: '63d1089e7ee22fa515771f130',
        rate: 4.5,
        Comment: 'Smart features work seamlessly. Great buy.',
        date: '2024-09-15T11:15:12.892Z',
        _id: '64e9d16786e6fe3e932ae750',
      },
    ],
    sell: 15,
  },
  {
    _id: '64c37c823c03172a9focc083',
    id: 41,
    title:
      'LG 28L Convection Microwave Oven with Intellowave Technology (Silver)',
    price: 12999,
    cureted_price: 14999,
    category: 'tv&appliances',
    ratings: 3.7,
    images: [
      {
        _id: '651d50b7e2b392999df759f0',
        url: 'https://rukminim2.flixcart.com/image/312/312/j9zyd8w0/microwave-new/j/8/z/mc2846sl-lg-original-imaezzen6pew69tg.jpeg?q=70',
      },
    ],
    seller: 'KitchenMasters',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        type: 'Microwave Oven',
        capacity: '1.5 Cubic Feet',
        material: 'Stainless Steel',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'KitchenMasters',
        Color: 'Silver',
        Type: 'Kitchen Appliance',
        ModelName: 'MICROWAVE-OVEN-1.5CUFT',
        ModelID: 'MICROWAVE-OVEN-1.5CUFT',
        type: 'Microwave Oven',
        capacity: '1.5 Cubic Feet',
        material: 'Stainless Steel',
        Collection: 'kitchen essentials',
        care: 'Follow user manual for usage guidelines',
      },
    ],
    stock: 18,
    __v: 0,
    features: [
      'Stainless steel microwave oven with 1.5 cubic feet capacity',
      'Multiple cooking modes and power levels',
      'User-friendly control panel',
    ],
    numrev: 12,
    rating: 92,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f131',
        rate: 4.5,
        Comment: 'Efficient and easy to use. Love the stainless steel design.',
        date: '2024-10-02T10:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae751',
      },
      {
        user: '63d1089e7ee22fa515771f132',
        rate: 4,
        Comment: 'Great value for money. Works perfectly.',
        date: '2024-10-05T08:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae752',
      },
    ],
    sell: 14,
  },
  {
    _id: '64c37c823k03172a9facc089',
    id: 47,
    title: 'Digital Rice Cooker: 10-Cup Capacity',
    price: 49.99,
    cureted_price: 59.99,
    category: 'tv&appliances',
    ratings: 4.6,
    images: [
      {
        _id: '651d50b7e2b392999df759f6',
        url: 'https://rukminim2.flixcart.com/image/416/416/l1mh7rk0/electric-cooker/v/i/w/instamagic-digital-pressure-cooker-with-one-touch-cooking-menus-original-imagd5gfzkyhpmmj.jpeg?q=70',
      },
    ],
    seller: 'KitchenTech',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        type: 'Rice Cooker',
        capacity: '10 Cups',
        technology: 'Digital',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'KitchenTech',
        Color: 'Silver',
        Type: 'Kitchen Appliance',
        ModelName: 'DIGITAL-RICE-COOKER-10CUP',
        ModelID: 'DIGITAL-RICE-COOKER-10CUP',
        type: 'Digital Rice Cooker',
        capacity: '10 Cups',
        technology: 'Digital',
        Collection: 'modern kitchen essentials',
        care: 'Non-stick coating for easy cleaning',
      },
    ],
    stock: 30,
    __v: 0,
    features: [
      'Digital rice cooker with 10-cup capacity',
      'Multiple cooking modes for versatile use',
      'Keep warm function for fresh, ready-to-serve rice',
    ],
    numrev: 22,
    rating: 91,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f143',
        rate: 4.5,
        Comment: 'Excellent rice cooker. Cooks perfect rice every time.',
        date: '2025-02-02T10:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae763',
      },
      {
        user: '63d1089e7ee22fa515771f144',
        rate: 4,
        Comment:
          'Digital controls make it easy to use. Happy with the purchase.',
        date: '2025-02-05T08:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae764',
      },
    ],
    sell: 25,
  },
  {
    _id: '64c37c823c03172a9facc097',
    id: 55,
    title: 'Dell 27" IPS LED Monitor - UltraSharp U2719D',
    price: 34999,
    cureted_price: 39999,
    category: 'tv&appliances',
    ratings: 4.8,
    images: [
      {
        _id: '651d50b7e2b392999df759fe',
        url: 'https://rukminim2.flixcart.com/image/312/312/kmp7ngw0/monitor/j/z/h/s2721hn-27-py0df-dell-original-imagfjphuywuh2a7.jpeg?q=70',
      },
    ],
    seller: 'TechWorld',
    replacement: '30 Days Replacement Policy',
    specificaton: [
      {
        type: 'IPS LED',
        size: '27 inches',
        resolution: '2560 x 1440 pixels',
        refreshRate: '60Hz',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Dell',
        Color: 'Black',
        Type: 'Monitor',
        ModelName: 'UltraSharp U2719D',
        ModelID: 'ULTRASHARP-U2719D',
        displayType: 'IPS LED',
        size: '27 inches',
        resolution: '2560 x 1440 pixels',
        refreshRate: '60Hz',
        Collection: 'professional-grade monitors',
        care: 'Adjustable stand for ergonomic viewing',
      },
    ],
    stock: 20,
    __v: 0,
    features: [
      '27-inch IPS LED display for vibrant and accurate colors',
      '2560 x 1440 pixel resolution for sharp visuals',
      'Ultra-thin bezels for immersive viewing',
      'Adjustable stand for personalized and ergonomic positioning',
    ],
    numrev: 15,
    rating: 95,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f159',
        rate: 4.9,
        Comment: 'Excellent color accuracy. Perfect for professional work.',
        date: '2025-08-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae779',
      },
      {
        user: '63d1089e7ee22fa515771f160',
        rate: 4.8,
        Comment: 'Great build quality and sharp display.',
        date: '2025-08-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae780',
      },
    ],
    sell: 18,
  },
  {
    _id: '64c37c823c03172a9facc098',
    id: 56,
    title: 'HP Pavilion x360 14" 2-in-1 Laptop',
    price: 699.99,
    cureted_price: 799.99,
    category: 'tv&appliances',
    ratings: 4.5,
    images: [
      {
        _id: '651d50b7e2b392999df759ff',
        url: 'https://rukminim2.flixcart.com/image/312/312/xif0q/computer/q/x/b/-original-imagwzwbhgrr88xg.jpeg?q=70',
      },
    ],
    seller: 'HP Official Store',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        type: 'Intel Core i5',
        ram: '8GB DDR4',
        storage: '256GB SSD',
        display: '14-inch Full HD IPS Touchscreen',
        operatingSystem: 'Windows 10',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'HP',
        Color: 'Natural Silver',
        Type: '2-in-1 Laptop',
        ModelName: 'Pavilion x360',
        ModelID: 'PAVILION-X360',
        processor: 'Intel Core i5',
        ram: '8GB DDR4',
        storage: '256GB SSD',
        display: '14-inch Full HD IPS Touchscreen',
        operatingSystem: 'Windows 10',
        Collection: 'versatile 2-in-1 laptops',
        care: 'Convertible design for flexibility',
      },
    ],
    stock: 15,
    __v: 0,
    features: [
      'Powerful Intel Core i5 processor for smooth multitasking',
      'Versatile 2-in-1 design for flexibility',
      'Full HD IPS touchscreen for immersive visuals',
      '256GB SSD for fast storage access',
    ],
    numrev: 10,
    rating: 92,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f161',
        rate: 4.6,
        Comment: 'Excellent build quality and performance.',
        date: '2025-09-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae781',
      },
      {
        user: '63d1089e7ee22fa515771f162',
        rate: 4.5,
        Comment: 'Responsive touchscreen and fast SSD.',
        date: '2025-09-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae782',
      },
    ],
    sell: 12,
  },
  {
    _id: '64c37c823c03172a9facc100',
    id: 58,
    title: 'Logitech MK850 Performance Wireless Keyboard and Mouse Combo',
    price: 99.99,
    cureted_price: 129.99,
    category: 'tv&appliances',
    ratings: 4.5,
    images: [
      {
        _id: '651d50b7e2b392999df75a01',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/keyboard/laptop-keyboard/y/5/c/kb-160-wired-keyboard-mouse-combo-lenovo-original-imagutug5zhphhjy.jpeg?q=70',
      },
    ],
    seller: 'TechEssentials',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        type: 'Wireless (Bluetooth/RF)',
        keyboardLayout: 'Full-size',
        mouseDPI: '1000',
        batteryLife: 'Up to 3 years for keyboard, 2 years for mouse',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Logitech',
        Color: 'Black',
        Type: 'Wireless Keyboard and Mouse Combo',
        ModelName: 'MK850',
        ModelID: 'MK850',
        connectionType: 'Wireless (Bluetooth/RF)',
        keyboardLayout: 'Full-size',
        mouseDPI: '1000',
        batteryLife: 'Up to 3 years for keyboard, 2 years for mouse',
        Collection: 'performance accessories',
        care: 'Ergonomic design for comfortable use',
      },
    ],
    stock: 25,
    __v: 0,
    features: [
      'Wireless keyboard and mouse combo for flexible use',
      'Full-size keyboard layout for comfortable typing',
      '1000 DPI mouse for precise control',
      'Long battery life for extended usage',
    ],
    numrev: 12,
    rating: 90,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f165',
        rate: 4.5,
        Comment: 'Great combo for both work and gaming.',
        date: '2025-11-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae785',
      },
      {
        user: '63d1089e7ee22fa515771f166',
        rate: 4.6,
        Comment: 'Excellent battery life. Comfortable to use.',
        date: '2025-11-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae786',
      },
    ],
    sell: 20,
  },
  {
    _id: '64c37c823c03172a9facc101',
    id: 59,
    title: 'Sony WH-1000XM4 Wireless Bluetooth Noise-Canceling Headphones',
    price: 279.99,
    cureted_price: 349.99,
    category: 'tv&appliances',
    ratings: 4.8,
    images: [
      {
        _id: '651d50b7e2b392999df75a02',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/headphone/y/o/i/-original-imagz2d8cnqggynk.jpeg?q=70',
      },
    ],
    seller: 'AudioParadise',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        type: 'Wireless Bluetooth',
        noiseCancellation: 'Yes',
        batteryLife: 'Up to 30 hours',
        driverSize: '40mm',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Sony',
        Color: 'Black',
        Type: 'Over-Ear Headphones',
        ModelName: 'WH-1000XM4',
        ModelID: 'WH-1000XM4',
        connectionType: 'Wireless Bluetooth',
        noiseCancellation: 'Yes',
        batteryLife: 'Up to 30 hours',
        driverSize: '40mm',
        Collection: 'premium audio devices',
        care: 'Foldable design for portability',
      },
    ],
    stock: 30,
    __v: 0,
    features: [
      'Wireless Bluetooth headphones with premium sound quality',
      'Advanced noise cancellation for immersive experience',
      'Up to 30 hours of battery life for long listening sessions',
      '40mm drivers for powerful and clear audio',
    ],
    numrev: 25,
    rating: 96,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f167',
        rate: 4.9,
        Comment: 'Outstanding noise cancellation and comfort.',
        date: '2025-12-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae787',
      },
      {
        user: '63d1089e7ee22fa515771f168',
        rate: 4.8,
        Comment: 'Long-lasting battery and premium build.',
        date: '2025-12-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae788',
      },
    ],
    sell: 28,
  },

  // TwoWheelers
  {
    _id: '64c37c823c03172a9facc114',
    id: 72,
    title:
      'Hero HF Deluxe (Non i3S with Self Start) Booking for Ex-Showroom Price  (Black Nexus Blue)',
    price: 69999,
    cureted_price: 79999,
    category: 'twowheelers',
    ratings: 4.7,
    images: [
      {
        _id: '651d50b7e2b392999df75a15',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/electric-bike-scooter/y/k/q/-original-imagkrvpggy8gtft.jpeg?q=70',
      },
    ],
    seller: 'SpeedMasters',
    replacement: '7 Days Replacement Policy',
    specificaton: [
      {
        type: 'Parallel Twin',
        mileage: '25 km/l',
        maxPower: '47.5 bhp @ 8,500 rpm',
        maxTorque: '43.2 Nm @ 7,000 rpm',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Honda',
        Color: 'Red',
        Type: 'Sports Bike',
        ModelName: 'CB500F',
        ModelID: 'HONDA-CB500F',
        engineType: 'Parallel Twin',
        mileage: '25 km/l',
        maxPower: '47.5 bhp @ 8,500 rpm',
        maxTorque: '43.2 Nm @ 7,000 rpm',
        Collection: 'Performance Series',
        care: 'Regular maintenance for optimal performance',
      },
    ],
    stock: 10,
    __v: 0,
    features: [
      'Honda CB500F Sports Bike',
      'Powerful Parallel Twin engine',
      'Impressive mileage of 25 km/l',
      'Sporty design and performance-oriented',
    ],
    numrev: 12,
    rating: 94,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f193',
        rate: 4.8,
        Comment: 'Love the sleek design and powerful performance.',
        date: '2027-01-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7a7',
      },
      {
        user: '63d1089e7ee22fa515771f194',
        rate: 4.6,
        Comment: 'Great mileage for a sports bike. Smooth riding experience.',
        date: '2027-01-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7a8',
      },
    ],
    sell: 8,
  },
  {
    _id: '64c37c823c03172a9facc115',
    id: 73,
    title: 'Royal Enfield Classic 350 Cruiser',
    price: 5499.99,
    cureted_price: 5999.99,
    category: 'twowheelers',
    ratings: 4.5,
    images: [
      {
        _id: '651d50b7e2b392999df75a16',
        url: 'https://rukminim2.flixcart.com/image/612/612/kzrbiq80/car-hanging-ornament/i/h/d/1-classic-350-scale-model-ash-royal-enfield-original-imagbp95u65dyhmk.jpeg?q=70',
      },
    ],
    seller: 'RideKings',
    replacement: '7 Days Replacement Policy',
    specificaton: [
      {
        type: 'Single Cylinder',
        mileage: '35 km/l',
        maxPower: '20.07 bhp @ 5,250 rpm',
        maxTorque: '28 Nm @ 4,000 rpm',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Royal Enfield',
        Color: 'Black',
        Type: 'Cruiser',
        ModelName: 'Classic 350',
        ModelID: 'ROYALENFIELD-CLASSIC350',
        engineType: 'Single Cylinder',
        mileage: '35 km/l',
        maxPower: '20.07 bhp @ 5,250 rpm',
        maxTorque: '28 Nm @ 4,000 rpm',
        Collection: 'Classic Series',
        care: 'Regular servicing for a smooth ride',
      },
    ],
    stock: 15,
    __v: 0,
    features: [
      'Royal Enfield Classic 350 Cruiser',
      'Classic design with modern features',
      'Impressive mileage of 35 km/l',
      'Ideal for long rides and cruising',
    ],
    numrev: 10,
    rating: 88,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f195',
        rate: 4.4,
        Comment: 'Love the classic look and powerful performance.',
        date: '2027-02-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7a9',
      },
      {
        user: '63d1089e7ee22fa515771f196',
        rate: 4.6,
        Comment: 'Smooth ride and great fuel efficiency for a cruiser.',
        date: '2027-02-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7aa',
      },
    ],
    sell: 12,
  },
  {
    _id: '64c37c823c03172a9facc116',
    id: 74,
    title: 'TVS Scooty Zest 110 Scooter',
    price: 3799.99,
    cureted_price: 4299.99,
    category: 'twowheelers',
    ratings: 4.8,
    images: [
      {
        _id: '651d50b7e2b392999df75a17',
        url: 'https://rukminim2.flixcart.com/image/312/312/xif0q/electric-bike-scooter/z/v/c/-original-imagg9xgfmsn6rhd.jpeg?q=70',
      },
    ],
    seller: 'SheRides',
    replacement: '7 Days Replacement Policy',
    specificaton: [
      {
        type: 'Single Cylinder',
        mileage: '55 km/l',
        maxPower: '7.81 bhp @ 7,500 rpm',
        maxTorque: '8.8 Nm @ 5,500 rpm',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'TVS',
        Color: 'Purple',
        Type: 'Scooter',
        ModelName: 'Scooty Zest 110',
        ModelID: 'TVS-SCOOTY-ZEST110',
        engineType: 'Single Cylinder',
        mileage: '55 km/l',
        maxPower: '7.81 bhp @ 7,500 rpm',
        maxTorque: '8.8 Nm @ 5,500 rpm',
        Collection: 'Scooty Series',
        care: 'Perfect for urban commuting with a stylish and lightweight design',
      },
    ],
    stock: 18,
    __v: 0,
    features: [
      'TVS Scooty Zest 110 Scooter',
      'Lightweight and easy to handle',
      'High fuel efficiency of 55 km/l',
      'Designed for the modern urban woman',
    ],
    numrev: 15,
    rating: 96,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f197',
        rate: 4.9,
        Comment:
          'Excellent scooter for city commuting. Stylish and fuel-efficient.',
        date: '2027-03-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7ab',
      },
      {
        user: '63d1089e7ee22fa515771f198',
        rate: 4.7,
        Comment: 'Love the purple color and smooth ride.',
        date: '2027-03-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7ac',
      },
    ],
    sell: 14,
  },
  {
    _id: '64c37c823c03172a9facc117',
    id: 75,
    title: 'Bajaj Pulsar NS200 Street Fighter',
    price: 489999,
    cureted_price: 549999,
    category: 'twowheelers',
    ratings: 4.6,
    images: [
      {
        _id: '651d50b7e2b392999df75a18',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/electric-bike-scooter/u/j/a/-original-imagg9u6wargyj2k.jpeg?q=70',
      },
    ],
    seller: 'SpeedDemons',
    replacement: '7 Days Replacement Policy',
    specificaton: [
      {
        type: 'Single Cylinder, 4-Stroke, SOHC, 4-Valve',
        mileage: '35 km/l',
        maxPower: '24.5 bhp @ 9,750 rpm',
        maxTorque: '18.5 Nm @ 8,000 rpm',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Bajaj',
        Color: 'Yellow',
        Type: 'Street Fighter',
        ModelName: 'Pulsar NS200',
        ModelID: 'BAJAJ-PULSAR-NS200',
        engineType: 'Single Cylinder, 4-Stroke, SOHC, 4-Valve',
        mileage: '35 km/l',
        maxPower: '24.5 bhp @ 9,750 rpm',
        maxTorque: '18.5 Nm @ 8,000 rpm',
        Collection: 'Street Fighter Series',
        care: 'Ideal for riders who enjoy a powerful and agile street bike',
      },
    ],
    stock: 12,
    __v: 0,
    features: [
      'Bajaj Pulsar NS200 Street Fighter',
      'Powerful and responsive performance',
      'Eye-catching yellow color',
      'Designed for a thrilling street riding experience',
    ],
    numrev: 8,
    rating: 91,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f199',
        rate: 4.7,
        Comment: 'Great bike for city rides. Love the aggressive look.',
        date: '2027-04-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7ad',
      },
      {
        user: '63d1089e7ee22fa515771f200',
        rate: 4.5,
        Comment: 'Smooth handling and powerful acceleration.',
        date: '2027-04-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7ae',
      },
    ],
    sell: 10,
  },
  {
    _id: '64c37c823c03172a9facc118',
    id: 76,
    title:
      'Hero Pleasure+ (VX) Booking for Ex-Showroom Price  (Midnight Black)',
    price: 5299.99,
    cureted_price: 5799.99,
    category: 'twowheelers',
    ratings: 4.7,
    images: [
      {
        _id: '651d50b7e2b392999df75a19',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/scooter-moped/v/n/m/-original-imagty4pz6nzxqeh.jpeg?q=70',
      },
    ],
    seller: 'EasyRides',
    replacement: '7 Days Replacement Policy',
    specificaton: [
      {
        type: 'Single Cylinder, 4-Stroke, SI Engine',
        mileage: '60 km/l',
        maxPower: '7.68 bhp @ 8,000 rpm',
        maxTorque: '8.79 Nm @ 5,250 rpm',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Honda',
        Color: 'Blue',
        Type: 'Scooter',
        ModelName: 'Activa 6G',
        ModelID: 'HONDA-ACTIVA-6G',
        engineType: 'Single Cylinder, 4-Stroke, SI Engine',
        mileage: '60 km/l',
        maxPower: '7.68 bhp @ 8,000 rpm',
        maxTorque: '8.79 Nm @ 5,250 rpm',
        Collection: 'Activa Series',
        care: 'Perfect for daily commuting with exceptional fuel efficiency',
      },
    ],
    stock: 15,
    __v: 0,
    features: [
      'Honda Activa 6G Scooter',
      'Efficient and reliable daily commuter',
      'Impressive mileage of 60 km/l',
      'Easy to handle with a comfortable riding experience',
    ],
    numrev: 12,
    rating: 94,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f201',
        rate: 4.8,
        Comment: 'Smooth ride and excellent fuel efficiency.',
        date: '2027-05-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7af',
      },
      {
        user: '63d1089e7ee22fa515771f202',
        rate: 4.6,
        Comment: 'Love the blue color. Perfect for city traffic.',
        date: '2027-05-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7b0',
      },
    ],
    sell: 13,
  },
  {
    _id: '64c37c823c03172a9facc119',
    id: 77,
    title:
      'Hero Maestro Edge 125 (Drum) Booking for Ex-Showroom Price  (Candy Blazing Red)',
    price: 4699.99,
    cureted_price: 5199.99,
    category: 'twowheelers',
    ratings: 4.5,
    images: [
      {
        _id: '651d50b7e2b392999df75a20',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/electric-bike-scooter/n/j/q/-original-imagg9x2gbuveask.jpeg?q=70',
      },
    ],
    seller: 'ScootNJoy',
    replacement: '7 Days Replacement Policy',
    specificaton: [
      {
        type: 'Single Cylinder, 4-Stroke, CVTi, BS6 Compliant',
        mileage: '50 km/l',
        maxPower: '7.3 bhp @ 7,000 rpm',
        maxTorque: '8.4 Nm @ 5,500 rpm',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'TVS',
        Color: 'Burgundy',
        Type: 'Scooter',
        ModelName: 'Jupiter Classic',
        ModelID: 'TVS-JUPITER-CLASSIC',
        engineType: 'Single Cylinder, 4-Stroke, CVTi, BS6 Compliant',
        mileage: '50 km/l',
        maxPower: '7.3 bhp @ 7,000 rpm',
        maxTorque: '8.4 Nm @ 5,500 rpm',
        Collection: 'Jupiter Series',
        care: 'Classic design with modern features for a comfortable ride',
      },
    ],
    stock: 10,
    __v: 0,
    features: [
      'TVS Jupiter Classic Scooter',
      'Classic design with modern features',
      'Impressive mileage of 50 km/l',
      'Comfortable and spacious seat',
    ],
    numrev: 10,
    rating: 89,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f203',
        rate: 4.5,
        Comment: 'Great scooter with a classic touch. Smooth ride.',
        date: '2027-06-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7b1',
      },
      {
        user: '63d1089e7ee22fa515771f204',
        rate: 4.3,
        Comment: 'Burgundy color adds elegance. Excellent fuel efficiency.',
        date: '2027-06-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7b2',
      },
    ],
    sell: 8,
  },
  {
    _id: '64c37c823c03172a9facc120',
    id: 78,
    title:
      'Hero DESTINI PRIME Booking for Ex-Showroom Price  (Pearl Silver White)',
    price: 589999,
    cureted_price: 639999,
    category: 'twowheelers',
    ratings: 4.8,
    images: [
      {
        _id: '651d50b7e2b392999df75a21',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/scooter-moped/s/i/k/2023-drum-destini-prime-booking-for-ex-showroom-price-pan-india-original-imagtqap9tgrva3z.jpeg?q=70',
      },
    ],
    seller: 'RideInStyle',
    replacement: '7 Days Replacement Policy',
    specificaton: [
      {
        type: '4-Stroke, 1-Cylinder, Air-cooled',
        mileage: '55 km/l',
        maxPower: '8.6 bhp @ 6,750 rpm',
        maxTorque: '10 Nm @ 5,500 rpm',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Suzuki',
        Color: 'Silver',
        Type: 'Scooter',
        ModelName: 'Access 125',
        ModelID: 'SUZUKI-ACCESS-125',
        engineType: '4-Stroke, 1-Cylinder, Air-cooled',
        mileage: '55 km/l',
        maxPower: '8.6 bhp @ 6,750 rpm',
        maxTorque: '10 Nm @ 5,500 rpm',
        Collection: 'Access Series',
        care: 'Stylish scooter with powerful performance and advanced features',
      },
    ],
    stock: 18,
    __v: 0,
    features: [
      'Suzuki Access 125 Scooter',
      'Stylish design with powerful performance',
      'Impressive mileage of 55 km/l',
      'Advanced features for a comfortable ride',
    ],
    numrev: 15,
    rating: 96,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f205',
        rate: 4.9,
        Comment:
          'Love the silver color. Smooth ride with great fuel efficiency.',
        date: '2027-07-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7b3',
      },
      {
        user: '63d1089e7ee22fa515771f206',
        rate: 4.7,
        Comment: 'Suzuki Access 125 is the best choice. Excellent scooter.',
        date: '2027-07-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7b4',
      },
    ],
    sell: 13,
  },
  {
    _id: '64c37c823c03172a9facc121',
    id: 79,
    title: 'Hero Karizma XMR Booking for Ex-Showroom Price  (Iconic Yellow)',
    price: 7899.99,
    cureted_price: 8399.99,
    category: 'twowheelers',
    ratings: 4.9,
    images: [
      {
        _id: '651d50b7e2b392999df75a22',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/motorcycle/7/k/w/karizma-xmr-self-kick-karizma-xmr-disc-210-pan-india-hero-original-imagtbd8eecf6chx.jpeg?q=70',
      },
    ],
    seller: 'SpeedMasters',
    replacement: '7 Days Replacement Policy',
    specificaton: [
      {
        etype: 'Single Cylinder, 4-Stroke, SOHC, 2-Valve',
        mileage: '50 km/l',
        maxPower: '12.2 bhp @ 7,250 rpm',
        maxTorque: '13.6 Nm @ 5,500 rpm',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'Yamaha',
        Color: 'Red',
        Type: 'Motorcycle',
        ModelName: 'FZS-Fi V3',
        ModelID: 'YAMAHA-FZS-FI-V3',
        engineType: 'Single Cylinder, 4-Stroke, SOHC, 2-Valve',
        mileage: '50 km/l',
        maxPower: '12.2 bhp @ 7,250 rpm',
        maxTorque: '13.6 Nm @ 5,500 rpm',
        Collection: 'FZ Series',
        care: 'Powerful and stylish motorcycle for a thrilling riding experience',
      },
    ],
    stock: 12,
    __v: 0,
    features: [
      'Yamaha FZS-Fi V3 Motorcycle',
      'Powerful engine with advanced technology',
      'Impressive mileage of 50 km/l',
      'Stylish design for a sporty look',
    ],
    numrev: 18,
    rating: 98,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f207',
        rate: 5,
        Comment: 'Red color looks amazing. Smooth ride and great performance.',
        date: '2027-08-01T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae7b5',
      },
      {
        user: '63d1089e7ee22fa515771f208',
        rate: 4.8,
        Comment: 'Yamaha FZS-Fi V3 is a beast on the road. Loving it!',
        date: '2027-08-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae7b6',
      },
    ],
    sell: 10,
  },
]

export default Data
