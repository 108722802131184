const Books = [
  {
    _id: '64c37c823c03172a9facc073',
    id: 31,
    title: 'You are the Best Wife',
    price: 249,
    cureted_price: 350,
    category: 'books',
    ratings: 4.5,
    images: [
      {
        _id: '651d50b7e2b392999df759e6',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/book/5/p/j/you-are-the-best-wife-original-imagn5nzxqxq2nah.jpeg?q=70',
      },
    ],
    seller: 'SrishtiPD',
    replacement: '10 Days Replacement Policy',
    specificaton: [
      {
        author: 'Ajay K Pandey',
        genre: 'Fiction',
        format: 'paperback',
        type: 'Fiction book',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'TamilPusthakam',
        Color: 'blue',
        Type: 'Fiction',
        ModelName: 'Best_Wife',
        ModelID: 'Best_Wife',
        author: 'Ajay K Pandey',
        genre: 'Fiction',
        format: 'paperback',
        Collection: 'love',
        care: 'Handle with care to maintain book condition',
      },
    ],
    stock: 25,
    __v: 0,
    features: [
      'Practical guide to mindfulness and meditation',
      'Authored by a meditation expert',
      'Available in paperback format',
    ],
    numrev: 5,
    rating: 89,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f111',
        rate: 4.5,
        Comment:
          "This book has inspired me alot... Ajay sir and Bhavna mam's love for each other is immortal... it will always remain in the heart of all the readers... 💞💞",
        date: '2024-05-02T12:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae731',
      },
      {
        user: '63d1089e7ee22fa515771f112',
        rate: 4.8,
        Comment: 'heart touching book. imotinal Ending you may cry.',
        date: '2024-05-05T09:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae732',
      },
    ],
    sell: 18,
  },
  {
    _id: '64c37c823c03172a9facc062',
    id: 20,
    title:
      'The Memoirs of Sherlock Holmes  (English, Paperback, Doyle Arthur Conan)',
    price: 203,
    cureted_price: 229,
    category: 'books',
    ratings: 4.7,
    images: [
      {
        _id: '651d50b7e2b392999df759db',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/book/m/p/g/-original-imagnhhauzvm4kjf.jpeg?q=70',
      },
    ],
    seller: 'BookHaven',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        author: 'Emma L. Archer',
        genre: 'Mystery',
        format: 'Paperback',
        type: 'Mystery',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'BookHaven',
        Color: 'Assorted',
        Type: 'Mystery Novel',
        ModelName: 'SILENT-OBSERVER-NOVEL',
        ModelID: 'SILENT-OBSERVER-NOVEL',
        author: 'Emma L. Archer',
        genre: 'Mystery',
        format: 'Paperback',
        Collection: 'bestseller novels',
        care: 'Handle with care to maintain book condition',
      },
    ],
    stock: 30,
    __v: 0,
    features: [
      'Engaging mystery storyline',
      'Well-written by renowned author',
      'Available in paperback format',
    ],
    numrev: 15,
    rating: 93,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f89',
        rate: 5,
        Comment: "Couldn't put it down! Highly recommended.",
        date: '2024-01-05T12:55:30.892Z',
        _id: '64e9d16786e6fe3e932ae709',
      },
      {
        user: '63d1089e7ee22fa515771f90',
        rate: 4.5,
        Comment: 'Captivating mystery. A must-read.',
        date: '2024-01-08T11:15:12.892Z',
        _id: '64e9d16786e6fe3e932ae710',
      },
    ],
    sell: 25,
  },
  {
    _id: '64c37c823c03172a9facc063',
    id: 21,
    title: 'Beyond the Horizon: Sci-Fi Adventure',
    price: 473,
    cureted_price: 2499,
    category: 'books',
    ratings: 4.5,
    images: [
      {
        _id: '651d50b7e2b392999df759dc',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/book/k/b/p/beyond-the-blue-horizon-original-imagjw996pfwsyqu.jpeg?q=70',
      },
    ],
    seller: 'GalacticBooks',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        author: 'Jason A. Starlight',
        genre: 'Sci-Fi',
        format: 'Hardcover',
        type: 'Fiction book',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'GalacticBooks',
        Color: 'Assorted',
        Type: 'Sci-Fi Adventure',
        ModelName: 'BEYOND-HORIZON-SCI-FI',
        ModelID: 'BEYOND-HORIZON-SCI-FI',
        author: 'Jason A. Starlight',
        genre: 'Sci-Fi',
        format: 'Hardcover',
        Collection: 'bestseller novels',
        care: 'Keep away from direct sunlight',
      },
    ],
    stock: 22,
    __v: 0,
    features: [
      'Exciting sci-fi adventure',
      'Written by acclaimed author',
      'Available in hardcover format',
    ],
    numrev: 11,
    rating: 92,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f91',
        rate: 5,
        Comment: 'Mind-blowing adventure! Loved every page.',
        date: '2024-01-12T10:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae711',
      },
      {
        user: '63d1089e7ee22fa515771f92',
        rate: 4.5,
        Comment: 'Engaging storyline. Must-read for sci-fi fans.',
        date: '2024-01-15T08:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae712',
      },
    ],
    sell: 18,
  },
  {
    _id: '64c37c823c03172a9facc071',
    id: 29,
    title: 'Wise & Otherwise',
    price: 349,
    cureted_price: 500,
    category: 'books',
    ratings: 4.6,
    images: [
      {
        _id: '651d50b7e2b392999df759e4',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/book/u/r/i/wise-otherwise-original-imagu7sasyjqxhyf.jpeg?q=70',
      },
    ],
    seller: 'BookNook',
    replacement: '10 Days Replacement Policy',
    specificaton: [
      {
        author: 'Sudha Murthy',
        genre: 'Non-Fiction',
        format: 'Paperback',
        type: 'Non-Fiction book',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'BookNook',
        Color: 'Assorted',
        Type: 'Non-Fiction',
        ModelName: 'MILK-STOPPED',
        ModelID: 'MILK-STOPPED',
        author: 'Sudha Murthy',
        genre: 'Non-Fiction',
        format: 'Paperback',
        Collection: 'Inspirational Stories for Adults',
        care: 'Handle with care to maintain book condition',
      },
    ],
    stock: 15,
    __v: 0,
    features: [
      'Compelling non-fiction narrative',
      'Authored by Sudha Murthy',
      'Available in paperback format',
    ],
    numrev: 9,
    rating: 88,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f107',
        rate: 4.7,
        Comment:
          "A thought-provoking book. Sudha Murthy's writing is captivating.",
        date: '2024-04-10T14:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae727',
      },
      {
        user: '63d1089e7ee22fa515771f108',
        rate: 4.5,
        Comment: "Excellent insights into life's experiences.",
        date: '2024-04-12T11:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae728',
      },
    ],
    sell: 8,
  },
  {
    _id: '64c37c823c03172a9facc064',
    id: 22,
    title: 'The Enchanted Garden: Fantasy Tale',
    price: 486,
    cureted_price: 1539,
    category: 'books',
    ratings: 4.8,
    images: [
      {
        _id: '651d50b7e2b392999df759dd',
        url: 'https://rukminim2.flixcart.com/image/612/612/kg8avm80-0/poster/k/m/1/small-azart15816104pos-fr-l-01-gd-fantasy-garden-with-tree-original-imafwgeeguybmvjg.jpeg?q=70',
      },
    ],
    seller: 'MysticalPress',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        author: 'Isabella Moonlight',
        genre: 'Fantasy',
        format: 'Paperback',
        type: 'Fantasy book',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'MysticalPress',
        Color: 'Assorted',
        Type: 'Fantasy Tale',
        ModelName: 'ENCHANTED-GARDEN-FANTASY',
        ModelID: 'ENCHANTED-GARDEN-FANTASY',
        author: 'Isabella Moonlight',
        genre: 'Fantasy',
        format: 'Paperback',
        Collection: 'bestseller novels',
        care: 'Handle with care to maintain book condition',
      },
    ],
    stock: 28,
    __v: 0,
    features: [
      'Whimsical fantasy tale',
      'Crafted by talented author',
      'Available in paperback format',
    ],
    numrev: 13,
    rating: 95,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f93',
        rate: 5,
        Comment: 'Magical journey! Beautifully written.',
        date: '2024-01-22T12:55:30.892Z',
        _id: '64e9d16786e6fe3e932ae713',
      },
      {
        user: '63d1089e7ee22fa515771f94',
        rate: 4.5,
        Comment: 'Captivating world. Highly recommended.',
        date: '2024-01-25T11:15:12.892Z',
        _id: '64e9d16786e6fe3e932ae714',
      },
    ],
    sell: 24,
  },
  {
    _id: '64c37c823c03172a9facc065',
    id: 23,
    title: 'CodeCraft: Mastering Web Development',
    price: 330,
    cureted_price: 360,
    category: 'books',
    ratings: 2.9,
    images: [
      {
        _id: '651d50b7e2b392999df759de',
        url: 'https://rukminim2.flixcart.com/image/612/612/book/7/9/9/web-designing-and-development-training-guide-original-imae9dfdjadxpcfk.jpeg?q=70',
      },
    ],
    seller: 'TechBooks',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        author: 'Alex Coder',
        genre: 'Technology',
        format: 'Hardcover',
        type: 'Technology book',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'TechBooks',
        Color: 'Assorted',
        Type: 'Web Development',
        ModelName: 'CODECRAFT-WEB-DEVELOPMENT',
        ModelID: 'CODECRAFT-WEB-DEVELOPMENT',
        author: 'Alex Coder',
        genre: 'Technology',
        format: 'Hardcover',
        Collection: 'coding and development',
        care: 'Store in a cool, dry place',
      },
    ],
    stock: 20,
    __v: 0,
    features: [
      'Comprehensive guide to web development',
      'Written by expert coder',
      'High-quality hardcover format',
    ],
    numrev: 18,
    rating: 98,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f95',
        rate: 5,
        Comment: 'Invaluable resource for developers. Must-have!',
        date: '2024-02-01T10:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae715',
      },
      {
        user: '63d1089e7ee22fa515771f96',
        rate: 4.5,
        Comment: 'Clear explanations. Great for learning.',
        date: '2024-02-05T08:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae716',
      },
    ],
    sell: 15,
  },
  {
    _id: '64c37c823c03172a9facc066',
    id: 24,
    title: 'The Art of Mindfulness: A Guide to Meditation',
    price: 720,
    cureted_price: 750,
    category: 'books',
    ratings: 3.6,
    images: [
      {
        _id: '651d50b7e2b392999df759df',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/book/y/3/2/a-mindful-year-original-imagr75juhzwqnt8.jpeg?q=70',
      },
    ],
    seller: 'ZenReads',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        author: 'Elena Tranquil',
        genre: 'Self-Help',
        format: 'Paperback',
        type: 'Self-Help book',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'ZenReads',
        Color: 'Assorted',
        Type: 'Mindfulness Meditation Guide',
        ModelName: 'ART-OF-MINDFULNESS',
        ModelID: 'ART-OF-MINDFULNESS',
        author: 'Elena Tranquil',
        genre: 'Self-Help',
        format: 'Paperback',
        Collection: 'mindfulness and meditation',
        care: 'Handle with care to maintain book condition',
      },
    ],
    stock: 25,
    __v: 0,
    features: [
      'Practical guide to mindfulness and meditation',
      'Authored by a meditation expert',
      'Available in paperback format',
    ],
    numrev: 9,
    rating: 90,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f97',
        rate: 4.5,
        Comment: 'Helpful guide for beginners. Enjoyed it.',
        date: '2024-02-12T12:55:30.892Z',
        _id: '64e9d16786e6fe3e932ae717',
      },
      {
        user: '63d1089e7ee22fa515771f98',
        rate: 4,
        Comment: 'Clear instructions. Great introduction to mindfulness.',
        date: '2024-02-15T11:15:12.892Z',
        _id: '64e9d16786e6fe3e932ae718',
      },
    ],
    sell: 20,
  },
  {
    _id: '64c37c823c03172a9facc067',
    id: 25,
    title: 'The Tunguska Mystery  (English, Paperback, Rubtsov Vladimir)',
    price: 2199,
    cureted_price: 3499,
    category: 'books',
    ratings: 2.7,
    images: [
      {
        _id: '651d50b7e2b392999df759e0',
        url: 'https://rukminim2.flixcart.com/image/612/612/kjd6nww0-0/book/q/9/t/the-tunguska-mystery-original-imafyxjs4dhnzb7z.jpeg?q=70',
      },
    ],
    seller: 'CosmicBooks',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        author: 'Dr. Alan Quantum',
        genre: 'Science',
        format: 'Hardcover',
        type: 'Science book',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'CosmicBooks',
        Color: 'Assorted',
        Type: 'Physics Exploration',
        ModelName: 'QUANTUM-PARADOX',
        ModelID: 'QUANTUM-PARADOX',
        author: 'Dr. Alan Quantum',
        genre: 'Science',
        format: 'Hardcover',
        Collection: 'science and physics',
        care: 'Handle with care to maintain book condition',
      },
    ],
    stock: 18,
    __v: 0,
    features: [
      'In-depth exploration of quantum physics',
      'Authored by renowned physicist',
      'High-quality hardcover format',
    ],
    numrev: 12,
    rating: 94,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f99',
        rate: 5,
        Comment: 'Fascinating read! Mind-bending concepts explained well.',
        date: '2024-02-22T10:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae719',
      },
      {
        user: '63d1089e7ee22fa515771f100',
        rate: 4.5,
        Comment: 'Complex topics made accessible. Highly recommended.',
        date: '2024-02-25T08:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae720',
      },
    ],
    sell: 14,
  },
  {
    _id: '64c37c823c03172a9facc068',
    id: 26,
    title: 'Littlebuds 100 cm An Art Of Cooking Removable Sticker  (Pack of 1)',
    price: 299,
    cureted_price: 699,
    category: 'books',
    ratings: 4.8,
    images: [
      {
        _id: '651d50b7e2b392999df759e1',
        url: 'https://rukminim2.flixcart.com/image/612/612/l3j2cnk0/book/v/s/a/-original-imagemu5zjyzkt9u.jpeg?q=70',
      },
    ],
    seller: 'EpicureanPress',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        author: 'Chef Isabella Gourmet',
        genre: 'Cooking',
        format: 'Hardcover',
        type: 'Cooking book',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'EpicureanPress',
        Color: 'Assorted',
        Type: 'Cooking Masterclass',
        ModelName: 'CULINARY-MASTERCHEF',
        ModelID: 'CULINARY-MASTERCHEF',
        author: 'Chef Isabella Gourmet',
        genre: 'Cooking',
        format: 'Hardcover',
        Collection: 'culinary arts',
        care: 'Handle with care to maintain book condition',
      },
    ],
    stock: 20,
    __v: 0,
    features: [
      'Comprehensive guide to culinary arts',
      'Authored by renowned chef',
      'High-quality hardcover format',
    ],
    numrev: 15,
    rating: 96,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f101',
        rate: 5,
        Comment: 'Amazing recipes! A must-have for food enthusiasts.',
        date: '2024-03-02T12:55:30.892Z',
        _id: '64e9d16786e6fe3e932ae721',
      },
      {
        user: '63d1089e7ee22fa515771f102',
        rate: 4.5,
        Comment: 'Excellent tips. Beautifully presented.',
        date: '2024-03-05T11:15:12.892Z',
        _id: '64e9d16786e6fe3e932ae722',
      },
    ],
    sell: 18,
  },
  {
    _id: '64c37c823c03172a9facc069',
    id: 27,
    title:
      "Philip's Stargazing 2023 Month-by-Month Guide to the Night Sky Britain & Ireland ",
    price: 733,
    cureted_price: 1100,
    category: 'books',
    ratings: 4.6,
    images: [
      {
        _id: '651d50b7e2b392999df759e2',
        url: 'https://rukminim2.flixcart.com/image/612/612/l2tcfbk0/book/d/f/w/philip-s-stargazing-2023-month-by-month-guide-to-the-night-sky-original-image2bh5vzg2eyh.jpeg?q=70',
      },
    ],
    seller: 'AstroBooks',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        author: 'Dr. Celeste Astronomer',
        genre: 'Science',
        format: 'Paperback',
        type: 'Science book',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'AstroBooks',
        Color: 'Assorted',
        Type: 'Stargazing Guide',
        ModelName: 'CELESTIAL-ATLAS',
        ModelID: 'CELESTIAL-ATLAS',
        author: 'Dr. Celeste Astronomer',
        genre: 'Science',
        format: 'Paperback',
        Collection: 'astronomy and stargazing',
        care: 'Handle with care to maintain book condition',
      },
    ],
    stock: 22,
    __v: 0,
    features: [
      'Comprehensive guide to stargazing',
      'Authored by renowned astronomer',
      'Available in paperback format',
    ],
    numrev: 8,
    rating: 89,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f103',
        rate: 4.5,
        Comment: 'Fascinating insights. Perfect for amateur astronomers.',
        date: '2024-03-12T10:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae723',
      },
      {
        user: '63d1089e7ee22fa515771f104',
        rate: 4,
        Comment: 'Clear explanations. Beautiful illustrations.',
        date: '2024-03-15T08:45:22.892Z',
        _id: '64e9d16786e6fe3e932ae724',
      },
    ],
    sell: 16,
  },
  {
    _id: '64c37c823c03172a9facc070',
    id: 28,
    title: 'The Bird with Golden Wings',
    price: 299,
    cureted_price: 450,
    category: 'books',
    ratings: 4.8,
    images: [
      {
        _id: '651d50b7e2b392999df759e3',
        url: 'https://rukminim2.flixcart.com/image/612/612/krdtlzk0/regionalbooks/i/x/c/the-bird-with-golden-wings-stories-of-wit-and-magic-english-original-imag56jy4tgpswnz.jpeg?q=70',
      },
    ],
    seller: 'StorytellerBooks',
    replacement: '14 Days Replacement Policy',
    specificaton: [
      {
        author: 'Sudha Murthy',
        genre: "Children's Fiction",
        format: 'Hardcover',
        type: 'Children Fiction book',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'StorytellerBooks',
        Color: 'Assorted',
        Type: "Children's Book",
        ModelName: 'GOLDEN-WINGS',
        ModelID: 'GOLDEN-WINGS',
        author: 'Sudha Murthy',
        genre: "Children's Fiction",
        format: 'Hardcover',
        Collection: 'Inspirational Stories for Children',
        care: 'Handle with care to maintain book condition',
      },
    ],
    stock: 18,
    __v: 0,
    features: [
      "Heartwarming children's fiction",
      'Written by Sudha Murthy',
      'Available in hardcover format',
    ],
    numrev: 6,
    rating: 92,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f105',
        rate: 5,
        Comment: 'A beautiful story that teaches valuable lessons.',
        date: '2024-04-02T12:15:30.892Z',
        _id: '64e9d16786e6fe3e932ae725',
      },
      {
        user: '63d1089e7ee22fa515771f106',
        rate: 4.5,
        Comment: 'My child loved reading it. Highly recommended!',
        date: '2024-04-05T09:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae726',
      },
    ],
    sell: 12,
  },
  {
    _id: '64c37c823c03172a9facc072',
    id: 30,
    title: 'Mastering Mathematics: Advanced Concepts',
    price: 499,
    cureted_price: 699,
    category: 'books',
    ratings: 3.9,
    images: [
      {
        _id: '651d50b7e2b392999df759e5',
        url: 'https://rukminim2.flixcart.com/image/612/612/xif0q/regionalbooks/8/2/l/hive-mind-foundation-course-class-6-maths-science-english-3-original-imagsgmk7zye3ntr.jpeg?q=70',
      },
    ],
    seller: 'MathGeniusBooks',
    replacement: '15 Days Replacement Policy',
    specificaton: [
      {
        author: 'Prof. A. Mathison',
        genre: 'Academic',
        format: 'Hardcover',
        type: 'Academic book',
      },
    ],
    productDetails: [
      {
        packOf: '1',
        brand: 'MathGeniusBooks',
        Color: 'Assorted',
        Type: 'Academic',
        ModelName: 'MATH-ADVANCED',
        ModelID: 'MATH-ADVANCED',
        author: 'Prof. A. Mathison',
        genre: 'Academic',
        format: 'Hardcover',
        Collection: 'Advanced Mathematics',
        care: 'Handle with care to maintain book condition',
      },
    ],
    stock: 20,
    __v: 0,
    features: [
      'Comprehensive guide to advanced mathematics',
      'Authored by renowned mathematician',
      'Available in hardcover format',
    ],
    numrev: 12,
    rating: 95,
    reviews: [
      {
        user: '63d1089e7ee22fa515771f109',
        rate: 5,
        Comment:
          'A must-have for anyone serious about mastering advanced mathematics.',
        date: '2024-04-18T10:30:45.892Z',
        _id: '64e9d16786e6fe3e932ae729',
      },
      {
        user: '63d1089e7ee22fa515771f110',
        rate: 4.8,
        Comment:
          'Clear explanations and challenging problems. Excellent resource.',
        date: '2024-04-22T09:15:22.892Z',
        _id: '64e9d16786e6fe3e932ae730',
      },
    ],
    sell: 15,
  },
]

export default Books
